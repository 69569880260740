import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { CommunicatorListContainer, PHNProListContainerChildProps } from './container/CommunicatorListContainer';
import { OnboardingPageStore } from '../OnboardingPageStore';
import { SpotSearchbar, SpotSvg } from '@enterprise/spot';
import { Route, Link, useHistory } from 'react-router-dom';
import { OnboardingPage_URL } from '../OnboardingPage';
import { CommunicatorNewRequestPage } from './CommunicatorNewRequestPage';
import CommunicatorPracticeDetailsPage from './CommunicatorPracticeDetailsPage/CommunicatorPracticeDetailsPage';
import useStore from '../../../hooks/useStore';
import { PAGE_STORES } from '../../stores';
import i18n from '../../../i18n';
import { Trans } from 'react-i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import {
    GridCellParams,
    GridColDef,
    GridRowModel,
    GridRowParams,
    GridSortItem,
    GridValueGetterParams,
    MaterialDataGrid,
} from '@enterprise/material-data-grid';
import styles from '../../dataGrid.module.scss';
import * as _ from 'lodash';
import { CommunicatorPracticeEntity } from '../../../core/onboarding/Communicator/entity/CommunicatorPracticeEntity';

enum GridColumns {
    PracticeName = 'practiceName',
    SapId = 'sapId',
    PracticeId = 'practiceId',
    Status = 'status',
    CreatedDate = 'createdDate',
    Actions = 'actions',
}

export const CommunicatorPage = () => {
    const onboardingStore = useStore<OnboardingPageStore>(PAGE_STORES.OnboardingPageStore);
    const history = useHistory();

    useEffect(() => {
        return () => {
            (onboardingStore as OnboardingPageStore).disposeStore('PHNPro');
        };
    }, []);

    const [searchTerm, setSearchTerm] = useState('');

    const getPracticeName = ({ name }: CommunicatorPracticeEntity): string => {
        if (!name) {
            return i18n.t('communicatorPage:defaultPendingPracticeName', 'Pending Practice Name');
        }

        return String(name);
    };

    const getSapId = ({ sapId }: CommunicatorPracticeEntity): string => {
        if (!sapId) {
            return i18n.t('communicatorPage:defaultPendingSapId', 'Pending SAP Id');
        }

        return String(sapId);
    };

    const getPracticeId = ({ practiceId }: CommunicatorPracticeEntity): string => {
        if (!practiceId) {
            return i18n.t('communicatorPage:defaultPendingPracticeId', 'Pending Practice ID');
        }

        return String(practiceId);
    };

    const getStatus = ({ status }: CommunicatorPracticeEntity): string => {
        if (!status) {
            return i18n.t('communicatorPage:defaultPendingStatus', 'Pending Status');
        }

        return String(status);
    };

    const getCreatedDate = ({ createdAt }: CommunicatorPracticeEntity): string => {
        if (!createdAt) {
            return i18n.t('communicatorPage:defaultPendingCreatedDate', '0000-00-00');
        }

        return createdAt;
    };

    const practiceNameHeader = (): React.ReactElement => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerCell}>{i18n.t('communicatorPage:practiceName', 'Practice Name')}</div>
            </div>
        );
    };

    const sapIdHeader = (): React.ReactElement => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerCell}>{i18n.t('communicatorPage:sapId', 'SAP ID')}</div>
            </div>
        );
    };

    const practiceIdHeader = (): React.ReactElement => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerCell}>{i18n.t('communicatorPage:practiceId', 'Practice ID')}</div>
            </div>
        );
    };

    const statusHeader = (): React.ReactElement => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerCell}>{i18n.t('communicatorPage:status', 'Status')}</div>
            </div>
        );
    };

    const createdDateHeader = (): React.ReactElement => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.headerCell}>{i18n.t('communicatorPage:createdDate', 'Created Date')}</div>
            </div>
        );
    };

    const actionsHeader = (): React.ReactElement => {
        return <div className={styles.headerContainer}></div>;
    };

    const practiceNameCell = (params: GridCellParams): React.ReactElement => {
        const rowValue = params.row;
        return (
            <div className={styles.rowCell}>
                <div>{rowValue.name}</div>
            </div>
        );
    };

    const sapIdCell = (params: GridCellParams): React.ReactElement => {
        const rowValue = params.row;
        return (
            <div className={styles.rowCell}>
                <div>{rowValue.sapId}</div>
            </div>
        );
    };

    const practiceIdCell = (params: GridCellParams): React.ReactElement => {
        const rowValue = params.row;
        return (
            <div className={styles.rowCell}>
                <div>{rowValue.practiceId}</div>
            </div>
        );
    };

    const statusCell = (params: GridCellParams): React.ReactElement => {
        const rowValue = params.row;
        return (
            <div className={styles.rowCell}>
                <div>{rowValue.status}</div>
            </div>
        );
    };

    const createdDateCell = (params: GridCellParams): React.ReactElement => {
        const rowValue = params.row;
        return <div>{rowValue.createdAt && new Date(rowValue.createdAt).toLocaleString('en-US')}</div>;
    };

    const actionsCell = (params: GridCellParams): React.ReactElement => {
        return (
            <span style={{ color: '#00a7b5' }}>
                <Trans i18nKey={'common:pages.dataSources.manage'}>Manage</Trans>
            </span>
        );
    };

    const columns: GridColDef[] = [
        {
            field: GridColumns.PracticeName,
            flex: 3.5,
            renderHeader: practiceNameHeader,
            renderCell: practiceNameCell,
            valueGetter: (params: GridValueGetterParams) => {
                return getPracticeName(params.row);
            },
        },
        {
            field: GridColumns.SapId,
            flex: 1,
            renderHeader: sapIdHeader,
            renderCell: sapIdCell,
            valueGetter: (params: GridValueGetterParams) => {
                return getSapId(params.row);
            },
        },
        {
            field: GridColumns.PracticeId,
            flex: 1.5,
            renderHeader: practiceIdHeader,
            renderCell: practiceIdCell,
            valueGetter: (params: GridValueGetterParams) => {
                return getPracticeId(params.row);
            },
        },
        {
            field: GridColumns.Status,
            flex: 1,
            renderHeader: statusHeader,
            renderCell: statusCell,
            valueGetter: (params: GridValueGetterParams) => {
                return getStatus(params.row);
            },
        },
        {
            field: GridColumns.CreatedDate,
            flex: 1.5,
            renderHeader: createdDateHeader,
            renderCell: createdDateCell,
            valueGetter: (params: GridValueGetterParams) => {
                return getCreatedDate(params.row);
            },
        },
        {
            field: GridColumns.Actions,
            flex: 1,
            renderHeader: actionsHeader,
            renderCell: actionsCell,
            sortable: false,
        },
    ];

    const getRow = (items: CommunicatorPracticeEntity[]) => {
        if (!searchTerm) {
            return items;
        }

        return items.filter((item: CommunicatorPracticeEntity) => {
            const regexp = new RegExp(searchTerm, 'i');
            return regexp.test(_.values(item));
        });
    };

    const getRowId = (row: GridRowModel): string => {
        const { sapId, practiceId } = row as CommunicatorPracticeEntity;
        return `${sapId}-${practiceId}`;
    };

    const onRowClick = (param: GridRowParams): void => {
        history.push(`${OnboardingPage_URL}/communicator/practiceDetails/${param.row.sapId}/${param.row.practiceId}`);
    };

    const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
        {
            field: GridColumns.CreatedDate,
            sort: 'desc',
        },
    ]);

    return (
        <div className="onboarding-page h-100">
            <Container fluid={true} style={{ marginTop: '10px' }}>
                <Row>
                    <Col xs={4}>
                        <h1>
                            <Trans i18nKey={'common:pages.onboarding.onboardingTitle'}>Onboarding: Communicator</Trans>
                        </h1>
                    </Col>
                    <Col xs={5} />
                    <Col xs={3}>
                        <Row>
                            <Link to={`${OnboardingPage_URL}/communicator/new`} className="spot-link">
                                <SpotSvg icon="add" width="32px" height="32px" style={{ marginRight: 8 }} />
                                <Trans i18nKey={'common:pages.dataSources.createPractice'}>Create Practice</Trans>
                            </Link>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <CommunicatorListContainer>
                {({ items }: PHNProListContainerChildProps) => (
                    <div className="list">
                        <Route path={`${OnboardingPage_URL}/communicator/new`} exact={true} component={CommunicatorNewRequestPage} />
                        <Route
                            path={`${OnboardingPage_URL}/communicator/practiceDetails/:sapId/:practiceId`}
                            exact={true}
                            component={CommunicatorPracticeDetailsPage}
                        />
                        <SpotSearchbar onSubmit={setSearchTerm} />
                        <MaterialDataGrid
                            className={styles.dataGrid}
                            columns={columns}
                            rows={getRow(items)}
                            rowHeight={40}
                            autoHeight={true}
                            headerHeight={40}
                            getRowId={getRowId}
                            onRowClick={onRowClick}
                            sortModel={sortModel}
                            onSortModelChange={model => setSortModel(model)}
                        />
                        <CSVLink
                            data={items}
                            filename={`practices-${moment(new Date()).format('YYYY-MM-DD-HH:mm:ss')}.csv`}
                            style={{ padding: 5 }}
                            className="spot-link"
                        >
                            <Trans i18nKey={'common:pages.onboarding.exportPractices'}>Export Practices to CSV File</Trans>
                        </CSVLink>
                    </div>
                )}
            </CommunicatorListContainer>
        </div>
    );
};
