import { Service, Inject } from 'typedi';
import BaseHttp from './http/EnterpriseApiHttp';
import { Transformers } from '@enterprise/common';
import { OrganizationEntity } from '../core/entity/OrganizationEntity';
import { UserEntity } from '../core/entity/UserEntity';

export class FeatureEntity {
    name: string;
    criteria?: any;
}

@Service()
export class FeatureService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getGlobalFeatures(): Promise<FeatureEntity[]> {
        return this.http
            .get(`/features`)
            .then((data: any[]) =>
                new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(FeatureEntity)).transform(data.filter(f => !!f.isActive)),
            );
    }

    setGlobalFeatures(features: FeatureEntity[]): Promise<boolean> {
        return this.http
            .put(
                `/features`,
                new Transformers.ToPlainTransformer().transform(
                    features.map(f => {
                        (f as any).isActive = true;
                        return f;
                    }),
                ),
            )
            .then(data => true);
    }

    getOrganizationFeatures(org: OrganizationEntity | string): Promise<FeatureEntity[]> {
        return this.http
            .get(`/organizations/:orgId/features`, { pathParams: { orgId: (org as OrganizationEntity).id || org } })
            .then((data: any[]) =>
                new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(FeatureEntity)).transform(data.filter(f => !!f.isActive)),
            );
    }

    async setOrganizationFeatures(features: FeatureEntity[], org: OrganizationEntity | string): Promise<boolean> {
        const existedFeatures = await this.getOrganizationFeatures(org);
        const disabledFeatures = [...existedFeatures, ...features]
            .map(f => {
                (f as any).isActive = false;
                return f;
            })
            .filter(df => !features.find(f => f.name === df.name));
        return this.http
            .put(
                `/organizations/:orgId/features`,
                new Transformers.ToPlainTransformer().transform([
                    ...features.map(f => {
                        (f as any).isActive = true;
                        return f;
                    }),
                    ...disabledFeatures,
                ]),
                { pathParams: { orgId: (org as OrganizationEntity).id || org } },
            )
            .then(data => true);
    }

    getUserFeatures(u: UserEntity | string): Promise<FeatureEntity[]> {
        return this.http
            .get(`/users/:userId/features`, { pathParams: { userId: (u as UserEntity).id || u } })
            .then((data: any[]) =>
                new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(FeatureEntity)).transform(data.filter(f => !!f.isActive)),
            );
    }

    async setUserFeatures(features: FeatureEntity[], u: UserEntity | string): Promise<boolean> {
        const existedFeatures = await this.getUserFeatures(u);
        const disabledFeatures = [...existedFeatures, ...features]
            .map(f => {
                (f as any).isActive = false;
                return f;
            })
            .filter(df => !features.find(f => f.name === df.name));
        return this.http
            .put(
                `/users/:userId/features`,
                new Transformers.ToPlainTransformer().transform([
                    ...features.map(f => {
                        (f as any).isActive = true;
                        return f;
                    }),
                    ...disabledFeatures,
                ]),
                { pathParams: { userId: (u as UserEntity).id || u } },
            )
            .then(data => true);
    }
}
