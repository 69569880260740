import { Container } from 'typedi';
import * as mobxLogger from 'mobx-logger';
import { RootStore } from '../store/RootStore';
import EnterpriseApiHttp from './http/EnterpriseApiHttp';
import { UserService, EnterpriseAuthProvider } from '@enterprise/common';
import { environment } from '../environment/environment';

if (environment.isDev) {
    mobxLogger.enableLogging({
        action: true,
        compute: true,
        reaction: true,
        transaction: true,
    });
}

export function boot_di() {
    Container.set('http', new EnterpriseApiHttp(environment.ENTERPRISE_API, () => Container.get(RootStore).app.token));
    Container.set('httpOnboarding', new EnterpriseApiHttp(environment.ONBOARDING_API, () => Container.get(RootStore).app.token));
    Container.set(UserService, new UserService([new EnterpriseAuthProvider(Container.get('http'))]));
}

export function addPageStore<T>(identifier, TCreator: new (...args: any[]) => T) {
    Container.set(TCreator as any);
    const pageStore = Container.get(TCreator);
    const rootStore = Container.get(RootStore);
    rootStore.pages[identifier] = pageStore;
}

const ioc = Container;
export default ioc;
