import { Service, Inject } from 'typedi';
import BaseHttp from './http/EnterpriseApiHttp';
import { Transformers } from '@enterprise/common';
import { AccountEntity } from '../core/entity/AccountEntity';
import { UpsertAccountEntity } from '../core/entity/UpsertAccountEntity';

@Service()
export class DataSourceAccountService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getAccounts(): Promise<AccountEntity[]> {
        return this.http
            .get(`/v2/account`)
            .then(data => new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(AccountEntity)).transform(data));
    }

    getAccountById(id: string): Promise<AccountEntity> {
        return this.http
            .get(`/v2/account/:id`, { pathParams: { id } })
            .then(data => new Transformers.ToTypeTransformer(AccountEntity).transform(data));
    }

    saveAccount(entity: UpsertAccountEntity): Promise<AccountEntity> {
        if (entity.id) {
            return this.http
                .put(`/v2/account/:entityId`, new Transformers.ToPlainTransformer().transform(entity), { pathParams: { entityId: entity.id } })
                .then(data => new Transformers.ToTypeTransformer(AccountEntity).transform(data));
        } else {
            return this.http
                .post(`/v2/account`, new Transformers.ToPlainTransformer().transform(entity))
                .then(data => new Transformers.ToTypeTransformer(AccountEntity).transform(data));
        }
    }

    removeAccount(id: string): Promise<any> {
        return this.http.delete(`/v2/account/:id`, undefined, { pathParams: { id } });
    }
}
