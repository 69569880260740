import {Service} from "typedi";
import {CommunicatorPageStore} from "./Communicator/CommunicatorPageStore";
import ioc from "../../services/ioc";
import {InvalidConfigurationException} from "@enterprise/common";

const ONBOARDING_STORES_MAP = {
    'PHNPro': CommunicatorPageStore
};

export interface OnboardingPageStoreInterface {
    load(): Promise<any>;

    dispose(): Promise<any>;
}

@Service()
export class OnboardingPageStore {
    private stores: { [name: string]: OnboardingPageStoreInterface } = {};


    getSubStore(name: string): Promise<OnboardingPageStoreInterface | any> {
        if (this.stores[name]) {
            return Promise.resolve(this.stores[name])
        }
        if (!ONBOARDING_STORES_MAP[name]) {
            return Promise.reject(new InvalidConfigurationException(`Onboarding Store with name "${name}" is not registered`));
        }
        const storeInstance = ioc.get<OnboardingPageStoreInterface>(ONBOARDING_STORES_MAP[name]);
        this.stores[name] = storeInstance;
        return storeInstance.load()
            .then(() => (this.stores[name]));
    }

    disposeStore(name: string) {
        if (this.stores[name]) {
            return this.stores[name].dispose()
                .then((r) => {
                    delete this.stores[name];
                    return r;
                });
        }
        return Promise.reject(new InvalidConfigurationException(`Onboarding Store with name "${name}" is not registered`));
    }
}
