import BasicLayout from '../layout/basic/BasicLayout';
import MainLayout from '../layout/main/MainLayout';
import LoginPage from '../pages/login/LoginPage';
import UsersPage from '../pages/users/UsersPage';
import { DataSourcePage } from '../pages/dataSources/DataSourcePage';
import { OrganizationsPage } from '../pages/organizations/OrganizationsPage';
import RedirectToRouteComponent from './RedirectToRouteComponent';
import UnauthorizedPage from '../pages/login/UnauthorizedPage';
import GlobalSettingsPage from '../pages/globalSettings/GlobalSettingsPage';
import { OnboardingPage } from '../pages/onboarding/OnboardingPage';
import RoleConstants from '../constants/RoleConstants';
import { RouterPaths } from './RouterPaths';

const baseRoute = {
    path: null,
    isSecured: false,
    Layout: BasicLayout,
    role: ['*'],
    Component: null,
    config: undefined,
};

const securedRoute = {
    ...baseRoute,
    isSecured: true,
    role: [RoleConstants.SUPER_ADMIN],
    Layout: MainLayout,
};

export default [
    {
        ...baseRoute,
        path: '/',
        exact: true,
        Component: RedirectToRouteComponent,
        config: { to: RouterPaths.UserPages.Users },
    },
    {
        ...baseRoute,
        path: '/login',
        Component: LoginPage,
    },
    {
        ...baseRoute,
        path: '/unauthorized',
        Component: UnauthorizedPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.UserPages.Users,
        Component: UsersPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.DataSourcePages.DataSources,
        Component: DataSourcePage,
    },
    {
        ...securedRoute,
        path: RouterPaths.OrganizationsPages.Organizations,
        Component: OrganizationsPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.GlobalSettingPages.GlobalSettings,
        Component: GlobalSettingsPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.OnboardingPages.OnBoarding,
        role: [RoleConstants.SUPER_ADMIN, RoleConstants.ONBOARDING_ADMIN],
        Component: OnboardingPage,
    },
    {
        ...securedRoute,
        path: '/home',
        exact: true,
        Component: RedirectToRouteComponent,
        config: { to: RouterPaths.UserPages.Users },
    },
    {
        ...baseRoute,
        exact: true,
        Component: RedirectToRouteComponent,
        config: { to: RouterPaths.UserPages.Users },
    },
];
