import local from './local.json';
import dev from './dev.json';
import dev2 from './dev2.json';
import qa from './qa.json';
import qa2 from './qa2.json';
import stage from './staging.json';
import prod from './prod.json';

const envMap = [
    {
        name: 'dev',
        prefixes: ['admin-dev'],
        config: dev,
    },
    {
        name: 'dev2',
        prefixes: ['admin-dev2'],
        config: dev2,
    },
    {
        name: 'qa',
        prefixes: ['admin-qa'],
        config: qa,
    },
    {
        name: 'qa2',
        prefixes: ['admin-qa2'],
        config: qa2,
    },
    {
        name: 'staging',
        prefixes: ['admin-staging'],
        config: stage,
    },
    {
        name: 'prod',
        prefixes: ['admin'],
        config: prod,
    },
];

const getConfig = () => {
    let matchKey;
    // @ts-ignore
    const managerObject = window.ENTERPRISE_MANAGER;
    if (managerObject && managerObject.env) {
        matchKey = managerObject.env;
    } else {
        matchKey = window.location.hostname.split('.')[0];
    }
    const config = envMap.find(sub => {
        return sub.prefixes.some(prefix => prefix === matchKey);
    });

    return (
        config || {
            name: 'local',
            prefixes: ['localhost'],
            config: local,
        }
    );
};

export const envConfig = getConfig();
