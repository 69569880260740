import { Service, Inject } from 'typedi';
import BaseHttp from './http/EnterpriseApiHttp';
import { CommunicatorPracticeResponse } from '../pages/onboarding/Communicator/interfaces';
import { CommunicatorResetPassword } from '../pages/onboarding/Communicator/interfaces';

@Service()
export class OnboardingService {
    constructor(@Inject('httpOnboarding') private readonly http: BaseHttp) {}

    startTask(taskId: string): Promise<any> {
        return this.http.post(`/workflows/onboarding/start/${taskId}`);
    }

    async getPractices(): Promise<CommunicatorPracticeResponse> {
        return await this.http.get(`/communicator/practice/all`);
    }

    getPracticeOnboardingDetails(sapId: string) {
        return this.http.get(`/workflows/onboarding/details/${sapId}`);
    }

    getPracticeUsers(practiceId: string) {
        return this.http.get(`/communicator/practice/${practiceId}/users`);
    }

    getPracticeUser(idxId: string) {
        return this.http.get(`/communicator/practice/users/${idxId}`, { pathParams: { idxId } });
    }

    updatePracticeUser(user: any) {
        return this.http.put(`/communicator/practice/users/${user.idxId}`, user);
    }

    deletePracticeUser(idxId: string, practiceId: string) {
        return this.http.delete(`/communicator/practice/users/${practiceId}/${idxId}`);
    }

    createPracticeUser(user: any) {
        return this.http.post(`/communicator/practice/users/create`, user);
    }

    setUserPassword(user: CommunicatorResetPassword) {
        return this.http.post(`/communicator/practice/users/set-password`, user);
    }

    getUserExists(username: string) {
        return this.http.get(`/communicator/practice/users/exists/:username`, { pathParams: { username } });
    }

    enrollment(data): Promise<any> {
        return this.http.post(`/communicator/practice`, {
            sapId: data.sapId.trim(),
            practiceName: data.practiceName.trim(),
            practiceStreetAddress: data.practiceStreetAddress.trim(),
            practiceCity: data.practiceCity.trim(),
            practiceState: data.practiceState.toUpperCase().trim(),
            practiceCountry: data.practiceCountry.toUpperCase().trim(),
            practicePostalCode: data.practicePostalCode.trim(),
            contactFirstName: data.contactFirstName.trim(),
            contactLastName: data.contactLastName.trim(),
            contactPhoneNumber: data.contactPhoneNumber.trim(),
            contactEmail: data.contactEmail.trim(),
            requestedUsername: data.requestedUsername.trim(),
            twilioSMSNumber: data.twilioSMSNumber.trim(),
            siteId: data.practiceId?.trim(),
        });
    }
}
