import { Service, Inject } from 'typedi';
import BaseHttp from './http/EnterpriseApiHttp';
import { Transformers } from '@enterprise/common';
import { OrganizationEntity } from '../core/entity/OrganizationEntity';
import { SiteEntity } from '../core/entity/SiteEntity';
import { PricingTierEntity } from '../core/entity/PricingTierEntity';

@Service()
export class OrganizationsService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getOrganizations(): Promise<OrganizationEntity[]> {
        return this.http.get(`/organizations`, { params: { withPracticesCount: false } }).then(data => this.toEntities(data, OrganizationEntity));
    }

    getOrganizationById(id: string): Promise<OrganizationEntity> {
        return this.http.get(`/organizations/:id`, { pathParams: { id } }).then(data => this.toEntity(data, OrganizationEntity));
    }

    saveOrganization(entity: OrganizationEntity): Promise<OrganizationEntity> {
        if (entity.id) {
            return this.http
                .put(`/organizations/:entityId`, new Transformers.ToPlainTransformer().transform(entity), { pathParams: { entityId: entity.id } })
                .then(data => this.getOrganizationById(this.toEntity(data, OrganizationEntity).id));
        } else {
            return this.http
                .post(`/organizations`, new Transformers.ToPlainTransformer().transform(entity))
                .then(data => this.getOrganizationById(this.toEntity(data, OrganizationEntity).id));
        }
    }

    updateOrganizationPractice(entity: SiteEntity): Promise<SiteEntity> {
        return this.http.put(`/v2/organizations/:entityId/practice`, entity, { pathParams: { entityId: entity.id } });
    }

    getApplications(id: string): Promise<SiteEntity[]> {
        return this.http.get(`/organizations/:id/applications`, { pathParams: { id } }).then(data => this.toEntities(data, SiteEntity));
    }

    linkAccounts(orgId: string, accountIds: string[]): Promise<OrganizationEntity> {
        return this.http
            .put(`/v2/account/organization/:orgId/link`, undefined, {
                pathParams: { orgId },
                params: { accountId: accountIds },
            })
            .then(data => this.toEntity(data, OrganizationEntity));
    }

    unlinkAccounts(orgId: string, accountIds: string[]): Promise<OrganizationEntity> {
        return this.http
            .put(`/v2/account/organization/:orgId/unlink`, undefined, {
                pathParams: { orgId },
                params: { accountId: accountIds },
            })
            .then(data => this.toEntity(data, OrganizationEntity));
    }

    setMasterApplication(orgId: string, applicationId: string): Promise<SiteEntity> {
        return this.http
            .put(`/organizations/:orgId/applications/master/:applicationId`, null, {
                pathParams: { orgId, applicationId },
            })
            .then(data => this.toEntity(data, SiteEntity));
    }

    getAccountApplications(accountId: string): Promise<SiteEntity[]> {
        return this.http.get(`/v2/account/applications`, { params: { accountId: [accountId] } }).then(({ data, errors }) => {
            if (errors && errors[accountId]) {
                throw Error(errors[accountId].message);
            }

            return this.toEntities(data, SiteEntity);
        });
    }

    getPricingTiers(): Promise<PricingTierEntity[]> {
        return this.http.get(`/organizations/pricing-tiers`).then(data => this.toEntities(data, PricingTierEntity));
    }

    deleteOrganization(organizationId: string): Promise<any> {
        return this.http.delete(`/organizations/:orgId`, undefined, {
            pathParams: { orgId: organizationId },
        });
    }

    private toEntity(data, targetType) {
        return new Transformers.ToTypeTransformer(targetType).transform(data);
    }

    private toEntities(data, targetType) {
        return new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(targetType)).transform(data);
    }
}
