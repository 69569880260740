import './index.scss';
import 'reflect-metadata'; // DO NOT REMOVE THIS IMPORT
import { Container } from 'typedi';
import createBrowserHistory from 'history/createBrowserHistory';
import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router';
import App from './App';
import ioc, { boot_di } from './services/ioc';
import { RootStore } from './store/RootStore';
import { registerPagesStore } from './pages/stores';
import { unregister } from './registerServiceWorker';
import './i18n';
import { TAG_MANAGER_CONFIG } from './environment/environment';
import { tagManagerInit } from '@shared';
import { AppContext } from './app-context';

boot_di();
setTimeout(() => {
    const browserHistory = createBrowserHistory();
    registerPagesStore();
    tagManagerInit(TAG_MANAGER_CONFIG);
    const rootStore = Container.get(RootStore);
    const history = syncHistoryWithStore(browserHistory, rootStore.routing);

    ReactDOM.render(
        <Provider rootStore={rootStore} {...rootStore}>
            <AppContext.Provider value={{ ioc: ioc as any, rootStore }}>
                <Router history={history}>
                    <App />
                </Router>
            </AppContext.Provider>
        </Provider>,
        document.getElementById('root') as HTMLElement,
    );
    unregister();
});
