import { EnterpriseUserToken } from './EnterpriseUserToken';
import { BaseAuthProvider, BaseUserToken } from '../../';
import { AnonymousUserToken } from '../../AnonymousUserToken';
import { BaseHttp } from '../../../';

export interface IEnterpriseUser {
    username: string;
    organization: any;
    roles: string[];
    profile?: {
        firstName?: string;
        lastName?: string;
        locale?: string;
    };
}

const STORAGE_KEY_NAME = 'EnterpriseAuthProvider';
const LANGUAGE_KEY_NAME = 'i18nlocale';

export class EnterpriseAuthProvider implements BaseAuthProvider {
    /**
     * @type BaseHttp
     */
    http;

    /**
     * @param {BaseHttp} http
     */
    constructor(http: BaseHttp) {
        this.http = http;
    }

    /**
     *
     * @param {UsernamePasswordToken | EnterpriseUserToken | OpenIdexxUserToken} token
     */
    support(token) {
        return true;
    }

    setLocalStorage(token) {
        window.localStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(token));
        if (token.user.profile) {
            const locale = token.user.profile.locale || 'en-US';
            window.localStorage.setItem(LANGUAGE_KEY_NAME, locale);
        }
    }

    /**
     *
     * @param {UsernamePasswordToken} token
     * @return Promise{EnterpriseUserToken|Error}
     */
    async authenticate(token) {
        if (token instanceof EnterpriseUserToken) {
            return Promise.resolve(token);
        }
        const config = {
            headers: { Authorization: `Bearer ${token}` },
            suppressNotifications: true,
        };

        try {
            const userData = await this.http.get(`/auth/v1/user`, config);
            if (!userData) {
                return 'USER_NOT_FOUND';
            }
            const newToken = new EnterpriseUserToken(userData);
            newToken.access_token = token;
            this.setLocalStorage(newToken);
            return Promise.resolve(newToken);
        } catch (err) {
            return Promise.resolve(err);
        }
    }

    logout(): Promise<BaseUserToken> {
        return new Promise(resolve => {
            window.localStorage.removeItem(STORAGE_KEY_NAME);
            window.localStorage.removeItem(LANGUAGE_KEY_NAME);
            resolve(new AnonymousUserToken());
        });
    }

    restore(): Promise<BaseUserToken | null> {
        return new Promise((resolve, reject) => {
            const userData = window.localStorage.getItem(STORAGE_KEY_NAME);
            let token: any = null;
            if (userData) {
                try {
                    token = new EnterpriseUserToken(JSON.parse(userData));
                } catch (e) {
                    reject(new Error('Unable to parse token JSON'));
                }
                resolve(token);
            } else {
                reject();
            }
        });
    }
}
