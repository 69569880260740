import 'reflect-metadata'; // DO NOT REMOVE THIS IMPORT
import { Service } from 'typedi';
import { action, autorun, observable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import AppStore from './AppStore';
import UIStore from './UIStore';

@Service()
export class RootStore {
    pages: { [name: string]: any } = {};
    routing = new RouterStore();

    @observable isLoaded: boolean = false;

    constructor(public ui: UIStore, public app: AppStore) {
        autorun(() => {
            if (this.ui.title !== document.title) {
                document.title = this.ui.title;
            }
        });
    }

    @action bootstrap() {
        this.app.bootstrap().then(() => {
            this.isLoaded = true;
        });
    }
}
