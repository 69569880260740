import { envConfig } from './config';
import { FEATURES, GLOBAL_FEATURES } from '@enterprise/core';

export const environment = {
    get isDev() {
        return ['LOCAL', 'DEV', 'DEVELOPMENT'].indexOf(this.APP_ENV) !== -1;
    },
    get isLocal() {
        return this.APP_ENV === 'LOCAL';
    },
    APP_ENV: envConfig.name.toUpperCase(),
    adminEmail: 'connectadmin@idexx.com',
    SSO: {
        BASE_URL: envConfig.config.CF_SSO.BASE_URL,
        AUTH_HOST: envConfig.config.CF_SSO.AUTH_HOST,
        SECURE_HOST: envConfig.config.CF_SSO.SECURE_HOST,
        ALLOWED_DOMAINS: envConfig.config.CF_SSO.ALLOWED_DOMAINS,
    },
    ENTERPRISE_API: {
        baseUrl: envConfig.config.ENTERPRISE_API_BASEURL,
    },
    ONBOARDING_API: {
        baseUrl: envConfig.config.ONBOARDING_API_BASEURL,
    },
    features: Object.entries(FEATURES).map(([name, value]) => ({ name, value })),
    globalFeatures: Object.entries(GLOBAL_FEATURES).map(([name, value]) => ({ name, value } as { name: string; value: string })),
};

export const TAG_MANAGER_CONFIG = envConfig.config.TAG_MANAGER;
