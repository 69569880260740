import i18n from '../../i18n';
import { RouterPaths } from '../../router/RouterPaths';
import RoleConstants from '../../constants/RoleConstants';

export interface MenuItem {
    label: string;
    path: string;
    roles: string[];
}

export const menu: MenuItem[] = [
    {
        label: i18n.t('common:menu.users', 'Users'),
        path: RouterPaths.UserPages.Users,
        roles: [RoleConstants.SUPER_ADMIN],
    },
    {
        label: i18n.t('common:menu.organizations', 'Organizations'),
        path: RouterPaths.OrganizationsPages.Organizations,
        roles: [RoleConstants.SUPER_ADMIN],
    },
    {
        label: i18n.t('common:menu.dataSources', 'Data Sources'),
        path: RouterPaths.DataSourcePages.DataSources,
        roles: [RoleConstants.SUPER_ADMIN],
    },
    {
        label: i18n.t('common:menu.onboarding', 'Onboarding'),
        path: RouterPaths.OnboardingPages.Communicator,
        roles: [RoleConstants.SUPER_ADMIN, RoleConstants.ONBOARDING_ADMIN],
    },
    {
        label: i18n.t('common:menu.globalSettings', 'Global Settings'),
        path: RouterPaths.GlobalSettingPages.GlobalSettings,
        roles: [RoleConstants.SUPER_ADMIN],
    },
];
