import { IEnterpriseUser } from './EnterpriseAuthProvider';
import { BaseUserToken } from '../../common';

export class EnterpriseUserToken extends BaseUserToken {
    user: IEnterpriseUser;
    // tslint:disable-next-line
    access_token = null;
    // tslint:disable-next-line
    token_type = null;

    constructor(data) {
        super();
        this.user = data.user;
        this.access_token = data.access_token;
        this.token_type = data.token_type;
    }

    get isActive() {
        return !!this.user.organization;
    }

    isAuthenticated() {
        return !!this.user;
    }

    hasRole(name = '*') {
        if (!this.user.roles) {
            return false;
        }
        return name === '*' || this.user.roles.indexOf(name) !== -1;
    }

    getUsername() {
        if (this.user.profile && this.user.profile.firstName && this.user.profile.lastName) {
            return `${this.user.profile.firstName} ${this.user.profile.lastName}`;
        }
        return this.user.username;
    }
}
