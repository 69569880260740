import { toast } from 'react-toastify';
import * as React from 'react';
import { InvalidCredentialsException } from '@enterprise/common';
import { Container } from 'typedi';
import { RootStore } from '../store/RootStore';

type NotificationContent = React.ReactNode | (() => void);

interface NotificationOptions {
    /**
     * Called inside componentDidMount.
     */
    onOpen?: (...args: any[]) => void;

    /**
     * Called inside componentWillUnMount.
     */
    onClose?: (...args: any[]) => void;

    /**
     * Set a custom `toastId`
     */
    toastId?: number | string;
    autoClose?: number | false;
    /**
     * Set the default position to use.
     * `One of: 'top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left'`
     * `Default: 'top-right'`
     */
    position?: any;

    /**
     * Pass a custom close button.
     * To remove the close button pass `false`
     */
    closeButton?: React.ReactNode | false;
}

export class Notifications {
    private static instance?: Notifications;
    static getInstance() {
        if (!Notifications.instance) {
            Notifications.instance = new Notifications();
        }
        return Notifications.instance;
    }

    static success(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().success(content, options);
    }

    static info(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().info(content, options);
    }

    static warn(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().warn(content, options);
    }

    static error(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().error(content, options);
    }

    static fromException(e: any, options?: NotificationOptions) {
        if (e instanceof InvalidCredentialsException) {
            return Notifications.getInstance().warn(e.message || e, {
                onOpen: () => {
                    const rootStore = Container.get(RootStore);
                    rootStore.app
                        .logout()
                        .then(() => {
                            rootStore.routing.push('/login');
                        })
                        .catch(() => {
                            rootStore.routing.push('/login');
                        });
                },
            });
        }

        const { message, innerException } = e;
        const { error_description = '' } = innerException || {};
        const errorMessage = message + (error_description && `: ${error_description}`);
        return Notifications.getInstance().error(errorMessage || e, options);
    }

    success(content: NotificationContent, options?: NotificationOptions) {
        return toast.success(content, options);
    }

    info(content: NotificationContent, options?: NotificationOptions) {
        return toast.info(content, options);
    }

    warn(content: NotificationContent, options?: NotificationOptions) {
        return toast.warn(content, options);
    }

    error(content: NotificationContent, options?: NotificationOptions) {
        return toast.error(content, options);
    }
}
