import { FormType } from '@enterprise/common';
import { FormProps } from 'react-final-form';
import { AccountEntity } from '../../core/entity/AccountEntity';
import { Transformers } from '@enterprise/common';
import { validateSync } from 'class-validator';
import { UpsertAccountEntity } from '../../core/entity/UpsertAccountEntity';

export class AccountEditFormType extends FormType {
    buildForm(data: AccountEntity, options?: any): Partial<FormProps> | any {
        return { initialValues: new Transformers.ToPlainTransformer().transform(data) };
    }

    getEntity(value, options?: any): UpsertAccountEntity {
        return new Transformers.ToTypeTransformer(UpsertAccountEntity).transform(value);
    }

    async validate(value): Promise<{ [field: string]: string }> {
        const errors = validateSync(new Transformers.ToTypeTransformer(UpsertAccountEntity).transform(value));
        return new Transformers.FunctionTransformer(data => {
            const result = {};
            (data as any[]).forEach(error => {
                result[error.property] = error.constraints[Object.keys(error.constraints)[0]];
            });
            return result;
        }).transform(errors);
    }
}
