import {IsNotEmpty, IsNumberString, IsOptional, MaxLength } from 'class-validator';

export class SiteEntity {
    id: string;
    siteId: string;
    sapId: string;
    accountId: string;

    @IsOptional()
    @MaxLength(100)
    idexxPracticeName: string | null = null;

    @IsNumberString()
    @IsOptional()
    @MaxLength(12)
    idexxSapId: string | null = null;

    @IsOptional()
    @MaxLength(10)
    idexxZip?: string | null = null;

    name: string;
    email: string;
    contactPerson?: string;
    phone?: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    pimsName?: string;
    isActive: boolean = true;
    isEnabled: boolean = false;
    isMaster: boolean = false;
    enableIdexxTestData: boolean = false;
    country?: string;
}
