import { FormProps } from 'react-final-form';
import { Decorator, FormApi } from 'final-form';

const createDecorator = (formType: FormType): Decorator => (form: FormApi) => {
    return form.subscribe(
        ({ values }) => {
            formType.onChange(values);
        },
        { values: true },
    );
};
export class FormType {
    name = 'default';
    messages?: any;
    onChange(values) {}

    buildForm(data: any, options?: any): Partial<FormProps> | any {
        const decorators = [createDecorator(this)];
        return {
            initialValues: data,
            decorators,
        };
    }

    getEntity(value, options?: any): any {
        return value;
    }

    validate(values): Promise<{ [field: string]: string }> {
        return Promise.resolve({});
    }
}
