import * as React from 'react';
import classNames from 'classnames';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotFieldError } from './SpotFieldError';

export class SpotTextInput extends BaseSpotComponent<SpotInputProps & React.HTMLProps<HTMLInputElement>> {
    render() {
        const { className, error, id, type, ...rest } = this.props;
        return (
            <span>
                <input className={classNames('spot-form__input', className)} type={type || 'text'} {...rest} id={this.idProp} />
                {error && <SpotFieldError meta={error} />}
            </span>
        );
    }
}
