import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import * as React from 'react';
import { Route, Link } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import { RootStore } from '../../store/RootStore';
import UsersPageStore from './UsersPageStore';
import { Cmp } from '@enterprise/common';
import { SpotLoading } from '@enterprise/spot';
import { SpotSearchbar, SpotPill } from '@enterprise/spot';
import UsersDetailsPage from './UsersDetailsPage';
import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValueGetterParams, MaterialDataGrid } from '@enterprise/material-data-grid';
import { UserEntity } from '../../core/entity/UserEntity';
import styles from '../dataGrid.module.scss';
import i18n from '../../i18n';
import * as _ from 'lodash';

enum GridColumns {
    Name = 'name',
    Status = 'status',
    Roles = 'roles',
    Actions = 'actions',
}

interface UsersPageProps {
    usersPage: UsersPageStore;
    routing: RouterStore;
    match: any;
}

interface UsersPageState {
    searchTerm: string;
}

@inject((allStores: RootStore) => ({ usersPage: allStores.pages.usersPage, routing: allStores.routing }))
@observer
export default class UsersPage extends React.Component<UsersPageProps, UsersPageState> {
    state: UsersPageState = {
        searchTerm: '',
    };

    private unsubscribe: any;
    onFilterChanged = value => {
        this.setState({ searchTerm: value });
    };

    componentDidMount() {
        this.unsubscribe = (this.props.routing as RouterStore).history.subscribe(() => {
            this.props.usersPage.load();
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
        this.props.usersPage.dispose();
    }

    render() {
        const { isLoaded, users } = this.props.usersPage;
        const { searchTerm } = this.state;

        const getUserName = ({ username }: UserEntity): string => {
            if (!username) {
                return i18n.t('usersPage:defaultPendingUserName', 'Pending User Name');
            }

            return String(username);
        };

        const getUserRoles = ({ roles }: UserEntity): string => {
            if (!roles) {
                return i18n.t('usersPage:defaultPendingUserRoles', 'Pending User Roles');
            }

            return _.head(roles);
        };

        const nameHeader = (): React.ReactElement => {
            return (
                <div className={styles.headerContainer}>
                    <div className={styles.headerCell}>{i18n.t('usersPage:Name', 'Name')}</div>
                </div>
            );
        };

        const statusHeader = (): React.ReactElement => {
            return (
                <div className={styles.headerContainer}>
                    <div className={styles.headerCell}>{i18n.t('usersPage:Status', 'Status')}</div>
                </div>
            );
        };

        const rolesHeader = (): React.ReactElement => {
            return (
                <div className={styles.headerContainer}>
                    <div className={styles.headerCell}>{i18n.t('usersPage:Roles', 'Roles')}</div>
                </div>
            );
        };

        const actionsHeader = (): React.ReactElement => {
            return <div className={styles.headerContainer}></div>;
        };

        const nameCell = (params: GridCellParams): React.ReactElement => {
            const rowValue = params.row;
            return (
                <div className={styles.rowCell}>
                    <div>
                        {rowValue.profile.firstName} {rowValue.profile.lastName}
                    </div>
                    <small>{rowValue.username}</small>
                </div>
            );
        };

        const statusCell = (params: GridCellParams): React.ReactElement => {
            const isActive = params.row.isActive;
            return <SpotPill type={isActive ? 'positive' : 'primary'}>{isActive ? 'Active' : 'Inactive'}</SpotPill>;
        };

        const rolesCell = (params: GridCellParams): React.ReactElement => {
            const roles = params.row.roles;
            return <span className={styles.rowCell}>{roles.join(', ')}</span>;
        };

        const actionsCell = (params: GridCellParams): React.ReactElement => {
            const rowValue = params.row;
            return (
                <div>
                    <Link to={`${this.props.match.path}/${rowValue.id}`} className="spot-link">
                        Manage User
                    </Link>
                </div>
            );
        };

        const columns: GridColDef[] = [
            {
                field: GridColumns.Name,
                flex: 1.5,
                renderHeader: nameHeader,
                renderCell: nameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    return getUserName(params.row);
                },
            },
            {
                field: GridColumns.Status,
                flex: 1,
                renderHeader: statusHeader,
                renderCell: statusCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const { isActive } = params.row;
                    return isActive;
                },
            },
            {
                field: GridColumns.Roles,
                flex: 2,
                renderHeader: rolesHeader,
                renderCell: rolesCell,
                valueGetter: (params: GridValueGetterParams) => {
                    return getUserRoles(params.row);
                },
            },
            {
                field: GridColumns.Actions,
                flex: 1,
                renderHeader: actionsHeader,
                renderCell: actionsCell,
                sortable: false,
            },
        ];

        let rows;
        const listUsers = _.values(users);

        if (!searchTerm) {
            rows = listUsers;
        }

        if (searchTerm) {
            rows = listUsers.filter(({ username }: UserEntity) => {
                return username.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }

        return (
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Cmp.If cond={!isLoaded}>
                            <SpotLoading text="Loading..." />
                        </Cmp.If>
                        <Cmp.If cond={isLoaded}>
                            <Route path={`${this.props.match.path}/:id`} exact={true} component={UsersDetailsPage} />
                            <div className="users-page">
                                <h1>Users</h1>
                                <div className="list">
                                    <SpotSearchbar onSubmit={this.onFilterChanged} />
                                    <MaterialDataGrid
                                        className={styles.dataGrid}
                                        loading={!isLoaded}
                                        columns={columns}
                                        rows={rows}
                                        rowHeight={70}
                                        autoHeight={true}
                                        headerHeight={40}
                                        disableSelectionOnClick={true}
                                    />
                                </div>
                            </div>
                        </Cmp.If>
                    </Col>
                </Row>
            </Container>
        );
    }
}
