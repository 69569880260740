import { SiteEntity } from './SiteEntity';
import { Type } from 'class-transformer';

export class RegionEntity {
    id?: string;

    name: string;

    organizationId?: string;

    @Type(() => SiteEntity)
    sites: SiteEntity[] = [];
}
