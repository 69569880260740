import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RouterStore } from 'mobx-react-router';
import AppStore from '../../store/AppStore';
import { Trans } from 'react-i18next';

interface ILoginProps {
    app: AppStore;
    routing: RouterStore;
}

// this should move to RouterPaths after it is merged in
export const UNAUTHORIZED_PAGE_PATH = '/unauthorized';

@observer
class UnauthorizedPage extends React.Component<ILoginProps> {
    logOut = () => {
        this.props.routing.push('/login');
    };

    render() {
        return (
            <Row style={{ minHeight: '100vh' }}>
                <Col md="3" />
                <Col md="6">
                    <h2 className={'unauthorized-header'}>
                        <Trans i18nKey="common:unauthorized">Unauthorized</Trans>
                    </h2>
                    <div>
                        <a className="spot-link" href="javascript:void(0)" onClick={this.logOut}>
                            <Trans i18nKey="common:signOut">Sign out</Trans>
                        </a>
                    </div>
                </Col>
                <Col md="3" />
            </Row>
        );
    }
}

export default inject('app', 'routing')(UnauthorizedPage);
