import { IsNumberString, IsPhoneNumber, Matches, IsEmail, IsIn, IsNotEmpty, Length } from 'class-validator';
import OnboardingConstants from '../../../../constants/OnboardingConstants';
import { validatePostalCode } from './CustomValidators';

export class CommunicatorUserRequestEntity {
    @IsNotEmpty()
    @Length(0, OnboardingConstants.SAPID_MAX_LENGTH)
    sapId: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.PRACTICE_NAME_MAX_LENGTH)
    practiceName: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.PRACTICE_ADDR_MAX_LENGTH)
    practiceStreetAddress: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.PRACTICE_CITY_MAX_LENGTH)
    practiceCity: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.PRACTICE_STATE_MAX_LENGTH)
    practiceState: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.PRACTICE_COUNTRY_MAX_LENGTH)
    @IsIn(['USA', 'CA', 'AUS', 'NZ'])
    practiceCountry: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.PRACTICE_ZIP_MAX_LENGTH)
    @validatePostalCode('practiceCountry', {
        message: 'Invalid postal code for given country.',
    })
    practicePostalCode: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.FIRST_NAME_MAX_LENGTH)
    contactFirstName: string;
    @IsNotEmpty()
    @Length(0, OnboardingConstants.LAST_NAME_MAX_LENGTH)
    contactLastName: string;
    @IsNotEmpty()
    @IsPhoneNumber('US')
    @IsNumberString({},{ message: 'Phone numbers must be numbers only, no dashes, words, or special characters' })
    @Length(OnboardingConstants.PHONE_MIN_LENGTH, OnboardingConstants.PHONE_MAX_LENGTH, { message: 'Phone number must be exactly 10 digits.' })
    contactPhoneNumber: string;
    @IsNotEmpty()
    @IsEmail()
    @Length(0, OnboardingConstants.EMAIL_MAX_LENGTH)
    contactEmail: string;
    @IsNotEmpty()
    @IsEmail({}, { message: 'Username must be a valid email address' })
    requestedUsername: string;
    @IsNotEmpty()
    @IsPhoneNumber('US')
    @IsNumberString({},{ message: 'Phone numbers must be numbers only, no dashes, words, or special characters' })
    @Length(OnboardingConstants.PHONE_MIN_LENGTH, OnboardingConstants.PHONE_MAX_LENGTH, { message: 'Phone number must be exactly 10 digits.' })
    twilioSMSNumber: string;
    practiceId?: string;
}
