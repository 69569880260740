import * as React from 'react';
import { AsyncHookResult } from '../hooks';
import {ReactHelpers} from "./helpers";

export type AsyncLoaderProps = AsyncHookResult & {
    onError?: (error: Error | any) => boolean | void;
    loader?: React.ReactElement;
    children?: React.ReactElement;
    errorComponent?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const AsyncLoader = ({ loading, loader, error, value, onError, errorComponent, children }: AsyncLoaderProps) => {
    if (loading) {
        return ReactHelpers.renderChildren(loader || 'Loading...');
    }
    if (error) {
        if (onError && onError(error)) {
            return null;
        }
        if (errorComponent) {
            return ReactHelpers.renderChildren(errorComponent, { error });
        }
        return <div className="error"> {error.message || error} </div>;
    }
    return ReactHelpers.renderChildren(children, { value });
};
