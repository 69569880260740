import * as React from 'react';
import { SpotModal } from '@enterprise/spot';
import { Container } from 'react-bootstrap';
import { Trans } from 'react-i18next';

interface DeleteWarningModalProps {
    title: string;
    message: string;
    onDelete: any;
    onCancel: any;
    isVisible: boolean;
}

export const DeleteWarningModal: React.FC<DeleteWarningModalProps> = ({ title, message, onDelete, onCancel, isVisible }) => {
    const makeFooter = () => {
        return (
            <React.Fragment>
                <button className="spot-modal__footer-cancel-button" onClick={onCancel}>
                    <Trans i18nKey="common:cancel">cancel</Trans>
                </button>
                <button className="spot-button spot-button--primary" onClick={onDelete}>
                    <Trans i18nKey="common:delete">Delete</Trans>
                </button>
            </React.Fragment>
        );
    };

    return (
        <SpotModal title={title} isVisible={isVisible} onHide={onCancel} footer={makeFooter}>
            {() => <Container>{message}</Container>}
        </SpotModal>
    );
};
