import { EntityFormType, Transformers } from '@enterprise/common';
import { FormProps } from 'react-final-form';
import { FeatureEntity } from '../../../services/FeatureService';
import { UserEntity } from '../../../core/entity/UserEntity';

export class UserFormType extends EntityFormType<UserEntity> {
    constructor() {
        super(UserEntity);
    }

    buildForm(data: UserEntity & { features: FeatureEntity[] }, options?: any): Partial<FormProps> | any {
        const organization = data.organization.value;
        const initialValues = new Transformers.ToPlainTransformer().transform({
            ...data,
            orgId: organization?.id,
            organization,
        });

        return super.buildForm(initialValues, options);
    }

    getEntity(value, options?: any): UserEntity & { features?: FeatureEntity[] } {
        return {
            ...new Transformers.ToTypeTransformer(UserEntity).transform(value),
        };
    }
}
