import TagManager from 'react-gtm-module'

interface TagManagerConfig {
    gtmId: string;
    dataLayer?: object;
    dataLayerName?: string;
    events?: object;
    auth?: string;
    preview?: string;
}

export function tagManagerInit(tagManagerConfig:TagManagerConfig):void {
    TagManager.initialize(tagManagerConfig)
}
