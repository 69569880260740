import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

interface ValidationArgs extends ValidationArguments {
    object: {
        practiceCountry: string;
    };
}

export const validatePostalCode = function(property: string, validationOptions?: ValidationOptions): (object: object, propertyName: string) => void {
    return function(object: object, propertyName: string): void {
        registerDecorator({
            constraints: [property],
            name: 'validatePostalCode',
            options: validationOptions,
            propertyName: propertyName,
            target: object.constructor,
            validator: {
                validate(postalCode: string, args: ValidationArgs): boolean {
                    const { practiceCountry } = args.object;
                    if (practiceCountry === 'USA') {
                        return new RegExp('^[0-9]{5}(?:-[0-9]{4})?$').test(postalCode);
                    } else if (practiceCountry === 'CA') {
                        return new RegExp('^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$').test(postalCode);
                    } else if (practiceCountry === 'AUS' || practiceCountry === 'NZ') {
                        return new RegExp('^\\d{4}$').test(postalCode);
                    } else {
                        return false;
                    }
                },
            },
        });
    };
};
