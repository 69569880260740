import React, { ReactElement, useLayoutEffect } from 'react';
import { GridRenderCellParams, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro';
import { SpotRadio } from '@enterprise/spot';
import styles from './radioSelectorCell.module.scss';
import classNames from 'classnames';

export const RadioSelectorCell = (props: GridRenderCellParams): ReactElement => {
    const { field, id, value, tabIndex } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const element = apiRef.current.getCellElement(id, field);
    const isSelectable = !rootProps?.isRowSelectable || rootProps?.isRowSelectable(apiRef.current.getRowParams(id));
    const handleChange = () => {
        apiRef.current.setSelectionModel([id]);
    };

    useLayoutEffect(() => {
        if (tabIndex === 0 && element) {
            element.tabIndex = -1;
        }
    }, [element, tabIndex]);

    return (
        <div className={classNames({ [styles.hidden]: !isSelectable })}>
            <SpotRadio
                className={styles.radio}
                name={'data-grid-radiobox-selector'}
                value={id}
                checked={Boolean(value)}
                onChange={() => handleChange()}
                ata-automation-id="data-grid-selector-cell"
            />
        </div>
    );
};
