import { BaseUserToken, EnterpriseUserToken, BaseHttp, BaseHttpOptions, HttpException, InvalidCredentialsException } from '@enterprise/common';
import { Notifications } from '../../pages/Notifications';

export default class EnterpriseApiHttp extends BaseHttp {
    checkAppSession;

    constructor(globalOptions: BaseHttpOptions = { baseUrl: '/' }, private userTokenSelector: () => BaseUserToken | EnterpriseUserToken) {
        super(globalOptions);
    }

    setAppSessionMethod(checkAppSession) {
        this.checkAppSession = checkAppSession;
    }

    /**
     *
     * @param config
     * @return {Promise}
     */
    async request(config: BaseHttpOptions = {}) {
        const token = this.userTokenSelector();
        if ((token as EnterpriseUserToken).token_type && (token as EnterpriseUserToken).access_token) {
            const appToken = await this.checkAppSession();
            config.headers = config.headers || {};
            if (!config.headers.Authorization) {
                config.headers.Authorization = `${(token as EnterpriseUserToken).token_type} ${appToken ||
                    (token as EnterpriseUserToken).access_token}`;
            }
        }

        return super.request(config).catch(e => {
            if (config.suppressNotifications && e.data && e.data.status === 401) {
                return Promise.resolve();
            }
            return Promise.reject(wrapExceptions(e));
        });
    }
}

function wrapExceptions(e: HttpException) {
    if (e instanceof InvalidCredentialsException) {
        Notifications.fromException(e);
    }
    return e;
}
