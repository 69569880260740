import { Service } from 'typedi';
import { computed, observable } from 'mobx';

@Service()
export default class UIStore {
    @observable title: string = `IDEXX Enterprise Admin`;

    @computed
    get name(): string {
        return this.title;
    }
}
