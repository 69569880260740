import * as React from 'react';
import { SpotFieldError, SpotLoading, SpotModal, SpotSvg, SpotTextInput } from '@enterprise/spot';
import { Container } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { EntityFormType, Form, FormField } from '@enterprise/common';
import { CommunicatorCreateEditUserEntity, CommunicatorCreateEditUserEntityNoUsername } from '../../../../../core/onboarding/Communicator/entity';
import i18n from '../../../../../i18n';
import { CommunicatorPageStore } from '../../CommunicatorPageStore';
import { PAGE_STORES } from '../../../../stores';
import useStore from '../../../../../hooks/useStore';
import { useState } from 'react';
import { Notifications } from '../../../../Notifications';
import { isUsernameEmail } from './Validation';
import UserEditResetPassword from './UserEditResetPassword';

export default function UserEditCreateModal(props) {
    const { idxId, username, displayName, email, phone, role, closeModal, isNewUser, practiceId, cognitoStatus } = props;
    const formType = new EntityFormType(isNewUser ? CommunicatorCreateEditUserEntity : CommunicatorCreateEditUserEntityNoUsername);
    const store = useStore<CommunicatorPageStore>(PAGE_STORES.CommunicatorPageStore);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showYesNo, setShowYesNo] = useState(false);
    const lockEmailField = isUsernameEmail(username);

    const handleDelete = async () => {
        setIsDeleting(true);
        setIsLoading(true);

        await store
            .deletePracticeUser(idxId, practiceId)
            .then(() => {
                Notifications.success(i18n.t('common:pages.onboarding.userDeletedSuccess', 'User was deleted successfully.'));
            })
            .catch(() => Notifications.error(i18n.t('common:pages.onboarding.userDeleteError', 'User delete failed.')));
        setIsLoading(false);
        setIsDeleting(false);
        closeModal();
    };

    return (
        <div className={'communicator-user-edit-create-modal-page'} id={'user-create-edit-modal'}>
            <SpotModal
                title={!isNewUser ? i18n.t('common:pages.onboarding.editUser', 'Edit User') : i18n.t('common:pages.onboarding.newUser', 'New User')}
                isVisible={true}
                onHide={closeModal}
                hideClose={isLoading}
            >
                {() => (
                    <Container>
                        {isLoading && (
                            <div className={'loading-screen'}>
                                <div className={'loading-spinner'}>
                                    <SpotLoading
                                        size={'large'}
                                        text={
                                            isDeleting
                                                ? i18n.t('common:pages.onboarding.deleting', 'Deleting')
                                                : i18n.t('common:pages.onboarding.saving', 'Saving')
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {!isLoading && (
                            <Form
                                data={{ username, userDisplayName: displayName, userEmail: email || null }}
                                formType={formType}
                                onSubmit={async data => {
                                    if (!data.userEmail) {
                                        Notifications.warn(
                                            i18n.t(
                                                'common:pages.onboarding.emailPhoneNumberText',
                                                'You must have at least a phone number or email address.',
                                            ),
                                        );
                                    } else if (isUsernameEmail(data.username) && Boolean(data.userEmail) && data.username !== data.userEmail) {
                                        console.log(data.username, data.email);
                                        Notifications.warn(i18n.t('common:pages.onboarding.emailUsername', 'Username must match the email field.'));
                                    } else {
                                        setIsLoading(true);
                                        if (isNewUser) {
                                            await store
                                                .createPracticeUser({
                                                    practiceId,
                                                    username: data.username,
                                                    friendlyName: data.userDisplayName,
                                                    email: data.userEmail,
                                                    role: 'ADMIN_USER',
                                                })
                                                .then(() =>
                                                    Notifications.success(
                                                        i18n.t('common:pages.onboarding.userCreatedSuccess', 'User was created successfully.'),
                                                    ),
                                                )
                                                .catch(() =>
                                                    Notifications.error(i18n.t('common:pages.onboarding.userCreatedError', 'User create failed.')),
                                                );
                                        } else {
                                            // Do not update the email if username is already an email.
                                            const payload = isUsernameEmail(data.username)
                                                ? {
                                                      idxId,
                                                      friendlyName: data.userDisplayName,
                                                      role: role,
                                                  }
                                                : {
                                                      idxId,
                                                      friendlyName: data.userDisplayName,
                                                      email: data.userEmail || '',
                                                      role: role,
                                                  };

                                            await store
                                                .updatePracticeUser(payload)
                                                .then(() =>
                                                    Notifications.success(
                                                        i18n.t('common:pages.onboarding.userUpdatedSuccess', 'User was updated successfully.'),
                                                    ),
                                                )
                                                .catch(() =>
                                                    Notifications.error(i18n.t('common:pages.onboarding.userUpdateError', 'User update failed.')),
                                                );
                                        }
                                        closeModal();
                                    }
                                }}
                            >
                                {({ handleSubmit }) => (
                                    <div>
                                        <div>
                                            <div className="spot-form__field-group" id={'username-field-group'}>
                                                <div className="spot-typography__text--body">
                                                    <Trans i18nKey="common:pages.onboarding.userName">User Name</Trans>
                                                </div>
                                                {!isNewUser && (
                                                    <div
                                                        className="spot-typography__text--body spot-typography__font-weight--bold"
                                                        style={{ wordBreak: 'break-word' }}
                                                        id={'existing-username'}
                                                    >
                                                        {username}
                                                    </div>
                                                )}
                                                {isNewUser && (
                                                    <FormField name={'username'}>
                                                        {({ input, meta }) => (
                                                            <div className="spot-form__field-group">
                                                                <SpotTextInput
                                                                    {...input}
                                                                    id={'new-username'}
                                                                    className="spot-form__input"
                                                                    type="text"
                                                                    name={input.name}
                                                                />
                                                                <SpotFieldError meta={meta} />
                                                            </div>
                                                        )}
                                                    </FormField>
                                                )}
                                            </div>

                                            {!isNewUser && (
                                                <UserEditResetPassword
                                                    cognitoStatus={cognitoStatus}
                                                    username={username}
                                                    email={email}
                                                    idxId={idxId}
                                                    phone={phone}
                                                    practiceId={practiceId}
                                                />
                                            )}

                                            <div className="spot-form__field-group" id={'display-name-field-group'}>
                                                <div className="spot-typography__text--body">
                                                    <Trans i18nKey="common:pages.onboarding.displayName">Display Name</Trans>
                                                </div>
                                                <FormField name={'userDisplayName'}>
                                                    {({ input, meta }) => (
                                                        <div className="spot-form__field-group">
                                                            <SpotTextInput
                                                                {...input}
                                                                id={'user-display-name'}
                                                                className="spot-form__input"
                                                                type="text"
                                                                name={input.name}
                                                            />
                                                            <SpotFieldError meta={meta} />
                                                        </div>
                                                    )}
                                                </FormField>
                                            </div>

                                            <div className="spot-form__field-group" id={'email-field-group'}>
                                                <div className="spot-typography__text--body">
                                                    <Trans i18nKey="common:pages.onboarding.email">Email</Trans>
                                                </div>

                                                {lockEmailField && (
                                                    <div
                                                        className="spot-typography__text--body spot-typography__font-weight--bold"
                                                        style={{ wordBreak: 'break-word' }}
                                                        id={'locked-email-field'}
                                                    >
                                                        {email || 'undefined'}
                                                    </div>
                                                )}
                                                {!lockEmailField && (
                                                    <FormField name={'userEmail'}>
                                                        {({ input, meta }) => (
                                                            <div className="spot-form__field-group">
                                                                <SpotTextInput
                                                                    {...input}
                                                                    id={'user-email'}
                                                                    className="spot-form__input"
                                                                    type="text"
                                                                    name={input.name}
                                                                />
                                                                <SpotFieldError meta={meta} />
                                                            </div>
                                                        )}
                                                    </FormField>
                                                )}
                                            </div>
                                        </div>
                                        <div className={'button-footer'}>
                                            <button className="spot-modal__footer-cancel-button" onClick={closeModal} id={'user-cancel-button'}>
                                                <Trans i18nKey="common:pages.onboarding.cancel">Cancel</Trans>
                                            </button>
                                            {!isNewUser && (
                                                <>
                                                    <button
                                                        id={'user-delete-button'}
                                                        className={
                                                            !showYesNo
                                                                ? 'spot-modal__footer-cancel-button user-edit-delete'
                                                                : 'spot-modal__footer-cancel-button user-edit-delete-black'
                                                        }
                                                        onClick={() => {
                                                            showYesNo ? setShowYesNo(false) : setShowYesNo(true);
                                                        }}
                                                    >
                                                        <SpotSvg icon="delete" className={'user-edit-delete-icon'} />
                                                        <Trans i18nKey="common:delete">Delete</Trans>
                                                    </button>
                                                    <div
                                                        className={'delete-yes-no spot-modal__footer-cancel-button'}
                                                        hidden={!showYesNo}
                                                        id={'yes-no-delete-container'}
                                                    >
                                                        <div onClick={handleDelete} className="delete-yes" id={'yes-delete-button'}>
                                                            Yes
                                                        </div>
                                                        |
                                                        <div
                                                            id={'no-delete-button'}
                                                            className={'delete-no'}
                                                            onClick={() => {
                                                                setShowYesNo(false);
                                                            }}
                                                        >
                                                            No
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <button
                                                className="spot-button spot-button--primary user-save-button"
                                                onClick={handleSubmit}
                                                id={'user-save-button'}
                                            >
                                                <Trans i18nKey="common:save">Save</Trans>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        )}
                    </Container>
                )}
            </SpotModal>
        </div>
    );
}
