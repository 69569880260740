import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '@enterprise/common';
import { SpotFieldError, SpotEditableText } from '@enterprise/spot';
import { removeWhitespaceOnBlur } from '../../../../core/util/Forms';

interface OrganizationFormControlProps {}

export class OrganizationFormControl extends React.PureComponent<OrganizationFormControlProps> {
    render() {
        const {} = this.props;
        return (
            <>
                <Row>
                    <Col className={'label-text'}>Name</Col>
                    <Col className={'label-text'}>Org ID</Col>
                </Row>
                <Row style={{ marginTop: 0 }}>
                    <Col style={{ marginTop: 0 }}>
                        <FormField name="name">
                            {({ input, meta }) => (
                                <div className={'fly-over-editable-container'}>
                                    <div className="spot-form__field-group">
                                        <SpotEditableText label={input.value && input.value.toString().length > 0 ? input.value : '-'}>
                                            <input {...input} name={input.name} className="spot-form__input" type="text" />
                                        </SpotEditableText>
                                        <SpotFieldError meta={meta} />
                                    </div>
                                </div>
                            )}
                        </FormField>
                    </Col>

                    <Col style={{ marginTop: 0 }}>
                        <FormField format={removeWhitespaceOnBlur} formatOnBlur={true} name="organization_id">
                            {({ input, meta }) => (
                                <div className={'fly-over-editable-container'}>
                                    <div className="spot-form__field-group">
                                        <SpotEditableText label={input.value && input.value.toString().length > 0 ? input.value : '-'}>
                                            <input {...input} name={input.name} className="spot-form__input" type="text" />
                                        </SpotEditableText>
                                        <SpotFieldError meta={meta} />
                                    </div>
                                </div>
                            )}
                        </FormField>
                    </Col>
                </Row>
            </>
        );
    }
}
