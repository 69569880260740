import { addPageStore } from '../services/ioc';
import UsersPageStore from './users/UsersPageStore';
import DataSourcePageStore from './dataSources/DataSourcePageStore';
import OrganizationsPageStore from './organizations/OrganizationsPageStore';
import GlobalSettingsPageStore from './globalSettings/GlobalSettingsPageStore';
import { OnboardingPageStore } from './onboarding/OnboardingPageStore';
import { CommunicatorPageStore } from './onboarding/Communicator/CommunicatorPageStore';

export enum PAGE_STORES {
    CommunicatorPageStore = 'communicatorPage',
    DataSourcePageStore = 'dataSourcePage',
    GlobalSettingsPageStore = 'globalSettingsPage',
    OnboardingPageStore = 'onboarding',
    OrganizationsPageStore = 'organizationsPage',
    UsersPageStore = 'usersPage',
}

export function registerPagesStore() {
    addPageStore(PAGE_STORES.UsersPageStore, UsersPageStore);
    addPageStore(PAGE_STORES.DataSourcePageStore, DataSourcePageStore);
    addPageStore(PAGE_STORES.OrganizationsPageStore, OrganizationsPageStore);
    addPageStore(PAGE_STORES.GlobalSettingsPageStore, GlobalSettingsPageStore);
    addPageStore(PAGE_STORES.OnboardingPageStore, OnboardingPageStore);
    addPageStore(PAGE_STORES.CommunicatorPageStore, CommunicatorPageStore);
}
