import { FormType, Transformers } from '@enterprise/common';
import { FormProps } from 'react-final-form';
import { FeatureEntity } from '../../../services/FeatureService';

export class GlobalFeaturesFormType extends FormType {
    buildForm(data: FeatureEntity[], options?: any): Partial<FormProps> | any {
        const initialValues = new Transformers.ToPlainTransformer().transform({
            features: data,
        });
        return super.buildForm(initialValues, options);
    }

    getEntity(value, options?: any): FeatureEntity[] {
        return value.features;
    }
}
