import * as React from 'react';
import classNames from 'classnames';
import { SpotSvg } from '../SpotSvg';

export interface SpotDropdownElement {
    id: string | number;
    value?: React.ReactNode | string;
    isDivider?: boolean;
    isHeading?: boolean;
    className?: string;
    selected?: boolean;
}

export interface SpotDropdownProps {
    title?: string | React.ReactNode;
    titleClassName?: string;
    titleIconClassName?: string;
    elements: SpotDropdownElement[];
    onChange: any;
    wrapText?: boolean;
    className?: string;
    ['data-automation-id']?: string;
}

interface State {
    isOpen: boolean;
}

export class SpotDropdown extends React.PureComponent<SpotDropdownProps, State> {
    private readonly wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
    static defaultProps = {
        wrapText: true,
    };
    state = {
        isOpen: false,
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const { title, titleClassName, titleIconClassName, elements, wrapText, className } = this.props;

        return (
            <div className={classNames('spot-dropdown', className)} style={{ display: 'inline-block' }} ref={this.wrapperRef}>
                <span onClick={this.openClose} className={titleClassName}>
                    {title}
                    <SpotSvg
                        icon={this.state.isOpen ? 'caret-up' : 'caret-down'}
                        className={titleIconClassName}
                        style={{ width: '12px', height: '12px', marginLeft: '5px', marginBottom: '2px' }}
                    />
                </span>

                <ul className="spot-dropdown__content" style={{ display: this.state.isOpen ? 'block' : 'none' }}>
                    {elements.map((element, index) => {
                        const { isHeading, isDivider, id, value, className: itemClassName } = element;
                        const classes = classNames(
                            {
                                'spot-dropdown__content-item': !isDivider,
                                'spot-dropdown__content--divider': isDivider,
                                'spot-dropdown__content-item--heading': isHeading,
                                'spot-dropdown__content-item--single-line': !wrapText,
                            },
                            itemClassName,
                        );
                        return (
                            <li key={id} value={id} className={classes} onClick={event => !isHeading && this.onChange(event)}>
                                {value}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    private openClose = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    private onChange = event => {
        const targetId = event.target.getAttribute('value');
        this.props.onChange(targetId);
        this.openClose();
    };

    private handleClickOutside = event => {
        const { isOpen } = this.state;
        if (isOpen && this.wrapperRef && !this.wrapperRef.current!.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    };
}
