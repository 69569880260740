import { Transform, Type } from 'class-transformer';
import { IsEmail } from 'class-validator';
import { UserProfile } from './UserProfile';

export class UserEntity {
    id?: string;

    @IsEmail()
    username: string;
    @Transform(value => value)
    organization?: string | any = {};

    roles: string[] = [];

    @Type(() => UserProfile)
    profile: UserProfile = new UserProfile();
}
