import {EntityFormType, FormType } from '@enterprise/common';
import { FormProps } from 'react-final-form';
import { Transformers } from '@enterprise/common';
import { validateSync } from 'class-validator';
import { SiteEntity } from '../../../../core/entity/SiteEntity';

export class PracticeEditFormType extends EntityFormType<SiteEntity> {

    constructor() {
        super(SiteEntity);
    }
    
    buildForm(data: SiteEntity, options?: any): Partial<FormProps> | any {
        return { initialValues: new Transformers.ToPlainTransformer().transform(data) };
    }

    getEntity(value, options?: any): SiteEntity {
        return new Transformers.ToTypeTransformer(SiteEntity).transform(value);
    }

    async validate(value): Promise<{ [field: string]: string }> {
        const errors = validateSync(new Transformers.ToTypeTransformer(SiteEntity).transform(value));
        return new Transformers.FunctionTransformer(data => {
            const result = {};
            (data as any[]).forEach(error => {
                result[error.property] = error.constraints[Object.keys(error.constraints)[0]];
            });
            return result;
        }).transform(errors);
    }
}
