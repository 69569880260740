export default {
    TITLE: {
        NEW: 'New',
        EDIT: 'Manage',
        ORG: 'Organization',
    },
    FIELDS: {
        DEFAULT: '—',
        SAP_MAX_LENGTH: 12,
    },
    ROUTES: {
        ORG: 'organizations',
    },
    PRACTICE_CONNECT: 'Practice Connect',
};
