import React from 'react';
import { Link } from 'react-router-dom';
import DataSourcePageStore from '../DataSourcePageStore';
import { RouterStore } from 'mobx-react-router';
import { SpotLoading, SpotSelectBox, SpotSelectOption, SpotTextInput } from '@enterprise/spot';
import { Form } from '@enterprise/common';
import { FormType } from '@enterprise/common';
import { toJS } from 'mobx';
import { RootStore } from '../../../store/RootStore';
import { inject, observer } from 'mobx-react';
import { Field } from 'react-final-form';
import { AccountEditFormType } from '../AccountEditFormType';
import { FlyOver } from '../../../components/flyover/FlyOver';
import { UpsertAccountEntity } from '../../../core/entity/UpsertAccountEntity';
import { RouterPaths } from '../../../router/RouterPaths';
import i18n from '../../../i18n';
import { Trans } from 'react-i18next';
import { PAGE_STORES } from '../../stores';
import { AccountSource } from '../../../core/entity/AccountEntity';

interface DataSourceEditPageProps {
    page: DataSourcePageStore;
    routing: RouterStore;
    match: any;
}

interface DataSourceEditPageState {
    form: FormType;
}

@inject((allStores: RootStore) => ({ page: allStores.pages[PAGE_STORES.DataSourcePageStore], routing: allStores.routing }))
@observer
export class DataSourceEditPage extends React.Component<DataSourceEditPageProps, DataSourceEditPageState> {
    state = {
        form: new AccountEditFormType(),
    };

    save = (data: UpsertAccountEntity) => {
        this.props.page.save(data).then(() => this.cancel());
    };

    deleteAccount = () => {
        if (this.props.page.account) {
            this.props.page!.removeAccount(this.props.page.account.id as string).then(() => this.cancel());
        }
    };

    componentDidMount() {
        if (this.isNewAccount) {
            this.props.page.createAccount();
        } else {
            this.props.page.fetchAccount(this.props.match.params.id);
        }
    }

    componentWillUnmount(): void {
        this.props.page.account = undefined;
    }

    cancel = () => {
        this.props.routing.history.push(RouterPaths.DataSourcePages.DataSources);
    };

    get isNewAccount(): boolean {
        return this.props.match.params.id === 'new';
    }

    render() {
        const account = toJS(this.props.page.account);
        const isLinked = Boolean(account?.organization);

        return (
            <>
                {!account && <SpotLoading />}
                {account && (
                    <FlyOver
                        title={i18n.t('common:pages.dataSources.manageDataSourceCredentials', 'Manage Data Source Credentials')}
                        cancelAction={this.cancel}
                        body={
                            <Form data={account} formType={this.state.form} onSubmit={this.save}>
                                {({ handleSubmit }) => (
                                    <div>
                                        <div>
                                            <div className="fly-over-form">
                                                <div>
                                                    <span className={'fly-over-section-title'}>
                                                        <Trans i18nKey={'common:pages.dataSources.connectionType'}>Connection Type</Trans>
                                                    </span>
                                                    <div className={'fly-over-group'}>
                                                        <Field name="source">
                                                            {({ input, meta }) => (
                                                                <SpotSelectBox
                                                                    disabled={!this.isNewAccount}
                                                                    placeholder={i18n.t('common:pleaseSelect', 'Please Select')}
                                                                    {...input}
                                                                    error={meta}
                                                                >
                                                                    {Object.entries(AccountSource).map(([label, value]) => (
                                                                        <SpotSelectOption key={value} value={value}>
                                                                            {label}
                                                                        </SpotSelectOption>
                                                                    ))}
                                                                </SpotSelectBox>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <span className={'fly-over-section-title'}>
                                                    <Trans i18nKey={'common:pages.dataSources.credentials'}>Credentials</Trans>:
                                                </span>
                                                <div className={'fly-over-group'}>
                                                    <Field name="name">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label className="spot-form__label">Name</label>
                                                                <SpotTextInput {...input} error={meta} placeholder="Name" />
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name="username">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label className="spot-form__label">
                                                                    <Trans i18nKey={'common:pages.dataSources.username'}>Username</Trans>
                                                                </label>
                                                                <SpotTextInput {...input} error={meta} placeholder="Username" />
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name="password">
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label className="spot-form__label">
                                                                    <Trans i18nKey={'common:pages.dataSources.password'}>Password</Trans>
                                                                </label>
                                                                <SpotTextInput
                                                                    {...input}
                                                                    autoComplete="new-password"
                                                                    type="password"
                                                                    error={meta}
                                                                    placeholder={i18n.t('common:pages.dataSources.password', 'Password')}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>

                                                {isLinked && (
                                                    <>
                                                        <span className={'fly-over-section-title'}>
                                                            <Trans i18nKey={'common:pages.dataSources.linkedOrganization'}>Linked organization</Trans>
                                                            :
                                                        </span>
                                                        <div className={'fly-over-group'}>{account.organization?.name}</div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="fly-over-actions">
                                            <Link style={{ marginRight: '10px' }} className="spot-link" onClick={this.cancel} to={'#'}>
                                                <Trans i18nKey={'common:cancel'}>cancel</Trans>
                                            </Link>
                                            {!this.isNewAccount && (
                                                <button className="spot-button spot-button--primary spot-button--sales" onClick={this.deleteAccount}>
                                                    <Trans i18nKey={'common:delete'}>Delete</Trans>
                                                </button>
                                            )}
                                            <button className="spot-button spot-button--primary" onClick={handleSubmit}>
                                                <Trans i18nKey={'common:saveChangesButton'}>Save Changes</Trans>
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        }
                    />
                )}
            </>
        );
    }
}
