import * as React from 'react';
import styles from './App.module.css';

import { ToastContainer } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import ConnectRouter from './router/ConnectRouter';
import routes from './router/routes';
import { RootStore } from './store/RootStore';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { Cmp } from '@enterprise/common';

@inject('rootStore')
@observer
class App extends React.Component<{ rootStore?: RootStore }> {
    componentDidMount() {
        (this.props.rootStore as RootStore).bootstrap();
    }
    render() {
        const { isLoaded } = this.props.rootStore as RootStore;
        return (
            <div className={styles.enterpriseApp}>
                <Cmp.If cond={isLoaded}>
                    <ErrorBoundary>
                        <ConnectRouter routes={routes} />
                    </ErrorBoundary>
                </Cmp.If>
                <Cmp.If cond={!isLoaded}>Loading...</Cmp.If>
                <ToastContainer
                    position="top-center"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick={true}
                    rtl={false}
                    draggable={true}
                    pauseOnHover={true}
                />
            </div>
        );
    }
}

export default App;
