import { Service } from 'typedi';
import { action, observable } from 'mobx';
import { DataSourceAccountService } from '../../services/DataSourceAccountService';
import { AccountEntity } from '../../core/entity/AccountEntity';
import { OrganizationEntity } from '../../core/entity/OrganizationEntity';
import { OrganizationsService } from '../../services/OrganizationsService';
import { SiteEntity } from '../../core/entity/SiteEntity';
import { Notifications } from '../Notifications';
import { FeatureEntity, FeatureService } from '../../services/FeatureService';
import i18n from '../../i18n';
import { PricingTierEntity } from '../../core/entity/PricingTierEntity';

@Service()
export default class OrganizationsPageStore {
    @observable isLoaded: boolean = false;

    @observable organizations: OrganizationEntity[] = [];

    @observable organization?: OrganizationEntity;
    @observable accounts: AccountEntity[];
    @observable pricingTiers: PricingTierEntity[];
    accountApplications: SiteEntity[];
    @observable applications: SiteEntity[];

    @observable features: FeatureEntity[] = [];

    constructor(private service: OrganizationsService, private accountService: DataSourceAccountService, private featureService: FeatureService) {
        this.accounts = [];
        this.pricingTiers = [];
        this.accountApplications = [];
        this.applications = [];
    }

    async load() {
        await this.fetchOrganizations();
        this.isLoaded = true;
    }

    @action
    async fetchOrganizations(): Promise<OrganizationEntity[]> {
        const organizations = await this.service.getOrganizations();
        this.organizations = organizations.sort((a, b) => a.name.localeCompare(b.name));
        return this.organizations;
    }

    @action
    async fetchOrganization(id: string): Promise<OrganizationEntity | null> {
        try {
            this.organization = await this.service.getOrganizationById(id);
            this.features = await this.featureService.getOrganizationFeatures(id);
            this.updateAccountApplications(this.organization.all_sites);

            this.fetchAccounts();
            this.fetchPricingTiers();
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }

        try {
            if (this.applications.length === 0) {
                this.applications = await this.service.getApplications(id);
            }
        } catch (e) {
            Notifications.fromException(e);
        }

        return this.organization;
    }

    @action async saveFeatures(org: OrganizationEntity | string, features: FeatureEntity[]) {
        const res = await this.featureService.setOrganizationFeatures(features, org);
        if (res) {
            Notifications.success(i18n.t('common:saveOrgFeaturesSuccess'));
            this.features = features;
        }

        return res;
    }

    @action
    async fetchAccounts() {
        if (this.accounts.length === 0) {
            this.accounts = await this.accountService.getAccounts();
        }
    }

    @action
    async fetchPricingTiers() {
        if (this.pricingTiers.length === 0) {
            this.pricingTiers = await this.service.getPricingTiers();
        }
    }

    @action
    async updateOrgagnizationPractice(site: SiteEntity) {
       return await this.service.updateOrganizationPractice(site);
    }

    @action
    async createOrganization(): Promise<OrganizationEntity> {
        this.organization = new OrganizationEntity();
        this.features = [];
        return this.organization;
    }

    @action
    async save(entity: OrganizationEntity): Promise<OrganizationEntity> {
        this.organization = await this.service.saveOrganization(entity);
        return this.organization as OrganizationEntity;
    }

    @action
    async setMasterApplication(orgId: string, applicationId: string): Promise<OrganizationEntity | null> {
        try {
            await this.service.setMasterApplication(orgId, applicationId);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        Notifications.success(i18n.t('common:masterApplicationIsSet'));
        return this.fetchOrganization(orgId);
    }

    @action
    async fetchAccountApplications(accountId: string) {
        const accountSites = this.accountApplications.filter(site => site.accountId === accountId);
        if (!accountSites.length) {
            try {
                const sites = await this.service.getAccountApplications(accountId);
                this.updateAccountApplications(sites);
            } catch (e) {
                Notifications.fromException(e);
                throw e;
            }
        }
    }

    private updateAccountApplications(sites: SiteEntity[]) {
        const siteAccountIds = sites.map(({ accountId }) => accountId);
        const notOrgSites = this.accountApplications.filter(({ accountId }) => !siteAccountIds.includes(accountId));
        const accountApplications = [...notOrgSites, ...sites];
        accountApplications.sort((a, b) => Number(a.id) - Number(b.id));
        this.accountApplications = accountApplications;
    }

    @action
    async linkAccounts(orgId: string, accountIds: string[]): Promise<OrganizationEntity> {
        try {
            this.organization = await this.service.linkAccounts(orgId, accountIds);
            Notifications.success(i18n.t('common:linkAccountsSuccess'));
            return this.organization;
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    @action
    async unlinkAccounts(orgId: string, accountIds: string[]): Promise<OrganizationEntity> {
        try {
            this.organization = await this.service.unlinkAccounts(orgId, accountIds);
            Notifications.success(i18n.t('common:unlinkAccountsSuccess'));
            return this.organization;
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    @action dispose() {
        this.organizations = [];
        this.accounts = [];
        this.accountApplications = [];
        this.applications = [];
    }

    @action
    async delete(organizationId: string) {
        try {
            const response = await this.service.deleteOrganization(organizationId);
            Notifications.success(i18n.t('common:deleteOrgSuccess'));
            return response;
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }
}
