import React, { memo, PropsWithChildren, ReactElement, useMemo } from 'react';
import { DataGridPro, DataGridProProps, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import styles from './materialDataGrid.module.scss';
import classNames from 'classnames';
import { SelectorCell } from '../selectorCell';
import { SelectorHeader } from '../selectorHeader';
import { RadioSelectorCell } from '../radioSelectorCell';
import { RadioSelectorHeader } from '../radioSelectorHeader';
import { defaultColumnDef, defaultDataGridProps } from '../../config';

enum CustomGridColumns {
    radioSelect = 'radio-box-selector',
}

export enum GridFilterOperatorType {
    Contains = 'contains',
    Equals = 'equals',
    StartWith = 'startsWith',
}

const gridRadioboxSelectionColDef: GridColDef = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    field: CustomGridColumns.radioSelect,
    renderHeader: RadioSelectorHeader,
    renderCell: RadioSelectorCell,
    type: 'boolean',
    valueGetter: (params: GridValueGetterParams): boolean => {
        const { id, api } = params;
        return api.getSelectedRows().has(id);
    },
};

export type MaterialDataGridProps<T> = PropsWithChildren<
    {
        className?: string;
        rows: T[];
        columns: GridColDef[];
        headerHeight?: number;
        rowHeight?: number;
        noRowsTitle?: ReactElement | string;
        noResultsTitle?: ReactElement | string;
        radioboxSelection?: true;
    } & Partial<DataGridProProps>
>;

export const MaterialDataGrid = memo(function MaterialDataGrid<T>(props: MaterialDataGridProps<T>) {
    const { className, rows, columns, checkboxSelection, radioboxSelection, noRowsTitle, noResultsTitle, components, ...gripProps } = {
        ...defaultDataGridProps,
        ...props,
    };

    const gridColumns = useMemo(() => {
        const columnsDef: GridColDef[] = columns.map(item => ({
            ...defaultColumnDef,
            headerName: item.field,
            ...item,
        }));

        if (checkboxSelection) {
            // apply custom cell and header to checkbox selection column
            GRID_CHECKBOX_SELECTION_COL_DEF.renderHeader = SelectorHeader;
            GRID_CHECKBOX_SELECTION_COL_DEF.renderCell = SelectorCell;
        }

        if (radioboxSelection) {
            // apply custom cell and header radiobox selector column to the beginning of columns array
            columnsDef.unshift(gridRadioboxSelectionColDef);
        }

        return columnsDef;
    }, [columns, checkboxSelection, radioboxSelection]);

    const gridComponents = useMemo(() => {
        const CustomNoRowsOverlay = () => <div className={styles.noRows}>{noRowsTitle || 'No Rows'}</div>;
        const CustomNoResultsOverlay = () => <div className={styles.noRows}>{noResultsTitle || 'No Results'}</div>;

        return {
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoResultsOverlay,
            ...components,
        };
    }, [noRowsTitle, noResultsTitle, components]);

    const [pageSize, setPageSize] = React.useState<number>(10);

    return (
        <div className={classNames(styles.holder, className)}>
            <DataGridPro
                components={gridComponents}
                rows={rows}
                columns={gridColumns}
                className={styles.grid}
                checkboxSelection={checkboxSelection}
                disableColumnFilter={true}
                disableMultipleSelection={true}
                pagination={true}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 15, 20]}
                {...gripProps}
            />
        </div>
    );
});
