import { Service } from 'typedi';
import { action, observable } from 'mobx';
import { DataSourceAccountService } from '../../services/DataSourceAccountService';
import { AccountEntity } from '../../core/entity/AccountEntity';
import { Notifications } from '../Notifications';
import { UpsertAccountEntity } from '../../core/entity/UpsertAccountEntity';
import i18n from '../../i18n';

@Service()
export default class DataSourcePageStore {
    @observable isLoaded: boolean = false;

    @observable accounts: AccountEntity[] = [];

    @observable account?: AccountEntity;

    constructor(private service: DataSourceAccountService) {}

    async load() {
        await this.fetchAccounts();
        this.isLoaded = true;
    }

    @action
    async fetchAccounts(): Promise<AccountEntity[]> {
        const accounts = await this.service.getAccounts();
        this.accounts = accounts.sort((a, b) => a.name.localeCompare(b.name));
        return this.accounts;
    }

    @action
    async fetchAccount(id: string): Promise<AccountEntity> {
        try {
            this.account = await this.service.getAccountById(id);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        return this.account;
    }

    @action
    createAccount(): AccountEntity {
        this.account = new AccountEntity();
        return this.account;
    }

    @action
    async save(entity: UpsertAccountEntity): Promise<AccountEntity> {
        try {
            this.account = await this.service.saveAccount(entity);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        this.accounts = this.accounts.filter(i => i.id !== (this.account as AccountEntity).id);
        this.accounts.push(entity);
        Notifications.success(i18n.t('common:pages.dataSources.upsertSuccess', 'Successfully added/updated data source'));
        return this.account;
    }

    @action
    async removeAccount(id: string): Promise<any> {
        try {
            await this.service.removeAccount(id);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        Notifications.success(i18n.t('common:pages.dataSources.deleteSuccess', 'Successfully deleted data source'));
        this.accounts = this.accounts.filter(i => i.id !== id);
    }

    @action dispose() {
        this.accounts = [];
        this.account = undefined;
    }
}
