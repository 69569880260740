import React from 'react';
import { SpotLoading } from '@enterprise/spot';
import { Trans } from 'react-i18next';
import i18n from '../../../../../i18n';
import { CommunicatorPageStore } from '../../CommunicatorPageStore';
import { PAGE_STORES } from '../../../../stores';
import useStore from '../../../../../hooks/useStore';
import { useState } from 'react';

export default function UserEditResetPassword({ cognitoStatus, username, idxId, practiceId, email, phone }) {
    const store = useStore<CommunicatorPageStore>(PAGE_STORES.CommunicatorPageStore);
    const [resetPasswordStatus, setResetPasswordStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handlePasswordReset = async () => {
        if (!cognitoStatus) {
            setResetPasswordStatus(
                i18n.t('common:pages.onboarding.resetPasswordNoSSO', {
                    username,
                    defaultValue: `Error: No SSO Info was found for ${username}.`,
                }),
            );
        } else {
            setIsLoading(true);
            const recipient = email ? email : phone;
            try {
                await store.setUserPassword(idxId, practiceId);
                setResetPasswordStatus(
                    i18n.t('common:pages.onboarding.resetPasswordSuccess', {
                        recipient,
                        defaultValue: `Temporary password sent to ${recipient}`,
                    }),
                );
            } catch (e) {
                setResetPasswordStatus(
                    i18n.t('common:pages.onboarding.resetPasswordFailure', {
                        recipient,
                        defaultValue: `An error was encountered when attempting to reset the password for ${recipient}.`,
                    }),
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="communicator-user-edit-reset-password-page" id={'user-create-reset-password'}>
            {isLoading ? (
                <div id={'reset-password-loading'}>
                    <SpotLoading size={'small'} />
                </div>
            ) : (
                <>
                    <div className="spot-typography__text--body" id="reset-password-status">
                        {resetPasswordStatus}{' '}
                    </div>
                    <div
                        className="spot-button spot-button--link"
                        style={{ paddingLeft: 0 }}
                        id="reset-password-button-link"
                        onClick={handlePasswordReset}
                    >
                        {resetPasswordStatus ? (
                            <Trans i18nKey="common:pages.onboarding.sendAgain">Send Again</Trans>
                        ) : (
                            <Trans i18nKey="common:pages.onboarding.sendTemporaryPassword">Send Temporary Password to User</Trans>
                        )}
                    </div>
                </>
            )}
            <hr />
        </div>
    );
}
