import * as React from 'react';
import { SpotCheckbox, SpotCheckboxGroup, SpotModal, SpotTextarea, SpotButton } from '@enterprise/spot';
import { environment } from '../../environment/environment';
import { isEqual } from 'lodash';
import { Trans } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import i18n from '../../i18n';
import { useState } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { FeatureEntity } from '../../services/FeatureService';

function checkEquals(prevValues: Array<string>, newValues: Array<string>) {
    return isEqual(prevValues, newValues);
}

export const FeaturesCheckboxes = ({ name, extend = [] }: { name: string; extend?: { name: string; value: string }[] }) => (
    <SpotCheckboxGroup>
        <Field name={name}>
            {(props: FieldRenderProps<HTMLElement>) => <ItemsGroup {...props} name={name} options={[...environment.features, ...extend]} />}
        </Field>
    </SpotCheckboxGroup>
);

function ItemsGroup<T extends HTMLElement>({
    input,
    meta,
    options,
}: FieldRenderProps<T> & { name: string; options: { name: string; value: string }[] }) {
    const value = input.value as FeatureEntity[];
    const [criteria, setCriteria] = useState<string>();
    const toggle = (event, name: string) => {
        if (event.target.checked) {
            input.onChange([...value, { name }] as any);
        } else {
            input.onChange(value.filter(item => item.name !== String(name)) as any);
        }
    };

    const hasItem = (feature: string) => {
        return Boolean(input?.value?.find(item => item.name === feature));
    };

    const criteriaValue = (name: string) => {
        const value = input?.value?.find(item => item.name === name)?.criteria;
        return JSON.stringify(value);
    };

    const renderModifyCriteriaModalController = (name: string) => ({ open }) => {
        const onOpen = () => {
            setCriteria(criteriaValue(name) || '{}');
            open();
        };

        return (
            <SpotButton onClick={onOpen} disabled={!hasItem(name)}>
                <Trans i18nKey="modifyCriteria">Modify Criteria</Trans>
            </SpotButton>
        );
    };

    const renderModifyCriteriaModalFooter = (name: string) => ({ close }) => {
        const onApply = () => {
            updateCriteria(name, criteria);
            setCriteria(undefined);
            close();
        };

        return (
            <div>
                <SpotButton isPrimary={true} onClick={onApply} disabled={criteriaValidation(criteria)?.error}>
                    <Trans i18nKey="common:apply">Apply</Trans>
                </SpotButton>
            </div>
        );
    };

    const criteriaValidation = (value?: string) => {
        try {
            if (!value) {
                return { touched: true, error: i18n.t('common:criteriaMustBeValidJson', 'Criteria must be valid json') };
            } else if (JSON.parse(value)) {
                return undefined;
            }
        } catch (e) {}

        return { touched: true, error: i18n.t('common:criteriaMustBeValidJson', 'Criteria must be valid json') };
    };

    const updateCriteria = (name: string, criteria?: string) => {
        const field = value?.find(item => item.name === name);

        if (field) {
            field.criteria = criteria && JSON.parse(criteria);
            input.onChange(value as any);
        }
    };

    return (
        <>
            {options.map(({ name, value }, index) => (
                <div key={index}>
                    <Row>
                        <Col>
                            <SpotCheckbox
                                type="checkbox"
                                defaultChecked={hasItem(value)}
                                // tslint:disable-next-line
                                onClick={event => toggle(event, value)}
                            >
                                {name}
                            </SpotCheckbox>
                        </Col>

                        <Col>
                            <SpotModal
                                title="Modify Criteria"
                                controller={renderModifyCriteriaModalController(value)}
                                footer={renderModifyCriteriaModalFooter(value)}
                            >
                                {() => (
                                    <>
                                        <label className="spot-form__label">
                                            <Trans i18nKey="common:inputCriteria">Criteria in json format</Trans>
                                        </label>
                                        <div>
                                            <SpotTextarea
                                                value={criteria}
                                                id={'criteria'}
                                                onChange={({ target }) => setCriteria(target.value)}
                                                type="text"
                                                placeholder={i18n.t('common:criteria', 'Criteria')}
                                                error={criteriaValidation(criteria)}
                                            />
                                        </div>
                                    </>
                                )}
                            </SpotModal>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
}
