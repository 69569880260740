import { Service } from 'typedi';
import { OnboardingPageStoreInterface } from '../OnboardingPageStore';
import { observable } from 'mobx';
import { CommunicatorUserRequestEntity } from '../../../core/onboarding';
import { Notifications } from '../../Notifications';
import { OnboardingService } from '../../../services/OnboardingService';
import { CommunicatorPracticeEntity } from '../../../core/onboarding/Communicator/entity/CommunicatorPracticeEntity';
import i18n from '../../../i18n';
import { CommunicatorPracticeOnboardingDetailsEntity } from '../../../core/onboarding/Communicator/entity/CommunicatorPracticeOnboardingDetailsEntity';

@Service()
export class CommunicatorPageStore implements OnboardingPageStoreInterface {
    @observable
    items: CommunicatorPracticeEntity[] = [];

    constructor(private communicatorService: OnboardingService, private onboardingService: OnboardingService) {}

    async load(): Promise<void> {
        const result = await this.getPracticesRequest();
        this.items = result.practices;
    }

    getBySapId(sapId?: string): Promise<CommunicatorPracticeEntity | undefined> {
        if (!sapId) {
            return Promise.reject(null);
        }
        return Promise.resolve(this.items.find(i => i.sapId === sapId));
    }

    create(data: Partial<CommunicatorUserRequestEntity> = {}): Promise<CommunicatorUserRequestEntity> {
        return Promise.resolve(Object.assign(new CommunicatorUserRequestEntity(), data));
    }

    save(data: Partial<CommunicatorUserRequestEntity> = {}): Promise<CommunicatorUserRequestEntity> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(Object.assign(new CommunicatorUserRequestEntity(), data));
            }, 2000);
        });
    }

    async getPracticeOnboardingDetails(sapId): Promise<CommunicatorPracticeOnboardingDetailsEntity> {
        try {
            return await this.onboardingService.getPracticeOnboardingDetails(sapId);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    async getPracticesRequest(): Promise<{ practices: CommunicatorPracticeEntity[] }> {
        try {
            return await this.communicatorService.getPractices();
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    async enrollmentRequest(data: CommunicatorUserRequestEntity): Promise<any> {
        try {
            if (!/\S+@\S+\.\S+/.test(data.requestedUsername)) {
                throw Error(
                    i18n.t('common:pages.onboarding.mustBeValidEmail', {
                        field: 'Username',
                        defaultValue: 'Username must be a valid email address',
                    }),
                );
            }

            if (data.contactEmail && data.requestedUsername !== data.contactEmail) {
                throw Error(
                    i18n.t('common:pages.onboarding.emailUsername', {
                        defaultValue: `Username must match contact email`,
                    }),
                );
            }

            const existingPractice = await this.getBySapId(data.sapId);
            if (existingPractice) {
                throw Error(
                    i18n.t('common:pages.onboarding.sapIdAlreadyRegistered', {
                        sapId: data.sapId,
                        defaultValue: `The Sap ID ${data.sapId} has already been registered with a Communicator practice.`,
                    }),
                );
            }

            const user = await this.getUserExists(data.requestedUsername);
            if (user.exists) {
                throw Error(
                    i18n.t('common:pages.onboarding.usernameAlreadyRegistered', {
                        username: data.requestedUsername,
                        defaultValue: `The username ${data.requestedUsername} has already been registered with another account. Try using the contact's email address.`,
                    }),
                );
            }

            await this.onboardingService.enrollment(data).then(r => {
                return this.onboardingService.startTask(r.id);
            });
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        Notifications.success(`Successfully submitted enrollment request`);
    }

    async getPracticeUsers(practiceId) {
        try {
            return await this.onboardingService.getPracticeUsers(practiceId);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    async getPracticeUser(idxId) {
        try {
            return await this.onboardingService.getPracticeUser(idxId);
        } catch (e) {
            throw e;
        }
    }

    async updatePracticeUser(user) {
        try {
            return await this.onboardingService.updatePracticeUser(user);
        } catch (e) {
            throw e;
        }
    }

    async createPracticeUser(user) {
        try {
            return await this.onboardingService.createPracticeUser(user);
        } catch (e) {
            throw e;
        }
    }

    async deletePracticeUser(idxId, practiceId) {
        try {
            return await this.onboardingService.deletePracticeUser(idxId, practiceId);
        } catch (e) {
            throw e;
        }
    }

    async setUserPassword(idxId, practiceId) {
        const user = { idxId, practiceId };
        try {
            return await this.onboardingService.setUserPassword(user);
        } catch (e) {
            throw e;
        }
    }

    async getUserExists(username) {
        try {
            return await this.onboardingService.getUserExists(username);
        } catch (e) {
            throw e;
        }
    }

    dispose(): Promise<any> {
        this.items = [];
        return Promise.resolve();
    }
}
