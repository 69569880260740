import React from 'react';
import { FlyOver } from '../../../components/flyover/FlyOver';
import { NavLink, useHistory } from 'react-router-dom';
import { EntityFormType, Form } from '@enterprise/common';
import { CommunicatorUserRequestEntity } from '../../../core/onboarding/Communicator/entity';
import TextFormRow from './TextFormRow';
import useStore from '../../../hooks/useStore';
import { CommunicatorPageStore } from './CommunicatorPageStore';
import { PAGE_STORES } from '../../stores';

export const CommunicatorNewRequestPage = () => {
    const formType = new EntityFormType(CommunicatorUserRequestEntity);
    const history = useHistory();

    const store = useStore<CommunicatorPageStore>(PAGE_STORES.CommunicatorPageStore);

    function closeFlyOver() {
        history.push('/onboarding/communicator');
    }

    return (
        <>
            <FlyOver
                title={`Create new request`}
                cancelAction={closeFlyOver}
                body={
                    <Form
                        data={{}}
                        formType={formType}
                        keepDirtyOnReinitialize={true}
                        onSubmit={async data => {
                            await store
                                .enrollmentRequest(data)
                                .then(() => {
                                    closeFlyOver();
                                })
                                .catch(e => {
                                    console.error(e);
                                });
                        }}
                    >
                        {({ handleSubmit, submitting }) => (
                            <div>
                                <div>
                                    <div className="fly-over-form">
                                        <span className={'fly-over-section-title'}>Common:</span>
                                        <div className={'fly-over-group'}>
                                            <TextFormRow label="Requested Username" name="requestedUsername" />
                                            <TextFormRow label="SAP ID" name="sapId" />
                                            <TextFormRow label="Site ID" name="practiceId" />
                                            <TextFormRow label="Twilio SMS Number" name="twilioSMSNumber" />
                                        </div>
                                        <span className={'fly-over-section-title'}>Contact:</span>
                                        <div className={'fly-over-group'}>
                                            <TextFormRow label="First Name" name="contactFirstName" />
                                            <TextFormRow label="Last Name" name="contactLastName" />
                                            <TextFormRow label="Phone Number" name="contactPhoneNumber" />
                                            <TextFormRow label="Email" name="contactEmail" />
                                        </div>
                                        <span className={'fly-over-section-title'}>Practice:</span>
                                        <div className={'fly-over-group'}>
                                            <TextFormRow label="Name" name="practiceName" />
                                            <TextFormRow label="Country" name="practiceCountry" />
                                            <TextFormRow label="City" name="practiceCity" />
                                            <TextFormRow label="State (abbreviated)" name="practiceState" />
                                            <TextFormRow label="Street Address" name="practiceStreetAddress" />
                                            <TextFormRow label="Postal Code" name="practicePostalCode" />
                                        </div>
                                    </div>
                                    <div className="fly-over-actions">
                                        <NavLink style={{ marginRight: '10px' }} className="spot-link" to={'/onboarding/communicator'}>
                                            Cancel
                                        </NavLink>
                                        <button disabled={submitting} className="spot-button spot-button--primary" onClick={handleSubmit}>
                                            Create
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                }
            />
        </>
    );
};
