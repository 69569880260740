import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table';
import './SpotTable.scss';
import SpotPaging from '../Paging/SpotPaging';
import SpotIcon from '../Icon/SpotIcon';
import { Spot } from '../Spot';

SpotTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    rowKey: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    currentSort: PropTypes.array,
    visibleColumnsHandler: PropTypes.func,
    pageSize: PropTypes.number,
    filterValue: PropTypes.string,
    onRowClick: PropTypes.func,
    onPageChange: PropTypes.func,
    currentPage: PropTypes.number,
    onColumnSort: PropTypes.func,
    onFilter: PropTypes.func,
};

SpotTable.defaultProps = {
    visibleColumnsHandler: columns => columns,
    pageSize: 5,
    filterValue: null,
    onRowClick: () => null,
    onPageChange: () => null,
    currentPage: 0,
    sortable: false,
    currentSort: [],
    onColumnSort: () => null,
    onFilter: () => null,
};

const useVisibleColumnsHandler = visibleColumnsHandler => {
    return plugins => {
        plugins.visibleColumns.push(columns => visibleColumnsHandler(columns));
    };
};

export default function SpotTable({
    columns,
    data,
    rowKey,
    sortable,
    visibleColumnsHandler,
    pageSize,
    filterValue,
    onRowClick,
    onPageChange,
    currentPage,
    currentSort,
    onColumnSort,
    onFilter,
}) {
    const initialState = {
        pageSize,
        pageIndex: currentPage,
        sortBy: currentSort,
    };

    const plugins = [useVisibleColumnsHandler(visibleColumnsHandler), useGlobalFilter];

    if (sortable) {
        initialState.sortBy = currentSort;
        plugins.push(useSortBy);
    }

    plugins.push(usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        state: { pageIndex },
        setGlobalFilter,
        rows,
    } = useTable({ columns, data, initialState, disableSortRemove: true, disableMultiSort: true }, ...plugins);

    const getSortableProps = column => {
        const toggleProps = column.getSortByToggleProps();
        return {
            ...toggleProps,
            onClick(e) {
                toggleProps.onClick(e);
                setTimeout(() => {
                    const desc = column.isSortedDesc;

                    // Sort was turned off for column
                    if (desc === undefined) {
                        // Send empty value to onColumnSort callback
                        return onColumnSort([]);
                    }

                    // desc is true when column is sorted in descending direction
                    // and false when ascending
                    onColumnSort([
                        {
                            id: column.id,
                            desc,
                        },
                    ]);
                }, 0);
            },
        };
    };

    useEffect(() => {
        setGlobalFilter(filterValue);
    }, [filterValue, setGlobalFilter]);

    useEffect(() => {
        const data = rows.map(row => row.original);
        onFilter(data, filterValue);
    }, [filterValue, rows, onFilter]);

    const tableClassNames = ['spot-data-table'];

    if (sortable) {
        tableClassNames.push('spot-data-table--sortable');
    }

    const handlePageChange = num => {
        onPageChange(num);
        gotoPage(num);
    };

    return (
        <>
            <table {...getTableProps()} className={tableClassNames.join(' ')}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                            {headerGroup.headers.map((column, i) => {
                                return (
                                    <th
                                        {...column.getHeaderProps(column.canSort ? getSortableProps(column) : undefined)}
                                        key={i}
                                        className={`header-${i} column-${i}`}
                                    >
                                        {column.render('Header')}
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SpotIcon iconName={Spot.icons.caretDown} title={'Desc'} className={'spot-data-table__sort-icon'} />
                                            ) : (
                                                <SpotIcon iconName={Spot.icons.caretUp} title={'Asc'} className={'spot-data-table__sort-icon'} />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} onClick={e => onRowClick(e, row)} key={row.original[rowKey]}>
                                {row.cells.map((cell, i) => {
                                    return (
                                        <td {...cell.getCellProps()} className={`cell-${i} column-${i}`} key={`${row.original[rowKey]}-column-${i}`}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <SpotPaging
                totalRecords={rows.length}
                perPage={pageSize}
                currentPage={pageIndex + 1}
                goToPage={num => handlePageChange(num - 1)}
                testId={null}
                className={null}
            />
        </>
    );
}
