import * as React from 'react';
import { SpotLoading, SpotSvg } from '@enterprise/spot';

interface FlyOverProps {
    title: string;
    body: any;
    cancelAction: any;
    loading?: boolean;
    style?: object;
}

interface FlyOverState {
    form: any;
}

export class FlyOver extends React.Component<FlyOverProps, FlyOverState> {
    render() {
        return (
            <div className={'fly-over-container'} style={this.props.style}>
                <div className={'fly-over-content'}>
                    <div className={'fly-over-header'}>
                        <h4>{this.props.title}</h4>
                        <div className={'fly-over-x'} onClick={this.props.cancelAction}>
                            <SpotSvg className={'fly-over-header-cancel'} icon="cancel" />
                        </div>
                    </div>
                    {this.props.loading ? (
                        <div className={'fly-over-spot-loading'}>
                            <SpotLoading />
                        </div>
                    ) : (
                        <div className={'fly-over-body'}>{this.props.body}</div>
                    )}
                </div>
            </div>
        );
    }
}
