import { SiteEntity } from '../../../../core/entity/SiteEntity';
import { observer } from 'mobx-react-lite';
import { SpotLoading, SpotSelectBox, SpotSelectOption, SpotTextInput } from '@enterprise/spot';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { Form } from '@enterprise/common';
import i18n from '../../../../i18n';
import { toJS } from 'mobx';
import { PracticeEditFormType } from './PracticeEditFormType';
import { RootStore } from '../../../../store/RootStore';
import { inject } from 'mobx-react';
import styles from './PracticeEditPage.module.scss';
import { Trans } from 'react-i18next';
import { DeleteWarningModal } from '../../../../components/modals/DeleteWarningModal';
import { Link } from 'react-router-dom';
import OrganizationsPageStore from '../../OrganizationsPageStore';
import { OrganizationEntity } from '../../../../core/entity/OrganizationEntity';
import { Notifications } from '../../../Notifications';

interface PracticeEditPageProps {
    page: OrganizationsPageStore;
    organization?: OrganizationEntity;
    practice: SiteEntity;
    cancel: any;
}

interface PracticeEditPageState {
    practiceList?: any;
    form?: PracticeEditFormType;
}

export const PracticeEditPage = (props: PracticeEditPageProps) => {
    const [form, setForm] = useState(new PracticeEditFormType());
    const save = async (site: any) => {
        console.log(site);
        props.page.updateOrgagnizationPractice(site).then(()=>{
            Notifications.success(i18n.t('common:savePracticeSuccess'));
            props.cancel(site);
        }).catch((err)=>{
            Notifications.fromException('Problem with request.');
        })
    };

    const site: SiteEntity = props.practice;
    return (
        <div className={styles.editPracticeContainer}>
            <Form data={site} formType={form} onSubmit={save}>
                {({ handleSubmit, values, form, pristine, invalid }) => (
                    <div>
                        <div>
                            <div className={styles.siteIdText}>Site ID</div>
                            <div className={styles.siteIdName}>
                                {props.practice?.siteId}
                                <div className={'fly-over-group'}>
                                    <div className={styles.leftPracticeEditPane}>
                                        <Field name="idexxPracticeName">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:idexxPracticeName', 'IDEXX Practice Name')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:idexxPracticeName', 'IDEXX Practice Name')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="idexxSapId">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:idexxSapId', 'IDEXX SAP')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:idexxSapId', 'IDEXX SAP')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="idexxZip">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:idexxZip', 'IDEXX ZIP')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:idexxZip', 'IDEXX ZIP')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    <div className={styles.rightPracticeEditPane}>
                                        <Field name="name">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:practiceName', 'Practice Name')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:idexxPracticeName', 'IDEXX Practice Name')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="sapId">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">{i18n.t('common:pages:organizations:sapId', 'SAP ID')}</label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:sapId', 'SAP ID')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="zip">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">{i18n.t('common:pages:organizations:zip', 'ZIP')}</label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:zip', 'ZIP')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="address">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:streetAddress', 'Street Address')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:streetAddress', 'Street Address')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="address2">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:aptSuiteEtc', 'Apt, suite, etc')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:aptSuiteEtc', 'Apt, suite, etc')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="city">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">{i18n.t('common:pages:organizations:city', 'City')}</label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:city', 'City')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="state">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">{i18n.t('common:pages:organizations:state', 'State')}</label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:state', 'State')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="country">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:country', 'Country')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:country', 'Country')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="contactPerson">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:contactPerson', 'Contact Person')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:contactPerson', 'Contact Person')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="email">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:email', 'Email')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:email', 'Email')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <Field name="phone">
                                            {({ input, meta }) => (
                                                <div className={styles.textBoxContainer}>
                                                    <label className="spot-form__label">
                                                        {i18n.t('common:pages:organizations:phone', 'Phone Number')}
                                                    </label>
                                                    <SpotTextInput
                                                        {...input}
                                                        error={meta}
                                                        placeholder={i18n.t('common:pages:organizations:phone', 'Phone Number')}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fly-over-actions">
                            <Link style={{ marginRight: '10px' }} className={`${styles.cancelButton} spot-link`} onClick={props.cancel} to={'#'}>
                                <Trans i18nKey="common:pages:organizations:cancel">Cancel</Trans>
                            </Link>
                            <button disabled={pristine || invalid} className="spot-button spot-button--primary" onClick={handleSubmit}>
                                <Trans i18nKey="common:saveChangesButton">Save Changes</Trans>
                            </button>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
};
