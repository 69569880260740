import * as React from 'react';

export const renderChildren = (children, props = {}) => {
    if (!children) return null;
    if (typeof children === 'function') return children(props);
    if (typeof children === 'string') return <>{children}</>;
    if (children instanceof Array) {
        return children.map(child => React.cloneElement(child, props));
    }
    return React.cloneElement(children, props);
};
