import { Service, Inject } from 'typedi';
import BaseHttp from './http/EnterpriseApiHttp';
import { UserEntity } from '../core/entity/UserEntity';
import { Transformers } from '@enterprise/common';

@Service()
export class UsersService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getUsers(): Promise<UserEntity[]> {
        return this.http
            .get(`/users`)
            .then(data => new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(UserEntity)).transform(data));
    }

    getUserById(id: string): Promise<UserEntity> {
        return this.http.get(`/users/:id`, { pathParams: { id } }).then(data => new Transformers.ToTypeTransformer(UserEntity).transform(data));
    }

    updateUser(id: string, roles: string[], isActive: boolean, organizationId: string): Promise<UserEntity> {
        return this.http
            .put(`/users/:id`, null, { params: { roles, isActive, organizationId }, pathParams: { id } })
            .then(data => new Transformers.ToTypeTransformer(UserEntity).transform(data));
    }
}
