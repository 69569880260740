import * as React from 'react';

import { Notifications } from './Notifications';

interface ErrorBoundaryProps {
    fallbackComponent?: React.Component;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { error?: any }> {
    state = { error: null };

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error };
    }

    constructor(props) {
        super(props);
    }

    componentDidCatch(error, info) {
        Notifications.fromException(error, {});
    }

    render() {
        const { fallbackComponent } = this.props;
        if (this.state.error) {
            return fallbackComponent ? fallbackComponent : <DefaultErrorPage />;
        }

        return this.props.children;
    }
}

export class DefaultErrorPage extends React.PureComponent {
    render() {
        return (
            <div className="default-error-page">
                <div className="container">
                    <div className="error">
                        <h1>500</h1>
                        <h2>error</h2>
                        <h3>
                            Try to reload page or go to the <a href="/users">Home</a>
                        </h3>

                        <p>Ruh-roh, something just isn't right... Time to paw through your logs and get down and dirty in your stack-trace;)</p>
                    </div>
                    <div className="stack-container">
                        <div className="card-container">
                            <div className="perspec">
                                <div className="card">
                                    <div className="writing">
                                        <div className="topbar">
                                            <div className="red" />
                                            <div className="yellow" />
                                            <div className="green" />
                                        </div>
                                        <div className="code">
                                            <ul />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
