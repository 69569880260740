import React from 'react';
import { RouterStore } from 'mobx-react-router';
import { RootStore } from '../../store/RootStore';
import { inject, observer } from 'mobx-react';
import { Link, Route } from 'react-router-dom';
import DataSourcePageStore from './DataSourcePageStore';
import { SpotLoading, SpotSvg } from '@enterprise/spot';
import { Cmp } from '@enterprise/common';
import { SpotSearchbar } from '@enterprise/spot';
import { DataSourceEditPage } from './edit/DataSourceEditPage';
import { Col, Row, Container } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { PAGE_STORES } from '../stores';
import { GridCellParams, GridColDef, GridRowModel, GridValueGetterParams, MaterialDataGrid } from '@enterprise/material-data-grid';
import { AccountEntity } from '../../core/entity/AccountEntity';
import styles from '../dataGrid.module.scss';
import i18n from '../../i18n';
import * as _ from 'lodash';
import faker from 'faker';

enum GridColumns {
    Name = 'name',
    Actions = 'actions',
}

interface DataSourcePageProps {
    page: DataSourcePageStore;
    routing: RouterStore;
    match: any;
}

interface DataSourcePageState {
    searchTerm: string;
}

@inject((allStores: RootStore) => ({ page: allStores.pages[PAGE_STORES.DataSourcePageStore], routing: allStores.routing }))
@observer
export class DataSourcePage extends React.Component<DataSourcePageProps, DataSourcePageState> {
    state = {
        searchTerm: '',
    };

    private unsubscribe: any;

    componentDidMount() {
        this.unsubscribe = (this.props.routing as RouterStore).history.subscribe(() => {
            this.props.page.load();
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
        this.props.page.dispose();
    }

    onFilterChanged = value => {
        this.setState({ searchTerm: value });
    };

    render() {
        const { isLoaded, accounts } = this.props.page;
        const { searchTerm } = this.state;

        const getAccountName = ({ name }: AccountEntity): string => {
            if (!name) {
                return i18n.t('dataSourcePage:defaultPendingAccountName', 'Pending Account Name');
            }

            return String(name);
        };

        const nameHeader = (): React.ReactElement => {
            return (
                <div className={styles.headerContainer}>
                    <div className={styles.headerCell}>{i18n.t('dataSourcePage:Name', 'Name')}</div>
                </div>
            );
        };

        const actionsHeader = (): React.ReactElement => {
            return <div className={styles.headerContainer}></div>;
        };

        const nameCell = (params: GridCellParams): React.ReactElement => {
            const rowValue = params.row;
            return (
                <div className={styles.rowCell}>
                    <div>{rowValue.name}</div>
                </div>
            );
        };

        const actionsCell = (params: GridCellParams): React.ReactElement => {
            const rowValue = params.row;
            return (
                <div>
                    <Link id={'dataSourcePage-table-action'} to={`${this.props.match.path}/${rowValue.id}`} className="spot-link">
                        <Trans i18nKey={'common:pages.dataSources.manage'}>Manage</Trans>
                    </Link>
                </div>
            );
        };

        const columns: GridColDef[] = [
            {
                field: GridColumns.Name,
                flex: 1.5,
                renderHeader: nameHeader,
                renderCell: nameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    return getAccountName(params.row);
                },
            },
            {
                field: GridColumns.Actions,
                flex: 1,
                renderHeader: actionsHeader,
                renderCell: actionsCell,
                sortable: false,
            },
        ];

        let rows;
        const listAccounts = _.values(accounts);

        if (!searchTerm) {
            rows = listAccounts;
        }

        if (searchTerm) {
            rows = listAccounts.filter(({ name }: AccountEntity) => {
                return name.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }

        const getRowId = (row: GridRowModel): string => {
            const { id } = row as AccountEntity;
            return `${id}`;
        };

        return (
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <div className="data-source-page">
                            <Cmp.If cond={!isLoaded}>
                                <SpotLoading text={i18n.t('common:loading', 'Loading...')} />
                            </Cmp.If>
                            <Cmp.If cond={isLoaded}>
                                <Row>
                                    <Col xs={4}>
                                        <h1 className={'page-header'}>
                                            <Trans i18nKey={'common:pages.dataSources.title'}>Data Sources</Trans>
                                        </h1>
                                        <small>
                                            <strong>
                                                <Trans i18nKey={'common:pages.dataSources.subTitle'}>
                                                    Manage data sources in IDEXX Enterprise here
                                                </Trans>
                                            </strong>
                                        </small>
                                    </Col>
                                    <Col xs={5} />
                                    <Col xs={3}>
                                        <Link to={`${this.props.match.path}/new`} className="spot-link">
                                            <Trans i18nKey={'common:pages.dataSources.addDataSource'}>Add Data Source</Trans>
                                            <SpotSvg icon="add" className={'add-new-icon'} />
                                        </Link>
                                    </Col>
                                </Row>
                                <Route path={`${this.props.match.path}/:id`} exact={true} component={DataSourceEditPage} />

                                <div className="list">
                                    <SpotSearchbar onSubmit={this.onFilterChanged} />
                                    <MaterialDataGrid
                                        className={styles.dataGrid}
                                        loading={!isLoaded}
                                        columns={columns}
                                        rows={rows}
                                        rowHeight={40}
                                        autoHeight={true}
                                        headerHeight={40}
                                        disableSelectionOnClick={true}
                                        getRowId={getRowId}
                                    />
                                </div>
                            </Cmp.If>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
