import * as React from 'react';
import { inject } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import AppStore from '../../store/AppStore';
import { Layout } from '@enterprise/spot';
import { EnterpriseUserToken } from '@enterprise/common';
import { Image } from '../../components/helpers/Image';
import { menu, MenuItem } from './menu';
import { Header } from './Header';

class MainLayout extends React.PureComponent<{ app: AppStore }> {
    scrollContainerElement?: HTMLElement | null = null;
    logout = () => {
        const { app } = this.props;
        app.logout();
    };

    filterMenuItemsByRole(): MenuItem[] {
        const { token } = this.props.app;
        return menu.filter(menuItem => menuItem.roles.some(role => token.hasRole(role)));
    }

    render() {
        const { children, app } = this.props;
        let orgName = '';
        if ((app.token as EnterpriseUserToken).user.organization) {
            orgName = (app.token as EnterpriseUserToken).user.organization.name;
        }
        return (
            <div className="main-layout">
                <Header
                    menu={this.filterMenuItemsByRole()}
                    user={{
                        username: app.token.getUsername(),
                        orgName,
                    }}
                    onLogout={this.logout}
                    logo={<Image url="IDEXX Logo White SEP2015.png" />}
                />
                <Container fluid={true}>
                    <Row>
                        <Col sm={3} md={2} className="sidebar">
                            <Layout.SideNavigation menu={this.filterMenuItemsByRole()} />
                        </Col>
                        <Col sm={9} md={10} className="main col-sm-offset-3 col-md-offset-2">
                            <div
                                id="connect-page-content"
                                className="connect-page-content"
                                ref={el => {
                                    this.scrollContainerElement = el;
                                }}
                            >
                                <div>
                                    <div id="scroll-to-here" />
                                    {children}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default inject('app')(MainLayout);
