import Axios, { AxiosInstance, Method } from 'axios';
import { HttpException, InvalidCredentialsException, NotFoundException, ValidationException } from '../exceptions/exceptions';

export interface BaseHttpOptions {
    baseUrl?: string;
    url?: string;
    method?: Method;
    data?: any;
    headers?: any;
    params?: any;
    pathParams?: any;
    suppressNotifications?: boolean;
    transformRequest?: any[];
    transformResponse?: any[];
}

export class BaseHttp {
    private client: AxiosInstance;

    constructor(protected globalOptions: BaseHttpOptions = { baseUrl: '/' }) {
        this.client = Axios.create({
            baseURL: globalOptions.baseUrl,
        });
    }

    private safelyInterpolateUrl({ pathParams, url }: BaseHttpOptions) {
        if (!url || !pathParams) return url;
        return Object.entries(pathParams).reduce((agg, [key, value]) => agg.replace(`:${key}`, encodeURIComponent(`${value}`)), url);
    }

    /**
     *
     * @param config
     * @return {Promise}
     */
    request(config: BaseHttpOptions = {}) {
        config.url = this.safelyInterpolateUrl(config);
        const cfg = Object.assign({}, config, {
            headers: Object.assign(config.headers || {}, this.globalOptions.headers || {}),
            params: Object.assign(config.params || {}, this.globalOptions.params || {}),
            // transformRequest: [].concat(config.transformRequest || [], this.globalOptions.transformRequest || []),
            // transformResponse: [].concat(config.transformResponse || [], this.globalOptions.transformResponse || [])
        });

        return this.client
            .request(cfg)
            .then(response => response.data)
            .catch(e => {
                let status = e.response ? e.response.status : 500;
                let message = e.response ? e.response.statusText : e.message;
                if (e.response && e.response.data) {
                    message = e.response.data.message ? e.response.data.message.error || e.response.data.message : message;
                    status = e.response.data.status || status;
                }
                return Promise.reject(wrapExceptions(new HttpException(status, message, e)));
            });
    }

    /**
     * @param url
     * @param config
     * @return {Promise}
     */
    get(url, config: BaseHttpOptions = {}) {
        return this.request({ method: 'GET', url, ...config });
    }

    delete(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'DELETE', url, ...config, data });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    post(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'POST', url, ...config, data });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    put(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'PUT', url, ...config, data });
    }

    /**
     * @param url
     * @param data
     * @param config
     * @return {Promise}
     */
    patch(url, data?: any, config: BaseHttpOptions = {}) {
        return this.request({ method: 'PATCH', url, ...config, data });
    }
}

function wrapExceptions(e: HttpException) {
    switch (Number(e.status)) {
        case 403:
        case 401: {
            return new InvalidCredentialsException(e.message, e);
        }
        case 422: {
            return ValidationException.fromHttpException(e);
        }
        case 404: {
            return new NotFoundException(e.message, e);
        }
        default: {
            return e;
        }
    }
}
