import { inject, observer } from 'mobx-react';
import * as React from 'react';
import arrayMutators from 'final-form-arrays';
import { Field } from 'react-final-form';
import { Col, Row } from 'react-bootstrap';
import { RootStore } from '../../store/RootStore';
import UsersPageStore from './UsersPageStore';
import { SpotLoading, SpotSelectBox, SpotSelectOption, SpotSvg, SpotToggle } from '@enterprise/spot';
import { UserEntity } from '../../core/entity/UserEntity';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { Form } from '@enterprise/common';
import { RouterStore } from 'mobx-react-router';
import { FlyOver } from '../../components/flyover/FlyOver';
import OrganizationsPageStore from '../organizations/OrganizationsPageStore';
import { Notifications } from '../Notifications';
import { UserFormType } from './formType/UserFormType';
import { FeaturesCheckboxes } from '../../components/formControls/FeaturesCheckboxes';

interface UsersPageProps {
    usersPage: UsersPageStore;
    organizationsPage: OrganizationsPageStore;
    routing: RouterStore;
    match: any;
}

interface UsersPageState {
    isLoaded: boolean;
}

@inject((allStores: RootStore) => ({
    usersPage: allStores.pages.usersPage,
    organizationsPage: allStores.pages.organizationsPage,
    routing: allStores.routing,
}))
@observer
export default class UsersDetailsPage extends React.Component<UsersPageProps, UsersPageState> {
    state = {
        isLoaded: false,
    };

    private form = new UserFormType();
    private data: any;

    componentDidMount() {
        this.props.organizationsPage.load().then(() => {
            this.props.usersPage.fetchUser(this.props.match.params.id).then(() => {
                this.data = toJS(this.props.usersPage.user);
                this.data.orgId = this.data.organization ? this.data.organization.id : undefined;
                this.setState({ isLoaded: true });
            });
        });
    }

    save = ({ features, ...data }) => {
        if (!data.roles || data.roles.length === 0) {
            Notifications.error('At least one role should be specified');
            return;
        }
        this.props.usersPage.saveFeatures(data.id, features).then(result => {
            Notifications.success(`Successfully saved users features`);
            return result;
        });
        this.props.usersPage.updateUser(data.id, data.roles, data.isActive, data.orgId).then(() => {
            this.props.routing.history.push('/users');
        });
    };

    cancel = () => {
        this.props.routing.history.push(`/users`);
    };

    render() {
        const { usersPage } = this.props;
        return (
            <React.Fragment>
                {!this.state.isLoaded && <SpotLoading />}
                {this.state.isLoaded && (
                    <FlyOver
                        title="Manage User"
                        cancelAction={this.cancel}
                        body={
                            <Form
                                data={{ ...this.data, ...{ features: usersPage.features } }}
                                formType={this.form}
                                mutators={{
                                    // potentially other mutators could be merged here
                                    ...arrayMutators,
                                }}
                                onSubmit={this.save}
                            >
                                {({ handleSubmit }) => (
                                    <div>
                                        <div>
                                            <div className="fly-over-form">
                                                <span className={'fly-over-section-title'}>Status:</span>
                                                <div className={'fly-over-group'}>
                                                    <Row>
                                                        <Col className={'label-text'}>Active:</Col>
                                                        <Col>
                                                            <Field name="isActive" type="checkbox">
                                                                {({ input }) => {
                                                                    input.checked = !!input.value;
                                                                    return <SpotToggle {...input} />;
                                                                }}
                                                            </Field>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <span className={'fly-over-section-title'}>User Details:</span>
                                                <div className={'fly-over-group'}>
                                                    <Row>
                                                        <Col className={'label-text'}>First Name:</Col>
                                                        <Col>{(this.data as UserEntity).profile.firstName}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={'label-text'}>Last Name:</Col>
                                                        <Col>{(this.data as UserEntity).profile.lastName}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className={'label-text'}>Email:</Col>
                                                        <Col>{(this.data as UserEntity).username}</Col>
                                                    </Row>
                                                </div>

                                                <span className={'fly-over-section-title'}>Roles:</span>
                                                <div className={'fly-over-group'} style={{ marginTop: '10px' }}>
                                                    <fieldset className="spot-form__checkbox-group" role="group">
                                                        <div className="spot-form__checkbox-group-inner">
                                                            {['USER', 'ADMIN', 'SUPER_ADMIN'].map(role => (
                                                                <label key={role} className="spot-form__checkbox">
                                                                    <Field
                                                                        className="spot-form__checkbox-input"
                                                                        name="roles"
                                                                        component="input"
                                                                        type="checkbox"
                                                                        value={role}
                                                                    />
                                                                    <span className="spot-form__checkbox-inner">
                                                                        <span className="spot-form__checkbox-visual">
                                                                            <SpotSvg
                                                                                icon="checkmark"
                                                                                className="spot-icon spot-form__checkbox-checked-icon"
                                                                            />
                                                                            <SpotSvg
                                                                                icon="checkbox-indeterminate"
                                                                                className="spot-icon spot-form__checkbox-indeterminate-icon"
                                                                            />
                                                                        </span>
                                                                        <span className="spot-form__checkbox-label"> {role} </span>
                                                                    </span>
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </fieldset>
                                                </div>

                                                <span className={'fly-over-section-title'}>Organization:</span>
                                                <div className={'fly-over-group'} style={{ marginTop: '10px' }}>
                                                    <Field name="orgId">
                                                        {({ input, meta }) => (
                                                            <Row>
                                                                <Col xs={8}>
                                                                    <SpotSelectBox {...input} error={meta}>
                                                                        {this.props.organizationsPage.organizations.map(org => (
                                                                            <SpotSelectOption key={org.id} value={org.id}>
                                                                                {org.name}
                                                                            </SpotSelectOption>
                                                                        ))}
                                                                    </SpotSelectBox>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Field>
                                                </div>
                                                <Row>
                                                    <Col xs={12}>
                                                        <span className={'fly-over-section-title'}>Features:</span>
                                                        <div className={'fly-over-group'}>
                                                            <FeaturesCheckboxes name="features" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="fly-over-actions">
                                            <Link style={{ marginRight: '10px' }} className="spot-link" onClick={this.cancel} to={'#'}>
                                                cancel
                                            </Link>
                                            <button className="spot-button spot-button--primary" onClick={handleSubmit}>
                                                Save Changes
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                        }
                    />
                )}
            </React.Fragment>
        );
    }
}
