import React from 'react';
import { SpotSvg } from '@enterprise/spot';
import moment from 'moment-timezone';
import { Trans } from 'react-i18next';

export default function TaskCard({ task }) {
    const { onCompleteResult, onErrorResult, implementation, updatedDate } = task;

    // This can map a simple map of implementation -> key, but backup text was added because of an app-wide translation bug
    const implementationTranslationsMap = {
        CommunicatorOnboardingSetupTask: {
            key: 'communicatorOnboardingSetupTask',
            backupName: 'Credentials Sent to Customer',
            backupDescription: 'Initial practice submission. <br> Email with username and temporary password sent to user.',
        },
        CommunicatorEulaSigningTask: {
            key: 'communicatorEulaSigningTask',
            backupName: 'EULA Signature',
            backupDescription: 'User fills out the EULA and submits it to DataPoint.',
        },
        CommunicatorEulaSubmissionTask: {
            key: 'communicatorEulaSubmissionTask',
            backupName: 'EULA Submission',
            backupDescription: 'Submission of EULA and practice questions to Datapoint successful.',
        },
        CommunicatorCheckHeartbeatTask: {
            key: 'communicatorCheckHeartbeatTask',
            backupName: 'Cornerstone Connection',
            backupDescription: 'Cornerstone agent has been set up.',
        },
        CommunicatorUpdateSiteIdTask: {
            key: 'communicatorUpdateSiteIdTask',
            backupName: 'Site ID Setup',
            backupDescription: 'Communicator has been updated with the Site ID passed from Cornerstone.',
        },
        MdsOnboardingTask: {
            key: 'mdsOnboardingTask',
            backupName: 'MDS Onboarding',
            backupDescription: 'Configure MDS with the Twilio account and webhooks.',
        },
        CommunicatorWelcomeEmailTask: {
            key: 'communicatorWelcomeEmailTask',
            backupName: 'Welcome Email',
            backupDescription: 'Sent the customer a welcome email, alerting them that the site is available.',
        },
    };
    console.log(task);
    const implementationTextObject = implementationTranslationsMap[implementation];
    let svgName = 'checkbox-indeterminate';
    if (onCompleteResult) {
        svgName = 'checkmark';
    } else if (onErrorResult) {
        svgName = 'alert-notification';
    }
    // 03/14/2020 8:43 AM EST
    const updatedDateFormat = 'MM/DD/YYYY h:mm A z';
    const updatedDateDisplay = moment.tz(updatedDate, moment.tz.guess()).format(updatedDateFormat);
    return (
        <div className={'task-card-container'}>
            <div className={'task-card-first-line'}>
                <div>
                    <SpotSvg icon={svgName} className={`task-card-svg-${svgName}`} />
                    <span className={'task-card-step-name'}>
                        <Trans i18nKey={`common:pages.onboarding.taskFriendlyNames.${implementationTextObject.key}`}>
                            {implementationTextObject.backupName}
                        </Trans>
                    </span>
                </div>
                <span>{updatedDateDisplay}</span>
            </div>
            <div className={'task-card-description'}>
                <Trans i18nKey={`common:pages.onboarding.taskDescriptions.${implementationTextObject.key}`}>
                    {implementationTextObject.backupDescription}
                </Trans>
            </div>
        </div>
    );
}
