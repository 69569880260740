import { EntityFormType, Transformers } from '@enterprise/common';
import { FormProps } from 'react-final-form';
import { OrganizationEntity } from '../../../core/entity/OrganizationEntity';
import { FeatureEntity } from '../../../services/FeatureService';
import { validate, ValidationError } from 'class-validator';

export class OrganizationFormType extends EntityFormType<OrganizationEntity> {
    constructor() {
        super(OrganizationEntity);
    }

    buildForm(data: OrganizationEntity & { features: FeatureEntity[] }, options?: any): Partial<FormProps> | any {
        const initialValues = new Transformers.ToPlainTransformer().transform({
            ...data,
        });
        return super.buildForm(initialValues, options);
    }

    getEntity(value, options?: any): OrganizationEntity & { features?: FeatureEntity[] } {
        return {
            ...new Transformers.ToTypeTransformer(OrganizationEntity).transform(value),
        };
    }

    async validate(value: OrganizationEntity): Promise<{ [key: string]: string }> {
        const transformedData = new Transformers.ToTypeTransformer(OrganizationEntity).transform(value);
        const errors: ValidationError[] = await validate(transformedData);
        return new Transformers.FunctionTransformer(data => {
            return this.filterErrors(data);
        }).transform(errors);
    }

    private filterErrors = (errors: ValidationError[]): object => {
        const result: object = {};
        errors.forEach((error: ValidationError): void => {
            if (error.children?.length) {
                result[error.property] = [];
                error.children.forEach(child => {
                    result[error.property][child.property] = {};
                    child.children?.forEach(next => {
                        result[error.property][child.property][next.property] = next.constraints?.[Object.keys(next.constraints)[0]];
                    });
                });
            } else {
                result[error.property] = error.constraints?.[Object.keys(error.constraints)[0]];
            }
        });
        return result;
    };
}
