import { IsNotEmpty } from 'class-validator';
import { AccountEntity } from './AccountEntity';
import i18n from '../../i18n';

export class UpsertAccountEntity extends AccountEntity {
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('common:pages.dataSources.username') }),
    })
    username: string;

    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('common:pages.dataSources.password') }),
    })
    password?: string;
}
