import * as React from 'react';
import { SpotLoading } from '@enterprise/spot';
import { inject } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';

class RedirectToRouteComponent extends React.Component<{ to: string; state?: any; routing?: RouterStore }> {
    componentDidMount() {
        const { to, routing } = this.props;
        routing && routing.push(to);
    }

    render() {
        return (
            <div>
                <SpotLoading text="Redirecting..." />
            </div>
        );
    }
}

export default inject('routing')(RedirectToRouteComponent);
