// DATA SOURCES
const DataSources = '/data-sources';

const DataSourcePages = {
    DataSources,
};

// GLOBAL SETTINGS
const GlobalSettings = '/global-settings';

const GlobalSettingPages = {
    GlobalSettings,
};

// ONBOARDING
const OnBoarding = '/onboarding';

const OnboardingPages = {
    OnBoarding,
    Communicator: OnBoarding + '/communicator',
};

// ORGANIZATIONS
const Organizations = '/organizations';

const OrganizationsPages = {
    Organizations,
};

// USERS
const Users = '/users';

const UserPages = {
    Users,
};

export const RouterPaths = {
    DataSourcePages,
    GlobalSettingPages,
    OrganizationsPages,
    OnboardingPages,
    UserPages,
};
