// import {Layout} from '@enterprise/spot';
//
// export class Header extends Layout.Header {
//
// }

import * as React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { SpotSvg } from '@enterprise/spot';

export interface HeaderProps {
    user: { username: string; orgName: string };
    menu: { label: string; path: string }[];
    logo?: React.ReactElement<any>;
    onLogout: () => void;
}

export class Header extends React.Component<HeaderProps, { isOpen: boolean }> {
    state = {
        isOpen: false,
    };
    private toogler: HTMLElement | null = null;

    componentDidMount() {
        if (document) {
            document.addEventListener('mousedown', this.handleClickOutside, true);
        }
    }

    componentWillUnmount() {
        if (document) {
            document.removeEventListener('mousedown', this.handleClickOutside, true);
        }
    }

    handleClickOutside = e => {
        if (this.toogler && !this.toogler.contains(e.target)) {
            this.setState({ isOpen: false });
        }
    };

    toggleUserMenu = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    logout = () => {
        if (this.props.onLogout) {
            this.props.onLogout();
        }
    };

    render() {
        const { user, menu, logo } = this.props;
        const { isOpen } = this.state;

        return (
            <div className="connect-page-header">
                <Navbar collapseOnSelect={true} expand="lg" variant="dark" style={{ userSelect: 'none', backgroundColor: '#4A4A4A' }}>
                    {logo && <Navbar.Brand>{logo}</Navbar.Brand>}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="links">
                            {menu.map((item, i) => (
                                <NavLink key={i} className="nav-link" to={item.path}>
                                    {item.label}
                                </NavLink>
                            ))}
                        </Nav>
                        <Nav className="mr-auto" />
                        <Nav>
                            <div className="nav-items">
                                <div className="nav-item" ref={toogler => (this.toogler = toogler)}>
                                    <div className="spot-di">
                                        <div className="spot-di__header spot-di__header--white" onClick={this.toggleUserMenu}>
                                            <a className="spot-di__header-userinfo">
                                                <span className="spot-link--bold">{user.username}</span>
                                                <span className="spot-link--small spot-link--ellipsis">{user.orgName}</span>
                                            </a>
                                            <div className="spot-di__header-icon">
                                                <SpotSvg icon="caret-down" />
                                            </div>
                                        </div>
                                        <div
                                            className="spot-popover spot-popover--no-inset spot-popover--align-rightcaret"
                                            style={{ display: isOpen ? 'block' : 'none', marginRight: '50px' }}
                                        >
                                            <div className="spot-di__content">
                                                <div className="spot-di__content-mylocations">
                                                    <a className="spot-link" href="javascript:void(0)" onClick={this.logout}>
                                                        Sign Out
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <div className="connect-page-header-line" />
            </div>
        );
    }
}
