import { Type } from 'class-transformer';
import { IsNotEmpty, IsOptional, ValidateNested } from 'class-validator';
import { RegionEntity } from './RegionEntity';
import { SiteEntity } from './SiteEntity';
import i18n from '../../i18n';
import { FeatureEntity } from '../../services/FeatureService';
import { AccountEntity } from './AccountEntity';
import { PricingTierEntity } from './PricingTierEntity';
import { UserEntity } from './UserEntity';

export class OrganizationEntity {
    id: string;

    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('common:orgName') }),
    })
    name: string;

    @IsNotEmpty()
    pricingTier: PricingTierEntity;

    @Type(() => RegionEntity)
    regions: RegionEntity[] = [];

    accounts?: AccountEntity[];

    practicesAmount?: number;

    features?: FeatureEntity[];

    @IsOptional()
    organization_id: string | null = null;

    @ValidateNested({ each: true })
    @Type(() => SiteEntity)
    all_sites: SiteEntity[] = [];

    @Type(() => UserEntity)
    owner: UserEntity;
}
