import * as React from 'react';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotSvg } from '../SpotSvg';
import { SpotFieldError } from './SpotFieldError';

export interface SpotCheckboxProps extends SpotInputProps {
    isSmall?: boolean;
}

export class SpotCheckbox extends BaseSpotComponent<SpotCheckboxProps> {
    render() {
        const { className, isSmall, name, error, children, id, ...rest } = this.props;
        return (
            <span>
                <label className={['spot-form__checkbox', isSmall ? 'spot-form__checkbox--small' : '', className].join(' ')} htmlFor={this.idProp}>
                    <input id={this.idProp} name={name} {...rest} type="checkbox" className="spot-form__checkbox-input" />
                    <span className="spot-form__checkbox-inner">
                        <span className="spot-form__checkbox-visual">
                            <SpotSvg className="spot-form__checkbox-checked-icon" icon="checkmark" />
                            <SpotSvg className="spot-form__checkbox-indeterminate-icon" icon="checkbox-indeterminate" />
                        </span>
                        <span className="spot-form__checkbox-label"> {children} </span>
                    </span>
                </label>
                {error && <SpotFieldError meta={error} />}
            </span>
        );
    }
}
