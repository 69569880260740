import React, { ReactElement, useLayoutEffect } from 'react';
import { GridRenderCellParams, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro';
import { SpotCheckbox } from '@enterprise/spot';
import styles from './selectorCell.module.scss';
import classNames from 'classnames';

export const SelectorCell = (props: GridRenderCellParams): ReactElement => {
    const { field, id, value, tabIndex } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const element = apiRef.current.getCellElement(id, field);
    const isSelectable = !rootProps?.isRowSelectable || rootProps?.isRowSelectable(apiRef.current.getRowParams(id));
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        apiRef.current.selectRow(id, event.target.checked);
    };

    useLayoutEffect(() => {
        if (tabIndex === 0 && element) {
            element.tabIndex = -1;
        }
    }, [element, tabIndex]);

    return (
        <div className={classNames({ [styles.hidden]: !isSelectable })}>
            <SpotCheckbox
                tabIndex={tabIndex}
                className={styles.container}
                checked={Boolean(value)}
                onChange={handleChange}
                disabled={!isSelectable}
                data-automation-id="data-grid-selector-cell"
            />
        </div>
    );
};
