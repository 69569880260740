import * as React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { RouterStore } from 'mobx-react-router';
import { SpotLoading } from '@enterprise/spot';
import { Form } from '@enterprise/common';
import { RootStore } from '../../../store/RootStore';
import { inject, observer } from 'mobx-react';
import OrganizationsPageStore from '../OrganizationsPageStore';
import { OrganizationEntity } from '../../../core/entity/OrganizationEntity';
import { FlyOver } from '../../../components/flyover/FlyOver';
import UsersPageStore from '../../users/UsersPageStore';
import { OrganizationalConstants } from '../../../constants';
import { OrganizationFormType } from '../formType/OrganizationFormType';
import arrayMutators from 'final-form-arrays';
import { FeaturesCheckboxes } from '../../../components/formControls/FeaturesCheckboxes';
import { Notifications } from '../../Notifications';
import i18n from '../../../i18n';
import { DeleteWarningModal } from '../../../components/modals/DeleteWarningModal';
import AppStore from '../../../store/AppStore';
import RoleConstants from '../../../constants/RoleConstants';
import { Trans } from 'react-i18next';
import { AccountEntity } from '../../../core/entity/AccountEntity';
import { FormApi } from 'final-form';
import { SiteEntity } from '../../../core/entity/SiteEntity';
import { PricingTierFormControl, OrganizationFormControl, AccountsOrgAndPracticesFormControl } from '../formControls';
import { SystemOwnerFormControl } from '../formControls/systemOwnerFormControl/SystemOwnerFormControl';

interface OrganizationsEditPageProps {
    page: OrganizationsPageStore;
    usersPage: UsersPageStore;
    routing: RouterStore;
    app: AppStore;
    match: any;
}

interface OrganizationsEditPageState {
    userList?: any;
    loading?: boolean;
    showDeleteModal: boolean;
    updatedOrg?: OrganizationEntity;
}

@inject((allStores: RootStore) => ({
    page: allStores.pages.organizationsPage,
    usersPage: allStores.pages.usersPage,
    routing: allStores.routing,
    app: allStores.app,
}))
@observer
export class OrganizationsEditPage extends React.Component<OrganizationsEditPageProps, OrganizationsEditPageState> {
    state: OrganizationsEditPageState = {
        userList: undefined,
        showDeleteModal: false,
        updatedOrg: undefined,
    };
    form = new OrganizationFormType();

    CONSTANTS = OrganizationalConstants.default;

    save = async ({ features = [], ...data }: OrganizationEntity) => {
        const originalOrg = { ...(this.organization as OrganizationEntity) };
        const updatedOrg = { ...data };
        const linkAccountIds = updatedOrg.accounts?.filter(({ id }) => !originalOrg.accounts?.find(existingAccount => existingAccount.id === id));
        const unlinkAccountIds = originalOrg.accounts?.filter(({ id }) => !updatedOrg.accounts?.find(existingAccount => existingAccount.id === id));

        this.saveOrg(updatedOrg, linkAccountIds, unlinkAccountIds)
            .then(org => this.store.saveFeatures(org.id, features))
            .then(() => {
                this.cancel();
            })
            .catch(err => {
                let message = err;
                if (/\(name\)/g.test(message)) {
                    message = i18n.t('validation:fieldValueExists', {
                        field: i18n.t('common:orgName'),
                        value: data.name,
                    });
                }
                Notifications.fromException(message);
            });
    };

    private async saveOrg(org: OrganizationEntity, linkAccounts?: AccountEntity[], unlinkAccounts?: AccountEntity[]) {
        if (!org.id) {
            const { id } = await this.store.save(org);
            org.id = id;
        }

        if (linkAccounts?.length) {
            const linkAccountIds = linkAccounts.map(({ id }) => id);
            const { all_sites } = await this.store.linkAccounts(org.id, linkAccountIds);
            org.all_sites = this.updateOrgSites(all_sites, org.all_sites);
        }

        if (unlinkAccounts?.length) {
            const unlinkAccountIds = unlinkAccounts.map(({ id }) => id);
            const { all_sites } = await this.store.unlinkAccounts(org.id, unlinkAccountIds);
            org.all_sites = this.updateOrgSites(all_sites, org.all_sites);
        }

        return this.store.save(org).then(res => {
            Notifications.success(i18n.t('common:saveOrganizationSuccess'));
            return res;
        });
    }

    private updateOrgSites(target: SiteEntity[], source: SiteEntity[]) {
        target.forEach(targetSite => {
            const sourceSite = source.find(({ siteId, id }) => String(targetSite.siteId) === String(siteId || id));
            if (!sourceSite) {
                return;
            }

            targetSite.isEnabled = sourceSite.isEnabled;
            targetSite.idexxPracticeName = sourceSite.idexxPracticeName;
            targetSite.idexxSapId = sourceSite.idexxSapId;
            targetSite.idexxZip = sourceSite.idexxZip;
        });

        return target;
    }

    cancel = () => {
        this.props.routing.history.push(`/${this.CONSTANTS.ROUTES.ORG}`);
    };

    get store(): OrganizationsPageStore {
        return this.props.page;
    }

    get organization(): OrganizationEntity | undefined {
        return this.store.organization;
    }

    get isNewOrganization(): boolean {
        return this.props.match.params.id === 'new';
    }

    getUsersForOrg() {
        const orgId = this.props.match.params.id;
        this.props.usersPage.getUsers().then(res => {
            const users = res.map(user => user.organization.obj);

            const userList = users.filter(user => user.organization && user.organization.id === orgId);
            this.setState({ userList });
        });
    }

    getUserListElement = userList => {
        return userList && userList.length > 0
            ? userList.map((user, index) => {
                  return (
                      <Row key={index}>
                          <Col xs={4}>
                              {user.profile.firstName} {user.profile.lastName}
                          </Col>
                          <Col>{user.username}</Col>
                      </Row>
                  );
              })
            : i18n.t('common:noUsers');
    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext): void {
        if (!this.state.userList) {
            this.getUsersForOrg();
        }
    }

    loadData() {
        this.setState({ loading: true });
        this.store.fetchOrganizations().then(async () => {
            if (this.isNewOrganization) {
                await Promise.all([this.store.fetchAccounts(), this.store.fetchPricingTiers()]);
            } else {
                await this.store.fetchOrganization(this.props.match.params.id);
            }
            this.setState({ loading: false });
        });
    }

    componentDidMount() {
        if (this.isNewOrganization) {
            this.store.createOrganization();
        }
        this.loadData();
    }

    componentWillUnmount() {
        this.store.dispose();
    }

    render() {
        const { organization, CONSTANTS } = this;
        const { features, pricingTiers } = this.store;
        const { userList } = this.state;
        const flyoverPrefix = this.isNewOrganization ? CONSTANTS.TITLE.NEW : CONSTANTS.TITLE.EDIT;
        const initData = { ...organization, features };
        const deleteModalProps = {
            title: i18n.t('common:deleteOrgTitle'),
            message: i18n.t('common:deleteOrgWarning'),
            onCancel: () => {
                this.setState({ showDeleteModal: false });
            },
            onDelete: this.delete,
            isVisible: this.state.showDeleteModal,
        };

        return (
            <div>
                {!organization && <SpotLoading />}
                {organization && (
                    <FlyOver
                        title={`${flyoverPrefix} ${CONSTANTS.TITLE.ORG}`}
                        cancelAction={this.cancel}
                        loading={this.state.loading}
                        body={
                            <Form
                                data={initData}
                                formType={this.form}
                                mutators={{
                                    // potentially other mutators could be merged here
                                    ...arrayMutators,
                                }}
                                onSubmit={this.save}
                                keepDirtyOnReinitialize={true}
                            >
                                {({ handleSubmit, values, form, pristine, invalid }) => {
                                    return (
                                        <div>
                                            <div>
                                                <div className="fly-over-form">
                                                    <span className={'fly-over-section-title'}>
                                                        <Trans i18nKey="common:detailsFor">Details for</Trans>:
                                                    </span>
                                                    <div className={'fly-over-group'}>
                                                        <OrganizationFormControl />
                                                    </div>

                                                    <span className={'fly-over-section-title'}>
                                                        <Trans i18nKey="common:productTier">Product Tier</Trans>:
                                                    </span>
                                                    <div className={'fly-over-group'}>
                                                        <PricingTierFormControl pricingTiers={pricingTiers} />
                                                    </div>

                                                    <span className={'fly-over-section-title'}>
                                                        <Trans i18nKey="common:features">Features</Trans>:
                                                    </span>
                                                    <div className={'fly-over-group'}>
                                                        <Row>
                                                            <Col>
                                                                <FeaturesCheckboxes name="features" />
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    {!this.isNewOrganization && (
                                                        <>
                                                            <span className={'fly-over-section-title'}>
                                                                <Trans i18nKey="common:users">Users</Trans>:
                                                            </span>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <div className={'fly-over-group'}>{this.getUserListElement(userList)}</div>
                                                                </Col>
                                                            </Row>
                                                            {!!userList.length && (
                                                                <>
                                                                    <span className={'fly-over-section-title'}>
                                                                        <Trans i18nKey="common:systemOwner">System Owner</Trans>:
                                                                    </span>
                                                                    <Row>
                                                                        <Col xs={12}>
                                                                            <div className={'fly-over-group'}>
                                                                                <SystemOwnerFormControl users={userList} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    <AccountsOrgAndPracticesWrapper form={form} store={this.store} routing={this.props.routing}/>
                                                </div>
                                            </div>
                                            <div className="fly-over-actions">
                                                <Link style={{ marginRight: '10px' }} className="spot-link" onClick={this.cancel} to={'#'}>
                                                    <Trans i18nKey="common:cancel">cancel</Trans>
                                                </Link>
                                                {!this.isNewOrganization && this.userCanDeleteOrg() && (
                                                    <React.Fragment>
                                                        <button
                                                            style={{ marginRight: '10px' }}
                                                            className="spot-button spot-button--secondary"
                                                            onClick={this.promptDeleteWarning}
                                                        >
                                                            <Trans i18nKey="common:deleteOrgButton">Remove Organization</Trans>
                                                        </button>
                                                        <DeleteWarningModal {...deleteModalProps} />
                                                    </React.Fragment>
                                                )}
                                                <button
                                                    disabled={pristine || invalid}
                                                    className="spot-button spot-button--primary"
                                                    onClick={handleSubmit}
                                                >
                                                    <Trans i18nKey="common:saveChangesButton">Save Changes</Trans>
                                                </button>
                                            </div>
                                        </div>
                                    );
                                }}
                            </Form>
                        }
                    />
                )}
            </div>
        );
    }

    private promptDeleteWarning = () => {
        this.setState({ showDeleteModal: true });
    };

    private delete = () => {
        const org = this.organization as OrganizationEntity;
        this.store.delete(org.id).then(() => {
            this.setState({ showDeleteModal: false });
            this.props.routing.history.push(`/${this.CONSTANTS.ROUTES.ORG}`);
        });
    };

    private userCanDeleteOrg = () => {
        return this.props.app.token.hasRole(RoleConstants.SUPER_ADMIN);
    };
}

class AccountsOrgAndPracticesWrapper extends React.Component<{ form: FormApi; store: OrganizationsPageStore, routing: RouterStore }, any> {
    state = { loading: false };

    render() {
        const { loading } = this.state;
        const { store, form, routing } = this.props;
        const updatedOrg = form.getState().values as OrganizationEntity;
        const otherOrganizations = store.organizations.filter(({ id }) => id !== updatedOrg.id);
        const { accounts, all_sites } = updatedOrg;
        const usedAccountIds = [...otherOrganizations, updatedOrg]
            .reduce<AccountEntity[]>((agg, val) => [...agg, ...(val.accounts || [])], [])
            .map(account => (typeof account === 'string' ? account : account.id));
        const availableAccounts = store.accounts.filter(account => !usedAccountIds.includes(account.id));
        return (
            <AccountsOrgAndPracticesFormControl
                accounts={accounts}
                availableAccounts={availableAccounts}
                sites={all_sites}
                organization={store.organization}
                onAddAccount={this.onAddAccount}
                onRemoveAccount={this.onRemoveAccount}
                onUpdatePractice={this.onUpdatePractice}
                loading={loading}
                page={store}
                routing={routing}
            />
        );
    }

    private onAddAccount = (account: AccountEntity) => {
        if (!account) {
            return;
        }

        const { store, form } = this.props;
        const { accounts, all_sites } = form.getState().values as OrganizationEntity;
        form.change('accounts', [...(accounts || []), account]);

        this.setState({ loading: true });
        store
            .fetchAccountApplications(account.id)
            .then(() => {
                const sites = [...all_sites, ...store.accountApplications.filter(({ accountId }) => accountId === account.id)];
                form.change('all_sites', sites);
                this.setState({ loading: false });
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    };

    private onRemoveAccount = (account: AccountEntity) => {
        if (!account) {
            return;
        }

        const { form } = this.props;
        const { all_sites, accounts } = form.getState().values as OrganizationEntity;
        form.change(
            'accounts',
            accounts?.filter(({ id }) => id !== account.id),
        );

        form.change(
            'all_sites',
            all_sites.filter(({ accountId }) => accountId !== account.id),
        );
    };

    private onUpdatePractice = (practice: SiteEntity) => {
        if (!practice) {
            return;
        }

        const { form } = this.props;
        const { all_sites } = form.getState().values as OrganizationEntity;
        const filtered = all_sites.filter((p, index) => {
            return p.id !== practice.id;
        });
        filtered.push(practice);
        form.change(
            'all_sites',
            filtered
        );
    };
}
