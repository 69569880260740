import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '@enterprise/common';
import { SpotFieldError, SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import i18n from '../../../../i18n';
import { UserEntity } from '../../../../core/entity/UserEntity';
import { memo } from 'react';

interface SystemOwnerFormControlProps {
    users: UserEntity[];
}

export const SystemOwnerFormControl = memo(function SystemOwnerFormControl(props: SystemOwnerFormControlProps) {
    const { users } = props;
    const sortedUsers = users.sort((a, b) => a.username.localeCompare(b.username));
    return (
        <Row>
            <Col xs={8}>
                <FormField
                    name="owner"
                    parse={(id: string) => (id || null) && users.find(user => String(user.id) === id)}
                    format={value => value && value.id}
                >
                    {({ input, meta }) => (
                        <>
                            <SpotSelectBox
                                {...input}
                                placeholder={`- ${i18n.t('common:noSystemOwner', 'No System Owner')} -`}
                                data-automation-id={'systemOwner'}
                            >
                                {sortedUsers.map(user => (
                                    <SpotSelectOption key={user.id} value={user.id}>
                                        {user.username}
                                    </SpotSelectOption>
                                ))}
                            </SpotSelectBox>
                            <SpotFieldError meta={meta} />
                        </>
                    )}
                </FormField>
            </Col>
        </Row>
    );
});
