import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RouterStore } from 'mobx-react-router';
import AppStore from '../../store/AppStore';
import RoleConstants from '../../constants/RoleConstants';
import { BaseUserToken } from '@enterprise/common';
import { UNAUTHORIZED_PAGE_PATH } from './UnauthorizedPage';

interface ILoginProps {
    app: AppStore;
    routing: RouterStore;
}

interface ILoginState {
    checkAuthInterval: any;
}

const postAuthPaths = [
    { role: RoleConstants.SUPER_ADMIN, path: '/home' },
    { role: RoleConstants.ONBOARDING_ADMIN, path: '/onboarding/communicator' },
];

@observer
class LoginPage extends React.Component<ILoginProps, ILoginState> {
    iframeRef;
    loginUrl;

    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
        this.loginUrl = this.props.app.getSSOLoginURL();
        this.state = {
            checkAuthInterval: null,
        };
    }

    checkAuthState = () => {
        return this.props.app.authClient.isAuthenticated();
    };

    async componentDidMount() {
        this.props.app.toggleAuthStatus(false);
        const authState = await this.checkAuthState();
        if (authState && this.props.app.token?.isAuthenticated()) {
            const path = this.getPostAuthPath(this.props.app.token);
            this.props.routing.push(path);
        } else {
            const checkAuthInterval = setInterval(this.getAuthSession, 500);
            this.setState({ checkAuthInterval });
        }
        this.props.app.authClient.authHandler = this.authCallback;
    }

    componentWillUnmount = () => {
        clearInterval(this.state.checkAuthInterval);
    };

    getPostAuthPath(token: BaseUserToken): string {
        if (!token?.hasRole) {
            return UNAUTHORIZED_PAGE_PATH;
        }
        const match = postAuthPaths.find(({ role }) => token?.hasRole(role));
        return match?.path || UNAUTHORIZED_PAGE_PATH;
    }

    getAuthSession = () => {
        const node = this.iframeRef && this.iframeRef.current;
        if (document.hasFocus() && node && !(this.props.app.token && this.props.app.token.isAuthenticated())) {
            node.contentWindow && node.contentWindow.postMessage('getAuthSession', this.loginUrl);
        }
    };

    authCallback = async () => {
        if (await this.props.app.authClient.isAuthenticated()) {
            const idToken = await this.props.app.authClient.getIdToken();
            this.props.app.authorize(idToken.getJwtToken()).then(token => {
                if (token === undefined) {
                    return;
                }
                if (token instanceof Error) {
                    this.iframeRef.current.src += '';
                    return;
                }
                clearInterval(this.state.checkAuthInterval);
                const path = this.getPostAuthPath(token);
                if (path === UNAUTHORIZED_PAGE_PATH) {
                    this.props.app.logout(undefined);
                    this.props.routing.push(path);
                } else {
                    this.props.routing.go(path);
                }
            });
        }
    };

    render() {
        return (
            <Row className="vertical-align" style={{ minHeight: '100vh' }}>
                <Col md="12">
                    <iframe ref={this.iframeRef} src={this.loginUrl} className={'login-iframe'} />
                </Col>
            </Row>
        );
    }
}

export default inject('app', 'routing')(LoginPage);
