import { FormField } from '@enterprise/common';
import { SpotFieldError, SpotTextInput } from '@enterprise/spot';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function TextFormRow({ label, name }) {
    return (
        <Row>
            <Col className={'label-text'}>{label}:</Col>
            <Col>
                <FormField name={name}>
                    {({ input, meta }) => (
                        <div className="spot-form__field-group">
                            <SpotTextInput {...input} name={input.name} className="spot-form__input" type="text" />
                            <SpotFieldError meta={meta} />
                        </div>
                    )}
                </FormField>
            </Col>
        </Row>
    );
}
