import { IsAlphanumeric, IsIn, IsNotEmpty } from 'class-validator';
import { OrganizationEntity } from './OrganizationEntity';
import { Transform } from 'class-transformer';
import i18n from '../../i18n';

export enum AccountSource {
    Animana = 'animana',
    DataPoint = 'datapoint-2020',
    Neo = 'neo',
}

export class AccountEntity {
    id: string;

    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('common:pages.dataSources.name') }),
    })
    @IsAlphanumeric('en-US', {
        message: () => i18n.t('validation:isAlphanumeric', { field: i18n.t('common:pages.dataSources.name') }),
    })
    name: string;

    @Transform(({ value }) => value?.toLowerCase())
    @IsIn(Object.values(AccountSource), {
        message: () => i18n.t('validation:isRequired', { field: i18n.t('common:pages.dataSources.connectionType') }),
    })
    source: string;

    organization?: OrganizationEntity;
}
