import React, { useEffect, useState } from 'react';
import { FlyOver } from '../../../../components/flyover/FlyOver';
import { useHistory } from 'react-router-dom';
import useStore from '../../../../hooks/useStore';
import { CommunicatorPageStore } from '../CommunicatorPageStore';
import { PAGE_STORES } from '../../../stores';
import { SpotSvg } from '@enterprise/spot';
import UserTable from './UserTable';
import TaskCard from './TaskCard/TaskCard';
import { CommunicatorPracticeOnboardingDetailsEntity } from '../../../../core/onboarding/Communicator/entity/CommunicatorPracticeOnboardingDetailsEntity';

export default function CommunicatorPracticeDetailsPage(props) {
    const history = useHistory();
    const store = useStore<CommunicatorPageStore>(PAGE_STORES.CommunicatorPageStore);
    const practiceId = props.match.params.practiceId;
    const sapId = props.match.params.sapId;
    const [practiceDetails, setPracticeDetails] = useState<CommunicatorPracticeOnboardingDetailsEntity | undefined>();
    const [practiceUsers, setPracticeUsers] = useState();
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        setPracticeDetails(undefined);
        setPracticeUsers(undefined);
        store.getPracticeOnboardingDetails(sapId).then(data => {
            // User doesn't care about seeing the site search task
            data.workflowTasks = data.workflowTasks.filter(task => {
                return task.implementation !== 'CommunicatorSiteSearchTask';
            });
            setPracticeDetails(data);
        });
        store.getPracticeUsers(practiceId).then(data => {
            setPracticeUsers(data);
        });
    }, [sapId]);

    function closeFlyOver() {
        history.push('/onboarding/communicator');
    }

    if (!practiceDetails || !practiceUsers) {
        return null;
    }
    // Older practices don't have tasks
    const workflowHasTasks = practiceDetails.workflowTasks.length > 0;
    // Note: True when there are no tasks
    const allTasksComplete = practiceDetails.workflowTasks.every(task => task.onCompleteResult !== null);
    const taskCardsStyles = allTasksComplete ? { paddingLeft: 28 } : {};
    const detailsSvgName = showDetails ? 'caret-down' : 'next';
    const shouldShowTasks = (allTasksComplete && showDetails) || !allTasksComplete;
    return (
        <FlyOver
            title={`Manage Practice`}
            cancelAction={closeFlyOver}
            style={{ backgroundColor: 'white' }}
            body={
                <div className={'communicator-practice-details-page'}>
                    <h2 className={'header'}>Onboarding</h2>
                    {allTasksComplete && (
                        <div>
                            <div>
                                <SpotSvg icon={'checkmark'} className={`header-svg`} />
                                <span style={{ fontWeight: 700 }}>Onboarding is Complete</span>
                            </div>
                            {workflowHasTasks && (
                                <div className={'details-container'}>
                                    <a
                                        className="spot-link"
                                        href="javascript:void(0)"
                                        onClick={() => {
                                            setShowDetails(!showDetails);
                                        }}
                                    >
                                        <span style={{ paddingLeft: 28, marginRight: 3 }}>Details</span>
                                        <SpotSvg icon={detailsSvgName} className={'details-svg'} />
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                    {workflowHasTasks && shouldShowTasks && (
                        <div className={'task-cards'} style={taskCardsStyles}>
                            {practiceDetails.workflowTasks.map(task => {
                                return <TaskCard task={task} key={task.id} />;
                            })}
                        </div>
                    )}
                    <div style={{ marginTop: 28 }}>
                        <UserTable {...{ showAddUserButton: true, users: practiceUsers, practiceId: practiceId }} />
                    </div>
                </div>
            }
        />
    );
}
