import { Service } from 'typedi';
import { action, observable } from 'mobx';
import { Notifications } from '../Notifications';
import {FeatureEntity, FeatureService} from '../../services/FeatureService';

@Service()
export default class GlobalSettingsPageStore {
    @observable isLoaded: boolean = false;

    @observable features: FeatureEntity[] = [];

    constructor(private featureService: FeatureService) {}

    load() {
        this.getFeatures().then(() => {
            this.isLoaded = true;
        });
    }

    @action async saveFeatures(features: FeatureEntity[]){
        return this.featureService.setGlobalFeatures(features);
    }

    @action
    async getFeatures() {
        try {
            this.features = await this.featureService.getGlobalFeatures();
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        return this.features;
    }

    @action dispose() {
        this.features = [];
    }
}
