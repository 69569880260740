import { SpotButton } from '@enterprise/spot';
import useStore from '../../../../hooks/useStore';
import { CommunicatorPageStore } from '../CommunicatorPageStore';
import { PAGE_STORES } from '../../../stores';
import i18n from '../../../../i18n';
import UserEditCreateModal from './UserEditCreateModal/UserEditCreateModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import SpotTable from '../../../../Spot/Table/SpotTable';
import { LoadingModal } from './LoadingModal';
import { Notifications } from '../../../Notifications';

require('./UserTable.scss');

UserTable.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            idxId: PropTypes.string,
            friendlyName: PropTypes.string,
            created: PropTypes.string,
            practiceName: PropTypes.string,
            username: PropTypes.string,
        }),
    ),
    practiceId: PropTypes.string,
    showAddUserButton: PropTypes.bool,
};

export default function UserTable(props) {
    const [modalToRender, setModalToRender] = useState<JSX.Element | null>(null);
    const [users, setUsers] = useState<any>(props.users);
    const [searchValue, setSearchValue] = useState('');
    const store = useStore<CommunicatorPageStore>(PAGE_STORES.CommunicatorPageStore);

    async function refreshUsersList() {
        const temp = await store.getPracticeUsers(props.practiceId);
        if (temp) {
            setUsers(temp);
        }
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'friendlyName',
                Header: i18n.t('common:pages.onboarding.displayName', 'Display Name'),
                Cell: nameFormatter,
            },
            {
                accessor: 'username',
                Header: i18n.t('common:pages.onboarding.userName', 'User Name'),
                Cell: nameFormatter,
            },
            {
                accessor: 'created',
                Header: i18n.t('common:pages.onboarding.createdDate', 'Created Date'),
                Cell: dateFormatter,
            },
        ],
        [],
    );

    const editColumnAddition = useCallback(columns => {
        return [
            ...columns,
            {
                id: 'edit',
                Header: () => null,
                // eslint-disable-next-line react/display-name
                Cell: editFormatter,
            },
        ];
    }, []);

    // eslint-disable-next-line react/prop-types
    function nameFormatter({ cell }) {
        // eslint-disable-next-line react/prop-types
        return <div className={'word-break-overflow'}>{cell.value}</div>;
    }

    function dateFormatter({ cell }) {
        return moment(cell.value).format('MM/DD/YYYY');
    }

    function editFormatter() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a className="spot-link" style={{ color: '#00a7b5' }}>
                    {'Edit'}
                </a>
            </div>
        );
    }

    const onClickRow = async (e, row) => {
        setModalToRender(<LoadingModal />);
        const user = await store
            .getPracticeUser(row.original.idxId)
            .catch(() => Notifications.error(i18n.t('common:pages.onboarding.userSelectError', 'There was a problem retrieving the user.')));
        if (user) {
            const ssoInfo = user.ssoInfo;
            setModalToRender(
                <UserEditCreateModal
                    idxId={row.original.idxId}
                    username={row.original.username}
                    displayName={row.original.friendlyName}
                    email={ssoInfo?.email}
                    phone={ssoInfo?.phone_number && ssoInfo.phone_number.substring(2)}
                    role={row.original.role}
                    closeModal={closeModal}
                    isNewUser={false}
                    practiceId={props.practiceId}
                    cognitoStatus={ssoInfo?.cognito_status}
                />,
            );
        }
    };

    const handleClickNewUser = () => {
        setModalToRender(
            <UserEditCreateModal
                idxId={''}
                username={''}
                displayName={''}
                email={''}
                phone={''}
                role={''}
                closeModal={closeModal}
                isNewUser={true}
                practiceId={props.practiceId}
            />,
        );
    };

    async function closeModal() {
        setModalToRender(null);
        await refreshUsersList();
    }

    return (
        <>
            {modalToRender}
            <div className={'user-table'}>
                <div className="search-bar-container">
                    <div>
                        <h2>{i18n.t('common:pages.onboarding.users', 'Users')}</h2>
                    </div>
                    <div>
                        <label className="search-label">
                            <input
                                id="search-bar-0"
                                defaultValue={searchValue}
                                onChange={e => {
                                    setSearchValue(e.target.value);
                                }}
                                className="form-control"
                                placeholder="Search"
                            />
                        </label>
                        {props.showAddUserButton && (
                            <SpotButton onClick={handleClickNewUser} className={'add-user-button'} id={'new-user-button'}>
                                {i18n.t('common:pages.onboarding.addUser', 'Add User')}
                            </SpotButton>
                        )}
                    </div>
                </div>
                <SpotTable
                    columns={columns}
                    data={users}
                    visibleColumnsHandler={editColumnAddition}
                    filterValue={searchValue}
                    onRowClick={onClickRow}
                    rowKey={'idxId'}
                />
            </div>
        </>
    );
}
