import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import * as React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { RootStore } from '../../store/RootStore';
import { Cmp } from '@enterprise/common';
import { SpotLoading } from '@enterprise/spot';
import GlobalSettingsPageStore from './GlobalSettingsPageStore';
import { Notifications } from '../Notifications';
import { GlobalFeaturesFormType } from './formType/GlobalFeaturesFormType';
import { Form } from '@enterprise/common';
import { FeaturesCheckboxes } from '../../components/formControls/FeaturesCheckboxes';
import arrayMutators from 'final-form-arrays';
import { environment } from '../../environment/environment';

// import { Route, Link } from 'react-router-dom';

interface GlobalSettingsPageProps {
    globalSettingsPage: GlobalSettingsPageStore;
    routing: RouterStore;
    match: any;
}

interface GlobalSettingsPageState {}

@inject((allStores: RootStore) => ({
    globalSettingsPage: allStores.pages.globalSettingsPage,
    routing: allStores.routing,
}))
@observer
export default class GlobalSettingsPage extends React.Component<GlobalSettingsPageProps, GlobalSettingsPageState> {
    state: GlobalSettingsPageState = {};

    private form = new GlobalFeaturesFormType();

    private unsubscribe: any;

    componentDidMount() {
        this.unsubscribe = (this.props.routing as RouterStore).history.subscribe(() => {
            this.props.globalSettingsPage.load();
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
        this.props.globalSettingsPage.dispose();
    }

    get store(): GlobalSettingsPageStore {
        return this.props.globalSettingsPage;
    }

    saveFeatures = data => {
        this.store.saveFeatures(data).then(result => {
            Notifications.success(`Successfully saved users features`);
            return result;
        });
    };

    render() {
        const { isLoaded, features } = this.store;
        return (
            <Container fluid={true}>
                <Row>
                    <Col md="12">
                        <Cmp.If cond={!isLoaded}>
                            <SpotLoading text="Loading..." />
                        </Cmp.If>
                        <Cmp.If cond={isLoaded}>
                            <div className="settings-page">
                                <h1>Global Settings</h1>
                                <div className="list">
                                    <Row>
                                        <Col xs={12}>
                                            <Form
                                                data={features}
                                                formType={this.form}
                                                mutators={{
                                                    // potentially other mutators could be merged here
                                                    ...arrayMutators,
                                                }}
                                                onSubmit={this.saveFeatures}
                                            >
                                                {({ handleSubmit }) => (
                                                    <>
                                                        <span className={'fly-over-section-title'}>Features:</span>
                                                        <div className={'fly-over-group'}>
                                                            <FeaturesCheckboxes name="features" extend={environment.globalFeatures} />
                                                        </div>
                                                        <button className="spot-button spot-button--primary" onClick={handleSubmit}>
                                                            Save
                                                        </button>
                                                    </>
                                                )}
                                            </Form>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Cmp.If>
                    </Col>
                </Row>
            </Container>
        );
    }
}
