import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/ru'; // As new languages are added they must be imported for moment.js

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        fallbackLng: 'en',
        ns: ['common', 'validation'], // New namespaces need to be added here so they will be loaded
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
        },
        detection: {
            options: ['localStorage', 'cookie', 'navigator'],
            lookupLocalStorage: 'i18nlocale',
        },
    });

i18n.on('languageChanged', lng => {
    moment.locale(lng);
});

export default i18n;
