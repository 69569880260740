import { GridColDef, DataGridProProps } from '@mui/x-data-grid-pro';

export const defaultColumnDef: Partial<GridColDef> = {
    disableColumnMenu: true,
};

export const defaultDataGridProps: Partial<DataGridProProps> = {
    columnBuffer: 10,
    disableMultipleColumnsSorting: true
};
