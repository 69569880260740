import { IsNumberString, IsPhoneNumber, Matches, IsEmail, IsIn, IsNotEmpty, Length, IsOptional } from 'class-validator';
import OnboardingConstants from '../../../../constants/OnboardingConstants';

export class CommunicatorCreateEditUserEntity {
    @IsNotEmpty()
    @IsEmail({}, { message: 'Username must be a valid email address' })
    username: string;

    @IsNotEmpty({ message: 'Display Name is required' })
    @Length(0, OnboardingConstants.DISPLAY_NAME_MAX_LENGTH, {
        message: `Display Name must be less than ${OnboardingConstants.DISPLAY_NAME_MAX_LENGTH} characters.`,
    })
    userDisplayName: string;

    @IsNotEmpty({ message: 'Email is required' })
    @IsEmail({}, { message: 'Email must be a valid email address' })
    userEmail: string;

    userRole: string;
}

export class CommunicatorCreateEditUserEntityNoUsername {
    @IsNotEmpty({ message: 'Display Name is required' })
    @Length(0, OnboardingConstants.DISPLAY_NAME_MAX_LENGTH, {
        message: `Display Name must be less than ${OnboardingConstants.DISPLAY_NAME_MAX_LENGTH} characters.`,
    })
    userDisplayName: string;

    @IsNotEmpty({ message: 'Email is required' })
    @IsEmail({}, { message: 'Email must be a valid email address' })
    @Length(0, OnboardingConstants.EMAIL_MAX_LENGTH, {
        message: `Email must be less than ${OnboardingConstants.EMAIL_MAX_LENGTH} characters.`,
    })
    userEmail: string;

    userRole: string;
}
