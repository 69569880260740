import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormField } from '@enterprise/common';
import { SpotFieldError, SpotEditableText, SpotLoading, SpotSvg, SpotToggle } from '@enterprise/spot';
import { AccountEntity } from '../../../../core/entity/AccountEntity';
import { OrganizationEntity } from '../../../../core/entity/OrganizationEntity';
import { SiteEntity } from '../../../../core/entity/SiteEntity';
import i18n from '../../../../i18n';
import { removeWhitespaceOnBlur } from '../../../../core/util/Forms';
import { Trans } from 'react-i18next';
import styles from './AccountsOrgAndPracticesFormControl.module.scss';
import classNames from 'classnames';
import { OrganizationalConstants } from '../../../../constants';
import { FlyOver } from '../../../../components/flyover/FlyOver';
import { PracticeEditPage } from '../../edit/PracticeEditPage/PracticeEditPage'
import OrganizationsPageStore from '../../OrganizationsPageStore';
import { RouterStore } from 'mobx-react-router';

export interface DatapointOrgAndPracticesFormControlProps {
    loading?: boolean;
    accounts?: AccountEntity[];
    availableAccounts: AccountEntity[];
    organization?: OrganizationEntity;
    sites?: SiteEntity[];
    onAddAccount?: (account: AccountEntity) => void;
    onRemoveAccount?: (account: AccountEntity) => void;
    onUpdatePractice?: (practice: SiteEntity) => void;
    page: OrganizationsPageStore;
    routing: RouterStore;
}

export class AccountsOrgAndPracticesFormControl extends React.PureComponent<DatapointOrgAndPracticesFormControlProps> {
    state = {
        showSelectAccount: false,
        showEditPractice: false,
        selectedSite: new SiteEntity
    };

    CONSTANTS = OrganizationalConstants.default;

    showSelectAccount = () => {
        this.setState({ showSelectAccount: true });
    };

    hideSelectAccount = () => {
        this.setState({ showSelectAccount: false });
    };

    showEditPractice = (selectedSite: SiteEntity) => {
        this.setState({ showEditPractice: true, selectedSite: selectedSite });
    }

    hideEditPractice = async (practice: SiteEntity) => {
        this.setState({showEditPractice: false});
        if(this.props.onUpdatePractice) {
            this.props.onUpdatePractice(practice);
        }
        this.props.routing.history.push(`/${this.CONSTANTS.ROUTES.ORG}${this.props?.organization?.id ? '/' + this.props.organization.id : ''}`);
    }


    render() {
        const { availableAccounts, loading, accounts, sites, onRemoveAccount } = this.props;
        if (loading && !sites) {
            return <SpotLoading />;
        }
        const nullSitesAvailable = sites?.filter(({ accountId }) => accountId === null).length !== 0;
        if (!availableAccounts.length && !accounts?.length && !nullSitesAvailable) {
            return (
                <span>
                    <Trans i18nKey="common:noGroupsAvailable">No groups available</Trans>
                </span>
            );
        }
        return (
            <>
                <Row>
                    <Col>
                        <span className="fly-over-section-title">
                            <Trans i18nKey="common:accountsAndPractices">Accounts and Practices</Trans>:
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="fly-over-group">{this.renderAddAccount()}</div>
                    </Col>
                </Row>

                {this.renderAccountPractices(accounts, sites, onRemoveAccount, loading, nullSitesAvailable)}
            </>
        );
    }

    private renderAddAccount() {
        const { loading, availableAccounts, onAddAccount } = this.props;
        const { showSelectAccount } = this.state;
        const onSelectAccount = (accountId: string) => {
            const account = availableAccounts.find(({ id }) => String(id) === String(accountId));

            if (!onAddAccount || !account) {
                return;
            }

            onAddAccount(account);
            this.hideSelectAccount();
        };

        if (!availableAccounts.length) {
            return (
                <Row>
                    <Col>
                        <div className={styles.addAccount}>
                            <Trans i18nKey="common:noAvailableAccounts">No available accounts to add</Trans>
                        </div>
                    </Col>
                </Row>
            );
        }

        return (
            <>
                {!showSelectAccount && (
                    <Row>
                        <Col>
                            <a href={'javascript:void(0)'} className={classNames(styles.addAccount, 'spot-link')} onClick={this.showSelectAccount}>
                                <SpotSvg icon={'add'} className="spot-link__icon spot-link__icon--left" />
                                <Trans i18nKey="common:addAccount">Add Account</Trans>
                            </a>
                        </Col>
                    </Row>
                )}
                {showSelectAccount && (
                    <Row>
                        <Col xs={5}>
                            <div className="spot-form__select">
                                <select className="spot-form__select-input" disabled={loading} onChange={e => onSelectAccount(e.target.value)}>
                                    <option className="spot-form__select-option" value={''}>
                                        {i18n.t('common:selectGroup')}
                                    </option>
                                    {availableAccounts.map(({ id, name }) => (
                                        <option className="spot-form__select-option" value={`${id}`} key={id}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                                <div className="spot-form__select-inner"></div>
                                <span className="spot-form__select-open-indicator">
                                    <SpotSvg className="spot-icon spot-form__select-open-icon" icon="caret-down" />
                                </span>
                            </div>
                        </Col>
                    </Row>
                )}
            </>
        );
    }

    private renderAccountPractices(
        accounts?: AccountEntity[],
        sites?: SiteEntity[],
        onRemoveAccount?: (account: AccountEntity) => void,
        loading: boolean = false,
        nullSitesAvailable: boolean = false,
    ) {
        if (loading) {
            return <SpotLoading />;
        }

        if (!accounts && nullSitesAvailable) {
            return null;
        }

        const tempAccounts: AccountEntity[] = [];
        accounts?.map(account => tempAccounts.push(account));

        tempAccounts.push({ name: OrganizationalConstants.default.PRACTICE_CONNECT, id: 'temp_pc_id', source: '' });
        return (
            <>
                <FormField name={`accounts`}>
                    {() =>
                        tempAccounts.map(account => {
                            const accountSites =
                                account.name === OrganizationalConstants.default.PRACTICE_CONNECT
                                    ? sites?.filter(({ accountId }) => accountId === null)
                                    : sites?.filter(({ accountId }) => accountId === account.id);

                            return (
                                <Row key={account.id}>
                                    <Col>
                                        <Row>
                                            <Col className={styles.accountInfo}>
                                                <span className={'spot-typography__heading--level-5'}>{account.name}</span>

                                                <button
                                                    className={classNames('spot-button spot-button--link spot-button--with-icon')}
                                                    onClick={() => onRemoveAccount && onRemoveAccount(account)}
                                                >
                                                    <span className="spot-button__text">
                                                        <Trans i18nKey="common:remove">Remove</Trans>
                                                    </span>
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="fly-over-group">
                                                    {accountSites && accountSites.length ? (
                                                        accountSites.map(site => {
                                                            const siteIndex = sites?.indexOf(site);
                                                            return (
                                                                <Row className="fly-over-list-item" key={site.id}>
                                                                    <Col>{this.renderPractice(site, siteIndex)}</Col>
                                                                </Row>
                                                            );
                                                        })
                                                    ) : (
                                                        <Row className="fly-over-list-item">
                                                            <Col>
                                                                <span>
                                                                    <Trans i18nKey="common:noConnectedPractices">No connected Practices</Trans>
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </FormField>
            </>
        );
    }

    private renderPractice(site: SiteEntity, siteIndex?: number) {
        return (
            <>
                <Row>
                    <div className={styles.editPracticeLink}>
                        <SpotSvg icon="edit" className={styles.editIconOrg}/>
                        <button
                            className={`${classNames('spot-button spot-button--link spot-button--with-icon')} ${styles.editButtonOrg}`}
                            onClick={() => {
                                this.showEditPractice(site)
                            }}
                        >
                            <span className="spot-button__text">
                                <Trans
                                    i18nKey="common:editThisPractice">Edit This Practice</Trans>
                            </span>
                        </button>
                    </div>
                </Row>
                <Row>
                    <Col md={5}>
                        <FormField format={removeWhitespaceOnBlur} formatOnBlur={true}
                                   name={`all_sites[${siteIndex}].idexxPracticeName`}>
                            {({input, meta}) => (
                                <div className="fly-over-editable-container">
                                    <div className="spot-form__field-group" style={{fontSize: '1.2em'}}>
                                        <SpotEditableText
                                            label={
                                                input.value && input.value.toString().length > 0
                                                    ? input.value
                                                    : `[${i18n.t('common:idexxPracticeName').toUpperCase()}]`
                                            }
                                        >
                                            <input {...input} name={input.name} className="spot-form__input"
                                                   type="text"/>
                                        </SpotEditableText>
                                        <SpotFieldError meta={meta}/>
                                    </div>
                                </div>
                            )}
                        </FormField>
                    </Col>
                    <Col className={'text-right'} md={5}>
                        {site.name}
                    </Col>
                    <Col md={2}>
                        <FormField type={'checkbox'} name={`all_sites[${siteIndex}].isEnabled`}>
                            {({input, meta}) => {
                                const onChange = e => {
                                    input.onChange(e);
                                };
                                return (
                                    <div className="fly-over-editable-container">
                                        <div className="spot-form__field-group" style={{fontSize: '1.2em'}}>
                                            <SpotToggle checked={input.value} onChange={onChange}/>
                                            <SpotFieldError meta={meta}/>
                                        </div>
                                    </div>
                                );
                            }}
                        </FormField>
                    </Col>
                </Row>

                {!site.isActive && site.isEnabled && (
                    <Row>
                        <Col md={10}>
                            <span>
                                <SpotSvg className={'fly-over-alert-notification'} icon={'alert-notification'}/>
                                {i18n.t('common:siteIsInactive', 'This practice is set inactive by the client')}
                            </span>
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col md={5}>
                        <span className={'label-text--label'}>{i18n.t('common:idexxSapId')}:</span>
                        <FormField format={removeWhitespaceOnBlur} formatOnBlur={true}
                                   name={`all_sites[${siteIndex}].idexxSapId`}>
                            {({input, meta}) => (
                                <div className="fly-over-editable-container">
                                    <div className="spot-form__field-group">
                                        <SpotEditableText
                                            label={input.value && input.value.toString().length > 0 ? input.value : '-'}>
                                            <input {...input} name={input.name} className="spot-form__input"
                                                   type="text"/>
                                        </SpotEditableText>
                                        <SpotFieldError meta={meta}/>
                                    </div>
                                </div>
                            )}
                        </FormField>
                    </Col>
                    <Col className={'text-right'} md={5}>
                        <span className={'label-text--label'}>{i18n.t('common:datapointSapId')}:</span>
                        {site.sapId || '-'}
                    </Col>
                </Row>

                <Row>
                    <Col md={5}>
                        <span className={'label-text--label'}>{i18n.t('common:idexxZip')}:</span>
                        <FormField format={removeWhitespaceOnBlur} formatOnBlur={true}
                                   name={`all_sites[${siteIndex}].idexxZip`}>
                            {({input, meta}) => (
                                <div className="fly-over-editable-container">
                                    <div className="spot-form__field-group">
                                        <SpotEditableText
                                            label={input.value && input.value.toString().length > 0 ? input.value : '-'}>
                                            <input {...input} name={input.name} className="spot-form__input"
                                                   type="text"/>
                                        </SpotEditableText>
                                        <SpotFieldError meta={meta}/>
                                    </div>
                                </div>
                            )}
                        </FormField>
                    </Col>
                    <Col className={'text-right'} md={5}>
                        <span className={'label-text--label'}>{i18n.t('common:datapointZip')}:</span>
                        {site.zip || '-'}
                    </Col>
                </Row>
                <Row>
                    <Col md={5}/>
                    <Col className={'text-right'} md={5}>
                        <span className={'label-text--label'}>{i18n.t('common:enableIdexxTestData')}:</span>
                    </Col>
                    <Col md={2}>
                        <FormField type={'checkbox'} name={`all_sites[${siteIndex}].enableIdexxTestData`}>
                            {({input, meta}) => {
                                const onChange = e => {
                                    input.onChange(e);
                                };
                                return (
                                    <div className="fly-over-editable-container enable-idexx-test-data-control">
                                        <div className="spot-form__field-group" style={{fontSize: '1.2em'}}>
                                            <SpotToggle checked={input.value} onChange={onChange}/>
                                            <SpotFieldError meta={meta}/>
                                        </div>
                                    </div>
                                );
                            }}
                        </FormField>
                    </Col>
                </Row>
                {this.state.showEditPractice && <FlyOver title={'Edit Practice'}
                         cancelAction={() => this.hideEditPractice(this.state.selectedSite)}
                         loading={false}
                         body={<PracticeEditPage organization={this.props.organization} page={this.props.page} practice={this.state.selectedSite} cancel={(site: SiteEntity) => this.hideEditPractice(site)}/>}
                />}
            </>
        );
    }
}
