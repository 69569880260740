import React from 'react';
import PropTypes from 'prop-types';
import './SpotPaging.scss';
import * as _ from 'lodash';

SpotPaging.propTypes = {
    totalRecords: PropTypes.number.isRequired,
    perPage: PropTypes.number,
    currentPage: PropTypes.number,
    goToPage: PropTypes.func,
    testId: PropTypes.string,
    className: PropTypes.string,
};

export default function SpotPaging({ totalRecords, perPage, currentPage, goToPage, testId, className }) {
    const pageNeighbors = 2;
    const totalPages = Math.ceil(totalRecords / perPage);
    const maxPageNumbersToShow = 5;
    const hasEnoughPagesForSpillOffset = totalPages > maxPageNumbersToShow;
    const shouldShowFirst = hasEnoughPagesForSpillOffset && currentPage > pageNeighbors + 1;
    const shouldShowPrev = currentPage > 1;
    const shouldShowNext = currentPage < totalPages;
    const shouldShowLast = hasEnoughPagesForSpillOffset && currentPage < totalPages - pageNeighbors;

    const fetchPageNumbers = () => {
        if (!hasEnoughPagesForSpillOffset) {
            return _.range(1, totalPages + 1);
        }

        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = pageNeighbors * 2 + 2;
        const startPage = Math.max(1, currentPage - pageNeighbors);
        const endPage = Math.min(totalPages, currentPage + pageNeighbors);
        let pages = _.range(startPage, endPage + 1);

        /**
         * hasLeftSpill: has hidden pages to the left
         * hasRightSpill: has hidden pages to the right
         * spillOffset: number of hidden pages either to the left or to the right
         */
        const hasLeftSpill = startPage > 1;
        const hasRightSpill = totalPages - endPage > 1;
        const spillOffset = totalNumbers - (pages.length + 1);

        switch (true) {
            case hasLeftSpill && !hasRightSpill: {
                const extraPages = _.range(startPage - spillOffset, startPage);
                pages = [...extraPages, ...pages];
                break;
            }

            case !hasLeftSpill && hasRightSpill: {
                const extraPages = _.range(endPage + 1, endPage + spillOffset + 1);
                pages = [...pages, ...extraPages];
                break;
            }

            case hasLeftSpill && hasRightSpill:
            case !hasLeftSpill && !hasRightSpill:
            default: {
                pages = [...pages];
                break;
            }
        }

        return pages;
    };

    if (!totalRecords || totalPages === 1) {
        return null;
    }

    const pages = fetchPageNumbers();

    const pageLinkClassName = ['spot-paging__button'];

    return (
        <div className={`spot-paging${className ? ` ${className}` : ''}`} data-testid={testId}>
            <div className="spot-paging__group">
                {shouldShowFirst && (
                    <div
                        onClick={() => goToPage(1)}
                        className="spot-paging__button spot-paging__button--previous"
                        title="First"
                        data-testid="paging-link-first"
                    >
                        <span aria-hidden="true">&lt;&lt;</span>
                        <span className="sr-only">First</span>
                    </div>
                )}

                {shouldShowPrev && (
                    <div
                        onClick={() => goToPage(currentPage - 1)}
                        className="spot-paging__button spot-paging__button--previous"
                        title="Previous"
                        data-testid="paging-link-prev"
                    >
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                    </div>
                )}

                {pages.map(page => {
                    const className = [...pageLinkClassName];

                    if (page === currentPage) {
                        className.push('spot-paging__button--selected');
                    }

                    return (
                        <div key={page} className={className.join(' ')} onClick={() => goToPage(page)} data-testid={`paging-link-${page}`}>
                            {page}
                        </div>
                    );
                })}

                {shouldShowNext && (
                    <div
                        onClick={() => goToPage(currentPage + 1)}
                        className="spot-paging__button spot-paging__button--next"
                        title="Next"
                        data-testid="paging-link-next"
                    >
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                    </div>
                )}

                {shouldShowLast && (
                    <div
                        onClick={() => goToPage(totalPages)}
                        className="spot-paging__button spot-paging__button--next"
                        title="Last"
                        data-testid="paging-link-last"
                    >
                        <span aria-hidden="true">&gt;&gt;</span>
                        <span className="sr-only">Last</span>
                    </div>
                )}
            </div>
        </div>
    );
}
