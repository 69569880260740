import React, {Context} from 'react';
import {RootStore} from './store/RootStore';
import {ContainerInstance} from "typedi";

export interface AppContextInterface {
    ioc: ContainerInstance | null;
    rootStore: RootStore | null;
}

export const AppContext: Context<AppContextInterface> = React.createContext<AppContextInterface>({
    ioc: null,
    rootStore: null
});
