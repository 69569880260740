import * as React from 'react';
import { RouterStore } from 'mobx-react-router';
import { RootStore } from '../../store/RootStore';
import { inject, observer } from 'mobx-react';
import { Route, Link } from 'react-router-dom';
import { SpotLoading } from '@enterprise/spot';
import { Cmp } from '@enterprise/common';
import { SpotSearchbar } from '@enterprise/spot';
import { SpotSvg } from '@enterprise/spot';
import OrganizationsPageStore from './OrganizationsPageStore';
import { OrganizationsEditPage } from './edit/OrganizationsEditPage';
import { Col, Row, Container } from 'react-bootstrap';
import { GridCellParams, GridColDef, GridColumnHeaderParams, GridValueGetterParams, MaterialDataGrid } from '@enterprise/material-data-grid';
import { OrganizationEntity } from '../../core/entity/OrganizationEntity';
import styles from '../dataGrid.module.scss';
import i18n from '../../i18n';
import * as _ from 'lodash';

enum GridColumns {
    Name = 'name',
    Actions = 'actions',
}

interface OrganizationsPageProps {
    page: OrganizationsPageStore;
    routing: RouterStore;
    match: any;
}

interface OrganizationsPageState {
    searchTerm: string;
}

@inject((allStores: RootStore) => ({ page: allStores.pages.organizationsPage, routing: allStores.routing }))
@observer
export class OrganizationsPage extends React.Component<OrganizationsPageProps, OrganizationsPageState> {
    state = {
        searchTerm: '',
    };

    private unsubscribe: any;

    componentDidMount() {
        this.unsubscribe = (this.props.routing as RouterStore).history.subscribe(() => {
            this.props.page.load();
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
        this.props.page.dispose();
    }

    onFilterChanged = value => {
        this.setState({ searchTerm: value });
    };

    render() {
        const { isLoaded, organizations } = this.props.page;
        const { searchTerm } = this.state;

        const getOrgName = ({ name }: OrganizationEntity): string => {
            if (!name) {
                return i18n.t('organizationsPage:defaultPendingOrgName', 'Pending Organization Name');
            }

            return String(name);
        };

        const nameHeader = (): React.ReactElement => {
            return (
                <div className={styles.headerContainer}>
                    <div className={styles.headerCell}>{i18n.t('organizationsPage:Name', 'Name')}</div>
                </div>
            );
        };

        const actionsHeader = (): React.ReactElement => {
            return <div className={styles.headerContainer}></div>;
        };

        const nameCell = (params: GridCellParams): React.ReactElement => {
            const rowValue = params.row;
            return (
                <div className={styles.rowCell}>
                    <div>{rowValue.name}</div>
                </div>
            );
        };

        const actionsCell = (params: GridCellParams): React.ReactElement => {
            const rowValue = params.row;
            return (
                <div>
                    <Link to={`${this.props.match.path}/${rowValue.id}`} className="spot-link">
                        Manage Organization
                    </Link>
                </div>
            );
        };

        const columns: GridColDef[] = [
            {
                field: GridColumns.Name,
                flex: 1.5,
                renderHeader: nameHeader,
                renderCell: nameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    return getOrgName(params.row);
                },
            },
            {
                field: GridColumns.Actions,
                flex: 1,
                renderHeader: actionsHeader,
                renderCell: actionsCell,
                sortable: false,
            },
        ];

        let rows;
        const listOrgs = _.values(organizations);

        if (!searchTerm) {
            rows = listOrgs;
        }

        if (searchTerm) {
            rows = listOrgs.filter(({ name }: OrganizationEntity) => {
                return name.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }

        return (
            <div className="organizations-page">
                <Cmp.If cond={!isLoaded}>
                    <SpotLoading text="Loading..." />
                </Cmp.If>
                <Cmp.If cond={isLoaded}>
                    <Container fluid={true} style={{ marginTop: '10px' }}>
                        <Row>
                            <Col xs={4}>
                                <h1 className={'page-header'}>Organizations</h1>
                                <small>
                                    <strong>Manage organizations here</strong>
                                </small>
                            </Col>
                            <Col xs={5} />
                            <Col xs={3}>
                                <Link to={`${this.props.match.path}/new`} className="spot-link">
                                    Add Organization
                                    <SpotSvg icon="add" className={'add-new-icon'} />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    <Route path={`${this.props.match.path}/:id`} exact={true} component={OrganizationsEditPage} />

                    <div className="list">
                        <SpotSearchbar onSubmit={this.onFilterChanged} />
                        <MaterialDataGrid
                            className={styles.dataGrid}
                            loading={!isLoaded}
                            columns={columns}
                            rows={rows}
                            rowHeight={40}
                            autoHeight={true}
                            headerHeight={40}
                            disableSelectionOnClick={true}
                        />
                    </div>
                </Cmp.If>
            </div>
        );
    }
}
