export default {
    SAPID_MAX_LENGTH: 10,
    PRACTICE_NAME_MAX_LENGTH: 80,
    PRACTICE_ADDR_MAX_LENGTH: 80,
    PRACTICE_CITY_MAX_LENGTH: 35,
    PRACTICE_STATE_MAX_LENGTH: 3,
    PRACTICE_COUNTRY_MAX_LENGTH: 3,
    PRACTICE_ZIP_MAX_LENGTH: 10,
    FIRST_NAME_MAX_LENGTH: 80,
    LAST_NAME_MAX_LENGTH: 80,
    PHONE_MIN_LENGTH: 10,
    PHONE_MAX_LENGTH: 10,
    EMAIL_MAX_LENGTH: 80,
    USERNAME_MIN_LENGTH: 8,
    USERNAME_MAX_LENGTH: 100,
    DISPLAY_NAME_MAX_LENGTH: 100,
    COGNITO_STATUS: {
        FORCE_CHANGE_PASSWORD: 'FORCE_CHANGE_PASSWORD',
        CONFIRMED: 'CONFIRMED',
        RESET_REQUIRED: 'RESET_REQUIRED',
    },
};
