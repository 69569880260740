import React from 'react';
import {Route, Switch} from 'react-router';
import {CommunicatorPage} from "./Communicator/CommunicatorPage";

export const OnboardingPage_URL = '/onboarding';
export const OnboardingPage = () => {
    return (
        <div className="plans-page h-100">
            <Switch>
                <Route path={`${OnboardingPage_URL}/communicator`} component={CommunicatorPage} />
            </Switch>
        </div>
    );
};
