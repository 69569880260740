import { inject } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasicLayout from '../layout/basic/BasicLayout';
import AppStore from '../store/AppStore';
import RedirectToRouteComponent from './RedirectToRouteComponent';
import { autorun } from 'mobx';

interface IConnectRouterProps {
    routes: any[];
    children?: any;
    app?: AppStore;
    routing?: RouterStore;
}

class ConnectRouter extends React.Component<IConnectRouterProps, { prevPath: string; prevToken: any }> {
    state = {
        prevPath: 'm2n3b4m5n2b345',
        prevToken: null,
    };

    componentDidMount() {
        const { app, routing } = this.props;
        autorun(() => {
            const { prevToken, prevPath } = this.state;
            const newState: any = {};
            if (app && app.token !== prevToken) {
                newState.prevToken = app.token;
            }
            const currentPath = (routing as RouterStore).location.pathname;
            const isSimilarPath = prevPath.includes(currentPath) || currentPath.includes(prevPath);
            if (!isSimilarPath && currentPath !== prevPath) {
                newState.prevPath = currentPath;
            }
            this.setState(newState);
        });
    }

    render() {
        const { routes, app, routing } = this.props;
        const routesCmp = routes.map((route, index) => {
            const { Component, isSecured, role: allowedRoles, config } = route;
            const Layout = route.Layout ? route.Layout : BasicLayout;
            let isAllowed = true;
            if (isSecured) {
                isAllowed = (app as AppStore).token.isAuthenticated();
                if (isAllowed && allowedRoles) {
                    isAllowed = allowedRoles.some(role => (app as AppStore).token.hasRole(role));
                }
            }
            return isAllowed ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={({ ...args }) => (
                        <Layout {...config}>
                            <Component {...config} {...args} />
                        </Layout>
                    )}
                />
            ) : (
                <Route
                    key={index}
                    path={route.path}
                    render={() => <RedirectToRouteComponent to="/login" state={{ from: (routing as RouterStore).location.pathname }} />}
                />
            );
        });
        return <Switch location={(routing as RouterStore).location}>{routesCmp}</Switch>;
    }
}

export default inject('app', 'routing')(ConnectRouter);
