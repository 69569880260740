import * as React from 'react';
import { Form as FinalForm, FormProps } from 'react-final-form';
import { FormType } from '../FormType';
import { Decorator } from 'final-form';

interface IFormProps {
    formType: FormType;
    data: any;
    children: any;
    onSubmit: (values: any) => void | Promise<any>;
    options?: any;
    mutators?: any;
    defaultValues?: any;
    decorators?: Decorator[];
    /**
     * added to support and behave properly according to Final-Forms documentation
     * https://final-form.org/docs/final-form/types/Config#keepdirtyonreinitialize
     * If true, changed values on Form be preserved when initialize(newValues) is called
     */
    keepDirtyOnReinitialize?: boolean;
    [key: string]: any;
}

interface IFormState {
    isLoading: boolean;
    form?: Partial<FormProps> | any;
    decorators: Decorator[];
}

// @see https://github.com/final-form/react-final-form
export class Form extends React.Component<IFormProps, IFormState> {
    state: IFormState = {
        isLoading: false,
        form: undefined,
        decorators: [],
    };

    save = async values => {
        const { formType, onSubmit, options } = this.props;
        this.setState({ isLoading: true });
        await Promise.resolve(onSubmit(formType.getEntity(values, options)))
            .then(() => {
                this.setState({ isLoading: false });
            })
            .catch(() => {
                this.setState({ isLoading: false });
            });
    };

    rebuildForm() {
        const { formType, data, options } = this.props;
        const { decorators, ...formData } = formType.buildForm(data, options);
        this.setState({ form: formData, decorators: (decorators || []).concat(this.props.decorators || []) });
    }

    componentDidMount() {
        this.rebuildForm();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps as IFormProps).data !== this.props.data) {
            if (!this.props.keepDirtyOnReinitialize) {
                this.setState({ form: null, decorators: [] });
            }

            setTimeout(() => {
                this.rebuildForm();
            });
        }
    }

    render() {
        const { formType, data, onSubmit, ...rest } = this.props;
        const { form, isLoading, decorators } = this.state;
        let finalFormProps: any = form;
        if (!form) {
            return null;
        }
        if (!(form as Partial<FormProps>).initialValues) {
            finalFormProps = { initialValues: form as any };
        }
        if (finalFormProps && this.props.defaultValues) {
            finalFormProps.initialValues = { ...this.props.defaultValues, ...finalFormProps.initialValues };
        }

        return (
            <div className={`${isLoading ? 'loading' : ''}`} style={{ height: '100%' }}>
                <FinalForm {...finalFormProps} {...rest} decorators={decorators} onSubmit={this.save} validate={formType.validate.bind(formType)} />
            </div>
        );
    }
}
