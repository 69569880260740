import React from 'react';
import { ReactHelpers, useAsync, Cmp } from '@enterprise/common';
import { OnboardingPageStore } from '../../OnboardingPageStore';
import { CommunicatorPageStore } from '../CommunicatorPageStore';
import { toJS } from 'mobx';
import { CommunicatorPracticeEntity } from '../../../../core/onboarding/Communicator/entity/CommunicatorPracticeEntity';
import useStore from '../../../../hooks/useStore';
import { PAGE_STORES } from '../../../stores';

export type PHNProListContainerChildProps = {
    items: CommunicatorPracticeEntity[];
    save: (item: CommunicatorPracticeEntity) => Promise<any>;
};

export const CommunicatorListContainer = ({ children }) => {
    const onboardingStore = useStore<OnboardingPageStore>(PAGE_STORES.OnboardingPageStore);

    const result = useAsync((onboardingStore as OnboardingPageStore).getSubStore('PHNPro'));
    return (
        <Cmp.AsyncLoader {...result}>{!result.loading && ReactHelpers.renderChildren(children, childPropsSelector(result.value))}</Cmp.AsyncLoader>
    );
};

function childPropsSelector(store: CommunicatorPageStore): PHNProListContainerChildProps {
    return {
        items: toJS(store.items),
        save: (item: CommunicatorPracticeEntity) => {
            return Promise.resolve(item);
        },
    };
}
