import * as React from 'react';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotFieldError } from './SpotFieldError';

export interface SpotRadioProps extends SpotInputProps {
    isSmall?: boolean;
    isCentered?: boolean;
    isFullWidth?: boolean;
}

export class SpotRadio extends BaseSpotComponent<SpotRadioProps> {
    render() {
        const { className, isSmall, name, id, error, children, isCentered, isFullWidth, ...rest } = this.props;

        return (
            <span>
                <label
                    className={[
                        'spot-form__radio',
                        isSmall ? 'spot-form__radio--small' : '',
                        isFullWidth ? 'spot-form__radio--full' : '',
                        className,
                    ].join(' ')}
                    htmlFor={this.idProp}
                >
                    <input id={this.idProp} name={name} type="radio" className="spot-form__radio-input" {...rest} />
                    <span className="spot-form__radio-inner">
                        <span className={['spot-form__radio-visual', isCentered ? 'spot-form__radio-visual--centered' : ''].join(' ')} />
                        <span className="spot-form__radio-label "> {children} </span>
                    </span>
                </label>
                {error && <SpotFieldError meta={error} />}
            </span>
        );
    }
}
