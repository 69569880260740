import { Service } from 'typedi';
import { action, observable } from 'mobx';
import { UsersService } from '../../services/UsersService';
import { UserEntity } from '../../core/entity/UserEntity';
import { Notifications } from '../Notifications';
import { FeatureEntity, FeatureService } from '../../services/FeatureService';

@Service()
export default class UsersPageStore {
    @observable isLoaded: boolean = false;

    @observable users: UserEntity[] = [];

    @observable user?: UserEntity;
    @observable features: FeatureEntity[] = [];

    constructor(private service: UsersService, private featureService: FeatureService) {}

    load() {
        this.getUsers().then(() => {
            this.isLoaded = true;
        });
    }

    @action updateUser(id: string, roles: string[], isActive: boolean, organizationId: string) {
        return this.service
            .updateUser(id, roles, isActive, organizationId)
            .then(res => {
                this.users = this.users.map(u => {
                    if (u.id === res.id) {
                        return res;
                    }
                    return u;
                });
                Notifications.success(`Successfully updated user`);
                return res;
            })
            .catch(e => {
                Notifications.fromException(e);
                throw e;
            });
    }

    @action async saveFeatures(u: UserEntity | string, features: FeatureEntity[]) {
        return this.featureService.setUserFeatures(features, u);
    }

    @action
    async fetchUser(id: string) {
        try {
            this.user = await this.service.getUserById(id);
            this.features = await this.featureService.getUserFeatures(id);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        return this.user;
    }

    @action async getUsers() {
        const users = await this.service.getUsers();
        this.users = users.sort((a, b) => a.username.localeCompare(b.username));
        return this.users;
    }

    @action dispose() {
        this.users = [];
    }
}
