import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '@enterprise/common';
import { SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import { PricingTierEntity } from '../../../../core/entity/PricingTierEntity';
import i18n from '../../../../i18n';

interface PricingTierFormControlProps {
    pricingTiers: PricingTierEntity[];
}

export class PricingTierFormControl extends React.PureComponent<PricingTierFormControlProps> {
    render() {
        const { pricingTiers } = this.props;
        return (
            <Row>
                <Col xs={5}>
                    <FormField
                        name="pricingTier"
                        parse={(id: string) => id && pricingTiers.find(tier => String(tier.id) === id)}
                        format={value => value && value.id}
                    >
                        {({ input }) => (
                            <SpotSelectBox {...input} placeholder={i18n.t('common:pleaseSelect', 'Please select')} data-automation-id={'pricingTier'}>
                                {pricingTiers.map(item => (
                                    <SpotSelectOption key={item.id} value={item.id}>
                                        {item.name}
                                    </SpotSelectOption>
                                ))}
                            </SpotSelectBox>
                        )}
                    </FormField>
                </Col>
            </Row>
        );
    }
}
