import { validate } from 'class-validator';
import { FormProps } from 'react-final-form';
import { FormType } from './FormType';
import { FunctionTransformer, ToTypeTransformer, ToPlainTransformer } from '../transformers';

export class EntityFormType<T> extends FormType {
    constructor(protected TCreator: new () => T | T) {
        super();
    }

    buildForm(data: T, options?: any): Partial<FormProps> | any {
        const initialValues = new ToPlainTransformer().transform(data);
        return super.buildForm(initialValues, options);
    }

    getEntity(value, options?: any): T {
        return new ToTypeTransformer(this.TCreator).transform(value);
    }

    async validate(value): Promise<{ [field: string]: string }> {
        const errors = await validate(new ToTypeTransformer<object>(this.TCreator).transform(value));
        return new FunctionTransformer(data => {
            const result = {};
            (data as any[]).forEach(error => {
                result[error.property] = error.constraints[Object.keys(error.constraints)[0]];
            });
            return result;
        }).transform(errors);
    }
}
