import { useEffect, useState } from 'react';
import { GridFilterItem, useGridApiContext, useGridSelector, gridFilterStateSelector } from '@mui/x-data-grid-pro';
import { GridFilterOperatorType } from '../components';

export interface ColumnFilterHookParams {
    id?: number;
    columnField: string;
    operator?: GridFilterOperatorType;
}

interface ColumnFilterHook {
    onFilterChange: (value?: string) => void;
    filterValue: string;
}

export const useColumnFilterHook = <TFiled extends string = string>({ id, columnField, operator }: ColumnFilterHookParams): ColumnFilterHook => {
    const apiRef = useGridApiContext();
    const { filterModel } = useGridSelector(apiRef, gridFilterStateSelector);
    const [filterValue, setFilterValue] = useState<string>('');
    const onChange = (value = '') => {
        setFilterValue(value);

        const filter: GridFilterItem = {
            id,
            columnField,
            value: value || '',
            operatorValue: operator,
        };

        apiRef.current.upsertFilterItem(filter);
    };

    useEffect(() => {
        const selectedNameFilter = filterModel?.items.find((item) => item.id === id);
        setFilterValue(selectedNameFilter?.value || '');
    }, [filterModel?.items]);

    return {
        filterValue,
        onFilterChange: onChange,
    };
};
