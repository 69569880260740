/**
 * @class Base interface which every provider should extend to follow contract
 * requires an authenticate() method on the user token, and a supports() method,
 * which tells the authentication manager whether or not to use this provider
 * for the given token. In the case of multiple providers,
 * the UserService will then move to the next provider in the list.
 */
export interface BaseAuthProvider {
    support(token);

    /**
     * Should try to auth user with provided token
     * @param {BaseUserToken} token
     * @return {Promise<BaseUserToken>}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    authenticate(token): Promise<BaseUserToken>;

    /**
     * Should logout user from the system
     * @param {BaseUserToken} token
     * @return {Promise<BaseUserToken>}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    logout(token?): Promise<BaseUserToken>;

    /**
     * Try to restore user token from somewhere. Usually after page refresh
     * @return {Promise<BaseUserToken>}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    restore(): Promise<BaseUserToken | null>;
}

/**
 * @class  The interface for the user authentication information.
 */
export class BaseUserToken {
    user:any = {};

    /**
     * return current user instance
     * @return {*}
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    getUser() {
        return this.user;
    }

    /**
     * return current username
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    getUsername() {
        return 'Anonymous';
    }

    /**
     * check if user has role
     * @param name
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    hasRole(name) {
        return false;
    }

    /**
     * is current token is authenticated
     * @return {boolean}
     */
    isAuthenticated() {
        return false;
    }
}
