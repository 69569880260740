import { Auth } from 'aws-amplify';
const responseKey = 'setAuthSession';

const setAmplifyConfig = config => {
    Auth.configure({
        region: config.region,
        userPoolId: config.poolId,
        userPoolWebClientId: config.clientId,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    });
};

interface AuthConfig {
    authHost: string;
    secureHost: boolean;
    hostedLoginUrl: string;
    allowedDomains: string[];
}

export class AuthClient {
    cookieData = {};
    authHandler;
    config;
    hostedUIRef;
    amplifyConfigured = false;

    constructor(config: AuthConfig) {
        this.config = config;
        window.addEventListener('message', this.receiveMessage, false);
    }

    setAuthHandler(handler) {
        this.authHandler = handler;
    }

    checkStorage = () => {
        const clientId = localStorage.getItem('CFSSO.UserPoolClientId');
        const poolId = localStorage.getItem('CFSSO.UserPoolId');
        const region = localStorage.getItem('CFSSO.Region');
        if (!clientId || !poolId || !region) {
            return null;
        }
        if (!this.amplifyConfigured) {
            setAmplifyConfig({ clientId, poolId, region });
            this.amplifyConfigured = true;
        }
        return true;
    };

    isAuthenticated = () => {
        return new Promise(resolve => {
            const poolConfigInStorage = this.checkStorage();
            if (!poolConfigInStorage) {
                return resolve(false);
            }
            Auth.currentSession()
                .then(session => resolve(session))
                .catch(() => resolve(false));
        });
    };

    receiveMessage = event => {
        const isAllowed = this.config.allowedDomains.some(domain => event.origin.includes(domain));
        if (!isAllowed || !event.data || event.data.type !== responseKey) {
            return;
        }

        Object.keys(localStorage).forEach(key => {
            if (key && /CognitoIdentityServiceProvider/i.test(key)) {
                localStorage.removeItem(key);
            }
        });

        if (event.data.authData) {
            event.data.authData.forEach(chunk => {
                localStorage.setItem(chunk.Name, chunk.Value);
            });
            if (this.checkStorage()) {
                this.authHandler();
            }
        }
    };

    getLoginUrl = () => this.config.hostedLoginUrl;

    getAttributes = () => Auth.currentAuthenticatedUser();

    getIdToken = () => {
        this.checkStorage();
        return Auth.currentSession().then(res => res.getIdToken());
    };

    getHostedUIRef = () => this.hostedUIRef;

    signOut = async () => {
        try {
            await Auth.signOut();
        } catch (err) {
            console.warn('Error logging out:', err);
        }
        localStorage.clear();
    };
}
