import { Inject, Service } from 'typedi';
import { action, observable } from 'mobx';
import { UserService, BaseUserToken, AnonymousUserToken } from '@enterprise/common';
import { environment } from '../environment/environment';
import { AuthClient } from '@shared';
import EnterpriseApiHttp from '../services/http/EnterpriseApiHttp';
import { Notifications } from '../pages/Notifications';
import RoleConstants from '../constants/RoleConstants';
import { UNAUTHORIZED_PAGE_PATH } from '../pages/login/UnauthorizedPage';

@Service()
export default class AppStore {
    @observable token: BaseUserToken = new AnonymousUserToken();
    authClient = new AuthClient({
        authHost: environment.SSO.AUTH_HOST,
        secureHost: environment.SSO.SECURE_HOST,
        hostedLoginUrl: environment.SSO.BASE_URL,
        allowedDomains: environment.SSO.ALLOWED_DOMAINS,
    });
    authorizing = false;

    constructor(
        private readonly userService: UserService,
        @Inject('http') private readonly http: EnterpriseApiHttp,
        @Inject('httpOnboarding') private readonly httpOnboarding: EnterpriseApiHttp,
    ) {
        this.http.setAppSessionMethod(this.checkAppSession);
        this.httpOnboarding.setAppSessionMethod(this.checkAppSession);
    }

    @action checkAppSession = async () => {
        return await this.authClient
            .getIdToken()
            .then(res => res.getJwtToken())
            .catch(() => null);
    };

    @action redirectIfNotAdmin = (): void => {
        if (this.token?.hasRole(RoleConstants.SUPER_ADMIN) || this.token?.hasRole(RoleConstants.ONBOARDING_ADMIN)) {
            return;
        }

        window.location.pathname = UNAUTHORIZED_PAGE_PATH;
    };

    @action checkIsActive = (token): boolean => {
        return token.user.isActive;
    };

    @action errorNotActive = (): void => {
        this.logout(undefined).then(() => (window.location.pathname = UNAUTHORIZED_PAGE_PATH));
    };

    @action getSSOLoginURL = () => this.authClient.getLoginUrl() + '?newSession=true&hideProfile=true';

    @action async authorize(idToken: string) {
        if (this.authorizing) {
            return;
        }
        this.toggleAuthStatus();

        return this.userService
            .authenticate(idToken)
            .then(token => {
                if (token instanceof Error) {
                    throw token;
                }
                this.toggleAuthStatus();
                if (!token || token === 'USER_NOT_FOUND') {
                    return token;
                }
                this.token = token;
                this.redirectIfNotAdmin();
                if (token.user && !this.checkIsActive(token)) {
                    this.errorNotActive();
                    return;
                }
                return token;
            })
            .catch(err => {
                this.toggleAuthStatus();
                Notifications.error(err.message);
                return err;
            });
    }

    toggleAuthStatus = (status: boolean = !this.authorizing) => (this.authorizing = status);

    @action logout = async (token?: BaseUserToken): Promise<any> => {
        try {
            this.token = await this.userService.logout(token || this.token);
            await this.authClient.signOut();
        } catch (err) {
            console.error(err);
        }
        return this.token;
    };

    @action bootstrap(): Promise<any> {
        return this.userService
            .restore()
            .then(async token => {
                this.token = token;
                return this.token;
            })
            .catch(token => {
                this.token = token;
                return Promise.resolve(token);
            });
    }
}
