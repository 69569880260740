import React, { ReactElement, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
    GridColumnHeaderParams,
    gridTabIndexColumnHeaderSelector,
    useGridSelector,
    gridVisibleSortedRowIdsSelector,
    useGridRootProps,
    GridEvents,
    useGridApiContext,
} from '@mui/x-data-grid-pro';
import { SpotCheckbox } from '@enterprise/spot';
import styles from './selectorHeader.module.scss';

export const SelectorHeader = (props: GridColumnHeaderParams): ReactElement => {
    const [, forceUpdate] = React.useState(false);
    const { field } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const tabIndexState = useGridSelector(apiRef, gridTabIndexColumnHeaderSelector);
    const visibleRowIds = useGridSelector(apiRef, gridVisibleSortedRowIdsSelector);

    const totalSelectedRows = apiRef.current.getSelectedRows().size;
    const totalRowIds = apiRef.current.getAllRowIds();
    const totalSelectableRowIds = totalRowIds?.filter(
        (id) => !rootProps?.isRowSelectable || rootProps?.isRowSelectable(apiRef.current.getRowParams(id)),
    );
    const totalRows = totalSelectableRowIds.length;

    const [isIndeterminate, setIsIndeterminate] = useState(totalSelectedRows > 0 && totalSelectedRows !== totalRows);
    const [isChecked, setChecked] = useState(totalSelectedRows === totalRows);

    useEffect(() => {
        const isNewIndeterminate = totalSelectedRows > 0 && totalSelectedRows !== totalRows;
        const isNewChecked = totalRows > 0 && totalSelectedRows === totalRows;
        setChecked(isNewChecked);
        setIsIndeterminate(isNewIndeterminate);
    }, [isIndeterminate, totalRows, totalSelectedRows]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setChecked(checked);
        apiRef.current.selectRows(visibleRowIds, checked);
    };

    const tabIndex = tabIndexState !== null && tabIndexState.field === field ? 0 : -1;

    useLayoutEffect(() => {
        const element = apiRef.current.getColumnHeaderElement(field);
        if (tabIndex === 0 && element) {
            element!.tabIndex = -1;
        }
    }, [tabIndex, apiRef.current, field]);

    const handleSelectionChange = useCallback(() => {
        forceUpdate((p) => !p);
    }, []);

    useEffect(() => {
        return apiRef.current.subscribeEvent(GridEvents.selectionChange, handleSelectionChange);
    }, [apiRef.current, handleSelectionChange]);

    return (
        <SpotCheckbox
            tabIndex={tabIndex}
            className={styles.container}
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={handleChange}
            data-automation-id="data-grid-selector-header"
        />
    );
};
