/**
 * @class Main Service do deal with user roles authentication
 */
import { BaseAuthProvider, BaseUserToken } from './common';
import { AnonymousUserToken } from './AnonymousUserToken';

export class UserService {
    /**
     * List of available Auth providers
     */
    constructor(private providers: BaseAuthProvider[]) {}

    /**
     * Goes through all registered providers, finds the first one which supports given token
     * and tries to auth using it to authenticate
     * @param token
     * @return {Promise<BaseUserToken|Error>}
     */
    async authenticate(token): Promise<any> {
        const provider = this.providers.find(p => p.support && p.support(token));
        if (!provider) {
            throw new Error(`Unable to find Auth Provider`);
        }
        return provider
            .authenticate(token)
            .then(newToken => {
                return newToken;
            })
            .catch(() => {
                return false;
            });
    }

    logout(token?: BaseUserToken): Promise<any> {
        if (token) {
            const provider = this.providers.find(p => p.support && p.support(token));
            if (!provider) {
                return Promise.reject(new Error(`Unable to find Auth Provider`));
            }

            return provider.logout(token).then(newToken => {
                return newToken;
            });
        }

        return Promise.all(this.providers.map(p => p.logout()))
            .then(() => new AnonymousUserToken())
            .catch(() => new AnonymousUserToken());
    }

    /**
     * Tries to restore user session using all registered providers one by one.
     * The first provider which will successfully be restored will be used.
     * @return {Promise<any>}
     */
    restore(): Promise<any> {
        const promises = this.providers.map(p => p.restore());
        // If a request fails, count that as a resolution so it will keep
        // waiting for other possible successes. If a request succeeds,
        // treat it as a rejection so Promise.all immediately bails out.
        return Promise.all(promises.map(p => p.then(val => Promise.reject(val), err => Promise.resolve(err))))
            .then(
                // If '.all' resolved, we've just got an array of errors.
                errors => Promise.reject(errors),
                // If '.all' rejected, we've got the result we wanted.
                val => Promise.resolve(val),
            )
            .then(newToken => {
                return newToken;
            })
            .catch(() => {
                return new AnonymousUserToken();
            });
    }
}
