import * as React from 'react';
import { SpotLoading, SpotModal } from '@enterprise/spot';
import { Container } from 'react-bootstrap';
import i18n from '../../../../i18n';

export const LoadingModal = () => {
    return (
        <div className={'communicator-user-edit-create-modal-page'}>
            <SpotModal title={i18n.t('common:pages.onboarding.editUser', 'Edit User')} isVisible={true} hideClose={true}>
                {() => (
                    <Container>
                        <div className={'loading-screen'}>
                            <div className={'loading-spinner'}>
                                <SpotLoading size={'large'} text={i18n.t('common:loading', 'Loading...')} />
                            </div>
                        </div>
                    </Container>
                )}
            </SpotModal>
        </div>
    );
};
